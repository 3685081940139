import { Checkbox, TextField } from "@fluentui/react";
import { useRecoilValue } from "recoil";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { flatten, fromPairs, isEqual, sum, uniq } from "lodash";

import { Button } from "../../../../../components/Button";
import { SingleDataSelectionInner } from "../data_card/selections/DataCardSelection";
import { useHandleCardChangePython } from "../../../../../lib/application/state/actions/selections/shared/useHandleSelectionChangePython";
import {
  DataSelectionWithoutId,
  MeasureSelection,
} from "../../../../../lib/domain/selections/definitions";
import { nonEmptyString } from "../../../../../lib/core/nonEmptyString";
import { defined } from "../../../../../lib/core/defined";
import { handleGetDefaultMeasure } from "../../../../../lib/application/requests/common_requests";
import {
  getDateFormatter,
  TimeResolution,
} from "../../../../../lib/domain/time";
import {
  AppMessagesContext,
  CardUpdateCountContext,
  CategoriesContext,
  CategoriesContextMicroPrimaryContext,
  GeographiesContext,
  ShowDraftDataContext,
  UserInfoContext,
} from "../../../../../lib/application/contexts";
import {
  defaultMeasureSelectionPrimary,
  getSubjectPath,
  isBreakdownTotal,
  measureSelectionIsSurvey,
} from "../../../../../lib/domain/measure";
import { setMeasureAvailableDatesMut } from "../../../../../lib/domain/measure";
import { pythonCardQuery } from "../../../../../lib/application/state/stats/document-core/queries/card";
import { AlertBox } from "../../../../../components/AlertBox";
import { useToggle } from "../../../../../lib/application/hooks/useToggle";
import { GeoModalDataframe } from "./GeoModalDataframe";
import {
  DataframeMicroColumn,
  DataframeStatsColumn,
  DocCardPython,
  GeoExpansions,
  GeoSelections,
  isDataframeMicroColumn,
  isDataframeStatsColumn,
} from "../../../../../lib/application/state/stats/document-core/core";
import { truncate } from "../../../../../lib/core/truncate";
import {
  DataframeDto,
  cardToDataframe,
  getDataframe,
} from "../../../../../lib/application/requests/python/requests";
import { Table } from "../../../../../components/Table";
import { assertNever } from "../../../../../lib/core/assert";
import {
  DateRangeRaw,
  SelectedDimensionsV2,
  isSingleDate,
} from "../../../../../lib/domain/measure/definitions";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner";
import { HttpError } from "../../../../../lib/infra/HttpResult";
import { DisplayRequestErrorInfo } from "../../../../../components/errors/DisplayRequestErrorInfo";
import { TimeSelect } from "./TimeSelect";
import { last } from "../../../../../lib/core/last";
import { emptyGeoSelections } from "../../../../../lib/domain/geography";
import { measureSelectionIsSurveyString } from "../../../../../lib/domain/measure";
import {
  Categories,
  defaultSubject,
} from "../../../../../lib/domain/categories";
import {
  DataSelectionMicro,
  MicroSubjectPath,
  makePrimaryMicroDataSelection,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import { logger } from "../../../../../lib/infra/logging";
import {
  getMicroAvailableDates,
  getMicroCategoriesWithCache,
  getMicroDimensionsWithCache,
  getMicroMeasuresWithCache,
} from "../../../../../lib/application/requests/datasets/micro";
import { isMicroMeasureRegularDto } from "../../../../../lib/application/state/stats/document-core/core-micro";
import { getDefaultSelectedDimensions } from "../../../../../lib/domain/measure";
import { MicroMeasure } from "../../../../../lib/domain/micro/MicroMeasure";
import { useSelectionInputState } from "../micro/components/_shared";
import { StyledDropdown } from "../card_general/StyledDropdown";
import {
  ComputedMeasurementType,
  MicroMeasureDto,
} from "../../../../../lib/infra/api_responses/micro_dataset";
import { useMicroAvailableMeasures } from "../micro/useMicroAvailableMeasures";
import { useGeoTree } from "../micro/useGeoTree";
import { DimensionSelectionOuter } from "../micro/components/DimensionSelectionOuter";
import { cleanColumnName } from "../../../../../lib/domain/macro/clean";

const EMPTY_ARRAY: any[] = [];

export function CreateDataframe(props: { cardId: string }) {
  const [addMode, setAddMode] = useState<
    "stats" | "micro" | "survey" | "user-defined" | null
  >(null);
  const [currentDataframe, setCurrentDataframe] = useState<
    DataframeDto | undefined
  >(undefined);
  const [columnNameInput, setColumnNameInput] = useState("");
  const [expressionInput, setExpressionInput] = useState("");
  const [validationError, setValidationError] = useState<string | undefined>();

  const [categorieStats, setCategoriesStats] = useState<
    Categories | undefined
  >();
  const [categoriesSurvey, setCategoriesSurvey] = useState<
    Categories | undefined
  >();

  const [dataframeFetching, setDataframeFetching] = useState(false);
  const [dataframeError, setDataframeError] = useState<HttpError | undefined>();

  const card = useRecoilValue(pythonCardQuery(props.cardId));

  const adminShowDraftData = useContext(ShowDraftDataContext);
  const userInfo = useContext(UserInfoContext);
  const categoriesAll = useContext(CategoriesContext);
  const [microCategories, setMicroCategories] = useState<Categories>({});

  const handleCardUpdate = useHandleCardChangePython(props.cardId);

  useEffect(() => {
    if (!defined(userInfo) || !userInfo.hasMicroReadAccess()) {
      return;
    }
    getMicroCategoriesWithCache(
      adminShowDraftData,
      ["deso"],
      ["decimal", "integer"]
    ).then((res) =>
      res.match({
        ok: (categories) => setMicroCategories(categories),
        err: (err) => logger.error("Kunde inte hämta mikrokategorier", err),
      })
    );
  }, [adminShowDraftData, userInfo]);

  useEffect(() => {
    if (!defined(categoriesAll)) {
      return;
    }

    const stats: Categories = {};
    const survey: Categories = {};

    Object.entries(categoriesAll).forEach(([area, subareas]) => {
      const surveySubareas: { [key: string]: string[] } = {};
      const statsSubareas: { [key: string]: string[] } = {};

      Object.entries(subareas).forEach(([subarea, subjects]) => {
        if (subarea === "Infostats undersökningar") {
          surveySubareas[subarea] = subjects;
        } else {
          statsSubareas[subarea] = subjects;
        }
      });

      if (Object.keys(surveySubareas).length > 0) {
        survey[area] = surveySubareas;
      }
      if (Object.keys(statsSubareas).length > 0) {
        stats[area] = statsSubareas;
      }
    });
    setCategoriesStats(stats);
    setCategoriesSurvey(survey);
  }, [categoriesAll]);

  const validateUserDefinedColumn = useCallback(() => {
    if (!nonEmptyString(columnNameInput)) {
      return "Kolumnnamn saknas";
    }
    if (!nonEmptyString(expressionInput)) {
      return "Uttryck saknas";
    }
    return undefined;
  }, [columnNameInput, expressionInput]);

  const resetAddMode = useCallback(() => {
    // We do not reset add mode or data selection
    // since the user may well want to continue adding similar columns

    setColumnNameInput("");
    setExpressionInput("");
    setValidationError(undefined);
  }, []);

  const cancelAddMode = useCallback(() => {
    setAddMode(null);
    resetAddMode();
  }, [resetAddMode]);

  const handleUpdateExpression = useCallback(
    (columnName: string, expression: string) => {
      handleCardUpdate({
        ...card,
        data: {
          ...card.data,
          columns: card.data.columns.map((c) => {
            if (c.type === "user-defined" && c.columnName === columnName) {
              return {
                ...c,
                expression,
              };
            }
            return c;
          }),
        },
      });
    },
    [card, handleCardUpdate]
  );

  const handleAddUserDefinedColumn = useCallback(() => {
    const err = validateUserDefinedColumn();
    if (defined(err)) {
      return setValidationError(err);
    }

    const name = cleanColumnName(columnNameInput);
    if (!nonEmptyString(name)) {
      return;
    }
    handleCardUpdate({
      ...card,
      data: {
        ...card.data,
        columns: [
          ...card.data.columns,
          {
            type: "user-defined",
            columnName: name,
            expression: expressionInput,
          },
        ],
      },
    });
    resetAddMode();
  }, [
    card,
    columnNameInput,
    expressionInput,
    handleCardUpdate,
    resetAddMode,
    validateUserDefinedColumn,
  ]);

  const cols = card.data.columns;

  useEffect(() => {
    if (cols.length === 0 && !defined(card.data.surveySelection)) {
      setCurrentDataframe(undefined);
      return;
    }

    const dataframeSpec = cardToDataframe(card);
    if (!defined(dataframeSpec)) {
      return;
    }

    setDataframeFetching(true);
    getDataframe(dataframeSpec).then((res) => {
      res.match({
        ok: (dataframe) => {
          setCurrentDataframe(dataframe);
          setDataframeError(undefined);
        },
        err: (err) => {
          setDataframeError(err);
        },
      });

      setDataframeFetching(false);
    });
  }, [card, cols]);

  if (!defined(categorieStats) || !defined(categoriesSurvey)) {
    return <DelayedSpinner delayShowMs={0} label="Laddar ..." />;
  }

  return (
    <div className="create-dataframe content-padding">
      <section>
        <section>
          <Button
            onClick={() => setAddMode("stats")}
            title="Lägg till statskolumn"
            disabled={addMode === "stats"}
          />
          <Button
            onClick={() => setAddMode("micro")}
            title="Lägg till mikrokolumn"
            disabled={addMode === "micro" || defined(card.data.surveySelection)}
          />
          <Button
            onClick={() => setAddMode("user-defined")}
            title="Lägg till egen kolumn"
            disabled={addMode === "user-defined"}
          />
          {/* Survey functionality disabled until we decide whether to remove or keep */}
          {/* {defined(userInfo) &&
            userInfo.hasPermission(Permission.InternalManageMeasurements) && (
              <Button
                onClick={() => setAddMode("survey")}
                disabled={
                  defined(card.data.surveySelection) ||
                  addMode === "survey" ||
                  card.data.columns.some(isDataframeMicroColumn)
                }
                title="Lägg till surveydata"
              />
            )} */}
        </section>

        <div>
          {addMode === "user-defined" && (
            <>
              <h3>Egen kolumn</h3>
              <div className="column-submit">
                <TextField
                  required
                  label="Kolumnnamn"
                  value={columnNameInput}
                  onChange={(e) => {
                    setColumnNameInput(e.currentTarget.value);
                  }}
                />
                <div className="expression-assignment-symbol">=</div>
                <TextField
                  required
                  className="grow"
                  label="Uttryck"
                  value={expressionInput}
                  onChange={(e) => {
                    setExpressionInput(e.currentTarget.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleAddUserDefinedColumn();
                    } else if (e.key === "Escape") {
                      cancelAddMode();
                    }
                  }}
                />
                <div>
                  <Button title="Avbryt" onClick={() => cancelAddMode()} />
                  <Button
                    intent="primary"
                    title="Lägg till"
                    onClick={handleAddUserDefinedColumn}
                  />
                </div>
              </div>
              {defined(validationError) && (
                <AlertBox intent="warning">
                  <span>{validationError}</span>
                </AlertBox>
              )}
            </>
          )}
          {addMode === "stats" && (
            <>
              <h3>Statskolumn</h3>
              <CategoriesContext.Provider value={categorieStats}>
                <DataSelectionComponent
                  mode={addMode}
                  card={card}
                  handleCardUpdate={handleCardUpdate}
                  cancelAddMode={cancelAddMode}
                  columnIndex={card.data.columns.length}
                />
              </CategoriesContext.Provider>
            </>
          )}
          {addMode === "micro" && (
            <>
              <h3>Mikrokolumn</h3>
              <CategoriesContextMicroPrimaryContext.Provider
                value={microCategories}
              >
                <DataSelectionMicroComponent
                  card={card}
                  handleCardUpdate={handleCardUpdate}
                  cancelAddMode={cancelAddMode}
                  columnIndex={card.data.columns.length}
                />
              </CategoriesContextMicroPrimaryContext.Provider>
            </>
          )}
          {addMode === "survey" && (
            <>
              <h3>Surveymått</h3>
              <CategoriesContext.Provider value={categoriesSurvey}>
                <DataSelectionComponent
                  mode={addMode}
                  card={card}
                  handleCardUpdate={handleCardUpdate}
                  cancelAddMode={cancelAddMode}
                  columnIndex={card.data.columns.length}
                />
              </CategoriesContext.Provider>
            </>
          )}
        </div>
      </section>

      <section className="columns">
        {defined(card.data.surveySelection) && (
          <>
            <h3>Surveydata</h3>
            <section className="flex-row-space-between flex-align-items-center">
              <span>
                {card.data.surveySelection.selection.subjectPath.join(" > ")}
                {" > "}
                {
                  card.data.surveySelection.selection.measureSelection?.measure
                    .measure
                }
              </span>
              <Button
                small
                title="Ta bort"
                onClick={() =>
                  handleCardUpdate({
                    ...card,
                    data: { ...card.data, surveySelection: undefined },
                  })
                }
              ></Button>
            </section>
          </>
        )}
        <h3>Kolumner</h3>
        {card.data.columns.length === 0 ? (
          <i>Inga kolumner tillagda än</i>
        ) : (
          <Table
            slim
            columns={[
              { name: "Kolumnnamn", type: "text" },
              { name: "Mått/uttryck", type: "text" },
              { name: "", type: "text" },
            ]}
            data={card.data.columns.map((c, i) => {
              const id = i;
              const removeButton = (
                <Button
                  small
                  title="Ta bort"
                  onClick={() => {
                    handleCardUpdate({
                      ...card,
                      data: {
                        ...card.data,
                        columns: card.data.columns.filter((_, ix) => ix !== i),
                      },
                    });
                  }}
                ></Button>
              );
              switch (c.type) {
                case "stats":
                  return {
                    id,
                    cells: [
                      displayColumnName(c.columnName),
                      displayColumn(c),
                      removeButton,
                    ],
                  };
                case "micro":
                  return {
                    id,
                    cells: [
                      displayColumnName(c.columnName),
                      displayColumn(c),
                      removeButton,
                    ],
                  };
                case "user-defined":
                  return {
                    id,
                    cells: [
                      displayColumnName(c.columnName),
                      <ExpressionEditor
                        key={c.columnName}
                        initialExpression={c.expression}
                        updateExpression={(expr) =>
                          handleUpdateExpression(c.columnName, expr)
                        }
                      />,
                      removeButton,
                    ],
                  };
                default:
                  assertNever(c);
              }
            })}
          ></Table>
        )}
      </section>

      {dataframeFetching && (
        <DelayedSpinner delayShowMs={0} label="Laddar ..." />
      )}

      {defined(dataframeError) && !dataframeFetching && (
        <AlertBox intent="danger">
          <>
            <p>{dataframeError.code}</p>
            <DisplayRequestErrorInfo info={dataframeError.info} />
          </>
        </AlertBox>
      )}
      {!defined(dataframeError) && defined(currentDataframe) && (
        <>
          <h3>df</h3>
          <Table
            slim
            containerClassName="dataframe-table-container"
            columns={currentDataframe.data_frame[0]?.map((col, i) => {
              if (col === "index") {
                return {
                  name: col,
                  type: "text",
                };
              }
              return {
                name: col as string,
                type: "text",
              };
            })}
            data={currentDataframe.data_frame
              .slice(1, 200)
              .map((row, rowIx) => {
                return {
                  id: rowIx,
                  cells: row.map((col, i) => {
                    return <span>{col as string}</span>;
                  }),
                };
              })}
          ></Table>
        </>
      )}
    </div>
  );
}

function DataSelectionComponent(props: {
  mode: "stats" | "survey";
  card: DocCardPython;
  cancelAddMode: () => void;
  handleCardUpdate: (card: DocCardPython) => void;
  columnIndex: number;
}) {
  const { card, handleCardUpdate, columnIndex, mode } = props;

  const [columnNameInput, setColumnNameInput] = useState("");
  const [validationError, setValidationError] = useState<string | undefined>();
  const [geoModalOpen, toggleGeoModalOpen] = useToggle(false);

  const categories = useContext(CategoriesContext);

  // Find the previous stats column to get the initial geo and time selections
  const prevStatsColumn = card.data.columns
    .slice(0, columnIndex)
    .reverse()
    .find(isDataframeStatsColumn);

  const initialGeoSelections = prevStatsColumn?.geoSelections;
  const initialTimeSelection = prevStatsColumn?.timeSelection;
  const initialLockToLatestTime = prevStatsColumn?.lockToLatestTime;

  const [selectionParts, setSelectionParts] = useState<{
    geoSelections: GeoSelections | undefined;
    timeSelection: DateRangeRaw | undefined;
    dataSelection: DataSelectionWithoutId;
    lockToLatestTime?: boolean;
  }>({
    dataSelection: { subjectPath: [] },
    geoSelections: initialGeoSelections,
    timeSelection: initialTimeSelection,
    lockToLatestTime: initialLockToLatestTime,
  });

  const dataSelection = useMemo(() => {
    return selectionParts.dataSelection;
  }, [selectionParts.dataSelection]);
  const geoSelections = useMemo(() => {
    return selectionParts.geoSelections;
  }, [selectionParts.geoSelections]);
  const timeSelection = useMemo(() => {
    return selectionParts.timeSelection;
  }, [selectionParts.timeSelection]);
  const lockToLatestTime = useMemo(() => {
    return selectionParts.lockToLatestTime ?? false;
  }, [selectionParts.lockToLatestTime]);

  const [geoExpansions, setGeoExpansions] = useState<GeoExpansions>([]);

  const setDataSelection = useCallback((s: DataSelectionWithoutId) => {
    setSelectionParts((prev) => ({ ...prev, dataSelection: s }));
  }, []);
  const setTimeSelection = useCallback((s: DateRangeRaw | undefined) => {
    setSelectionParts((prev) => ({ ...prev, timeSelection: s }));
  }, []);
  const setGeoSelections = useCallback((s: GeoSelections | undefined) => {
    setSelectionParts((prev) => ({ ...prev, geoSelections: s }));
  }, []);

  const [enableMultiSelect, setEnableMultiSelect] = useState(() => {
    const breakdowns = prevStatsColumn?.selection.measureSelection?.breakdowns;
    const t = getCurrentIndexTypes(geoSelections, timeSelection, breakdowns);
    return t.includes("breakdowns");
  });

  const appMessagesHandler = useContext(AppMessagesContext);
  const geographies = useContext(GeographiesContext);
  if (!defined(geographies)) {
    throw new Error("Geographies must be defined");
  }

  const geoSelectionString: string = defined(geoSelections)
    ? listSelections(geoSelections)
    : "Inga";

  const revalidate = useCallback(
    (
      d: DataSelectionWithoutId,
      t: DateRangeRaw | undefined,
      colName: string,
      geo: GeoSelections | undefined
    ) => {
      if (!nonEmptyString(validationError)) {
        return;
      }

      const err = validateMeasureColumn(d, t, colName, geo);
      setValidationError(err);
    },
    [validationError]
  );

  const handleUpdateColumnName = useCallback(
    (nameRaw: string) => {
      const name = cleanColumnName(nameRaw);
      setColumnNameInput(name);
      revalidate(dataSelection, timeSelection, name, geoSelections);
    },
    [dataSelection, geoSelections, revalidate, timeSelection]
  );

  const handleUpdateGeoSelections = useCallback(
    (s: GeoSelections | undefined) => {
      setGeoSelections(s);
      revalidate(dataSelection, timeSelection, columnNameInput, s);
    },
    [
      columnNameInput,
      dataSelection,
      revalidate,
      setGeoSelections,
      timeSelection,
    ]
  );

  const resetAddMode = useCallback(() => {
    // We do not reset add mode or data selection
    // since the user may well want to continue adding similar columns

    setColumnNameInput("");
    setValidationError(undefined);
    setGeoExpansions([]);
  }, []);

  const cancelAddMode = useCallback(() => {
    setDataSelection({ subjectPath: [] });
    resetAddMode();
    props.cancelAddMode();
  }, [props, resetAddMode, setDataSelection]);

  // Only enable time range select if all breakdowns have at most one value selected
  // AND there is at most one geographic region selected
  const [enableTimeRange, setEnableTimeRange] = useState(() => {
    return card.data.columns.filter(isDataframeStatsColumn).some((c) => {
      const time = c.timeSelection;
      return !isSingleDate(time);
    });
  });

  const handleAddSurveySelection = useCallback(() => {
    const err = validateMeasureColumn(
      dataSelection,
      timeSelection,
      columnNameInput,
      geoSelections
    );
    if (defined(err)) {
      return setValidationError(err);
    }
    if (!defined(timeSelection)) {
      return;
    }

    handleCardUpdate({
      ...card,
      data: {
        ...card.data,
        surveySelection: { selection: dataSelection, timeSelection },
      },
    });
    resetAddMode();
    cancelAddMode();
  }, [
    cancelAddMode,
    card,
    columnNameInput,
    dataSelection,
    geoSelections,
    handleCardUpdate,
    resetAddMode,
    timeSelection,
  ]);

  const handleAddDataSelection = useCallback(() => {
    const err = validateMeasureColumn(
      dataSelection,
      timeSelection,
      columnNameInput,
      geoSelections
    );
    if (defined(err)) {
      return setValidationError(err);
    }
    if (!defined(geoSelections)) {
      return;
    }
    if (!defined(timeSelection)) {
      return;
    }

    handleCardUpdate({
      ...card,
      data: {
        ...card.data,
        columns: [
          ...card.data.columns,
          {
            type: "stats",
            columnName: columnNameInput,
            selection: dataSelection,
            geoSelections,
            timeSelection,
            lockToLatestTime: lockToLatestTime,
          },
        ],
      },
    });
    resetAddMode();
  }, [
    card,
    columnNameInput,
    dataSelection,
    geoSelections,
    handleCardUpdate,
    lockToLatestTime,
    resetAddMode,
    timeSelection,
  ]);

  const handleUpdateDataSelection = useCallback(
    async (prev: DataSelectionWithoutId, s: DataSelectionWithoutId) => {
      if (s.subjectPath.length === 3 && s.subjectPath.every(nonEmptyString)) {
        // has no measure selection
        if (!defined(s.measureSelection)) {
          const res = await handleGetDefaultMeasure(
            s.subjectPath,
            false,
            false,
            TimeResolution.maximal(),
            false
          );
          if (!defined(res)) {
            appMessagesHandler?.add("warning", "Kunde inte hämta måttinfo");
            return;
          }

          const { defaultMeasure, availableMeasures } = res;

          const measureSelectionWithoutDates =
            await defaultMeasureSelectionPrimary(
              defaultMeasure,
              availableMeasures
            );
          const measureSelection = await setMeasureAvailableDatesMut(
            { ...measureSelectionWithoutDates },
            false
          );

          const dataSelection = {
            ...s,
            subjectPath: getSubjectPath(measureSelection.measure),
            measureSelection,
          };

          setSelectionParts((prev) => ({
            ...prev,
            dataSelection,
            geoSelections: alignGeoSelections(measureSelection, geoSelections),
            timeSelection: getMeasureSelectionDefaultTime(measureSelection),
          }));

          revalidate(
            dataSelection,
            timeSelection,
            columnNameInput,
            geoSelections
          );
        } else {
          if (
            prev.measureSelection?.measure.data_id !==
            s.measureSelection.measure.data_id
          ) {
            // Flipping measure, we need to set default settings/dates
            const m = await setMeasureAvailableDatesMut(
              await defaultMeasureSelectionPrimary(
                s.measureSelection.measure,
                s.measureSelection.available
              ),
              false
            );
            const d = {
              ...s,
              subjectPath: getSubjectPath(m.measure),
              measureSelection: m,
            };
            setSelectionParts((prev) => ({
              ...prev,
              dataSelection: d,
              geoSelections: alignGeoSelections(m, geoSelections),
              timeSelection: getMeasureSelectionDefaultTime(m),
            }));

            revalidate(d, timeSelection, columnNameInput, geoSelections);
          } else {
            // Changed breakdowns
            setDataSelection(s);
            revalidate(s, timeSelection, columnNameInput, geoSelections);
          }
        }
      }
    },
    [
      revalidate,
      timeSelection,
      columnNameInput,
      geoSelections,
      appMessagesHandler,
      setDataSelection,
    ]
  );

  const handleSetLockToLatestTime = useCallback(
    (lock: boolean) => {
      setSelectionParts((prev) => ({ ...prev, lockToLatestTime: lock }));
    },
    [setSelectionParts]
  );

  const handleUpdateTimeSelection = useCallback(
    (timespan: DateRangeRaw) => {
      setTimeSelection(timespan);
      const availableDates = dataSelection.measureSelection?.availableDates;
      if (!defined(availableDates)) {
        return;
      }

      if (last(timespan) !== last(availableDates)) {
        handleSetLockToLatestTime(false);
      } else {
        handleSetLockToLatestTime(true);
      }

      revalidate(dataSelection, timespan, columnNameInput, geoSelections);
    },
    [
      columnNameInput,
      dataSelection,
      geoSelections,
      handleSetLockToLatestTime,
      revalidate,
      setTimeSelection,
    ]
  );

  const selectionIsSurvey =
    defined(dataSelection.measureSelection) &&
    (measureSelectionIsSurvey(dataSelection.measureSelection) ||
      measureSelectionIsSurveyString(dataSelection.measureSelection));

  return (
    <>
      {geoModalOpen && (
        <GeoModalDataframe
          dataSelection={dataSelection}
          geoSelections={geoSelections}
          geoExpansions={geoExpansions}
          setGeoExpansions={setGeoExpansions}
          setGeoSelections={handleUpdateGeoSelections}
          geographies={geographies}
          handleClose={toggleGeoModalOpen}
        />
      )}

      <SingleDataSelectionInner
        item={categories}
        selectSingle={!enableMultiSelect}
        dataSelection={{ ...dataSelection, id: "-" }}
        dataframeMode
        autofill
        groupingSelectionMode={false}
        isGroupingSelection={false}
        setDataSelection={(s) => {
          handleUpdateDataSelection(dataSelection, s);
        }}
      />
      {defined(dataSelection.measureSelection) && !selectionIsSurvey && (
        <div className="simple-geo-display">
          <p>
            <strong>Valda geografiska områden</strong>: {geoSelectionString}
          </p>
          <Button small title="Ändra" onClick={toggleGeoModalOpen}></Button>
        </div>
      )}

      {card.data.columns.length === 0 &&
        defined(dataSelection.measureSelection) && (
          <div className="checkboxes padding-y-md">
            {!selectionIsSurvey && (
              <Checkbox
                className="checkbox"
                label="Flerval"
                checked={enableMultiSelect}
                onChange={() => setEnableMultiSelect(!enableMultiSelect)}
              />
            )}
            <Checkbox
              className="checkbox"
              label="Tidsintervall"
              checked={enableTimeRange}
              onChange={() => {
                const nextState = !enableTimeRange;
                if (!nextState) {
                  const lastDate = timeSelection?.[1];
                  if (defined(lastDate)) {
                    handleUpdateTimeSelection([lastDate, lastDate]);
                  }
                }
                setEnableTimeRange(nextState);
              }}
            />
          </div>
        )}

      {defined(dataSelection.measureSelection) && defined(timeSelection) && (
        <TimeSelect
          timeSelection={timeSelection}
          lockToLatest={lockToLatestTime ?? null}
          handleSetLockToLatestTime={(lock) => {
            handleSetLockToLatestTime(lock);
          }}
          availableDates={dataSelection.measureSelection.availableDates}
          timeResolution={dataSelection.measureSelection.measure.resolution}
          enableRange={enableTimeRange}
          handleUpdate={handleUpdateTimeSelection}
        />
      )}
      <div className="column-submit">
        {mode === "stats" && (
          <TextField
            required
            label="Kolumnnamn"
            value={columnNameInput}
            onChange={(e) => {
              handleUpdateColumnName(e.currentTarget.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAddDataSelection();
              } else if (e.key === "Escape") {
                cancelAddMode();
              }
            }}
          />
        )}
        <Button title="Avbryt" onClick={() => cancelAddMode()} />
        <Button
          disabled={defined(validationError)}
          intent="primary"
          title="Lägg till"
          onClick={
            mode === "stats" ? handleAddDataSelection : handleAddSurveySelection
          }
        />
      </div>
      {defined(validationError) && (
        <AlertBox intent="warning">
          <span>{validationError}</span>
        </AlertBox>
      )}
    </>
  );
}

function DataSelectionMicroComponent(props: {
  card: DocCardPython;
  cancelAddMode: () => void;
  handleCardUpdate: (card: DocCardPython) => void;
  columnIndex: number;
}) {
  const { card, handleCardUpdate, columnIndex } = props;

  const [columnNameInput, setColumnNameInput] = useState("");
  const [validationError, setValidationError] = useState<string | undefined>();

  const geoTree = useGeoTree();

  // Find the previous stats column to get the initial geo and time selections
  const prevMicroColumn = card.data.columns
    .slice(0, columnIndex)
    .reverse()
    .find(isDataframeMicroColumn);

  const initialTimeSelection = prevMicroColumn?.timeSelection;

  const [selectionParts, setSelectionParts] = useState<{
    timeSelection: DateRangeRaw | undefined;
    dataSelection: DataSelectionMicro | undefined;
    lockToLatest?: boolean;
  }>({
    dataSelection: undefined,
    timeSelection: initialTimeSelection,
    lockToLatest: false,
  });

  const canLockToLatest = selectionParts.dataSelection?.type === "primary";

  const microCategories = useContext(CategoriesContextMicroPrimaryContext);
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);
  const autofill = true;

  const dataSelection = useMemo(() => {
    return selectionParts.dataSelection;
  }, [selectionParts.dataSelection]);
  const timeSelection = useMemo(() => {
    return selectionParts.timeSelection;
  }, [selectionParts.timeSelection]);
  const lockToLatestSelection = useMemo(() => {
    if (!canLockToLatest) {
      return undefined;
    }
    return selectionParts.lockToLatest ?? false;
  }, [canLockToLatest, selectionParts.lockToLatest]);
  const availableDates = useMemo(() => {
    return dataSelection?.availableDates;
  }, [dataSelection?.availableDates]);

  const availableMeasures = useMicroAvailableMeasures(
    dataSelection?.subjectPath ?? [undefined, undefined, undefined],
    adminShowDraftData,
    [],
    ["decimal", "integer"]
  );

  const setDataSelection = useCallback((s: DataSelectionMicro | undefined) => {
    setSelectionParts((prev) => ({ ...prev, dataSelection: s }));
  }, []);

  const [enableMultiSelect, setEnableMultiSelect] = useState(() => {
    return false;
  });

  const geographies = useContext(GeographiesContext);
  if (!defined(geographies)) {
    throw new Error("Geographies must be defined");
  }

  const revalidate = useCallback(
    (
      d: DataSelectionMicro | undefined,
      t: DateRangeRaw | undefined,
      colName: string
    ) => {
      if (!defined(d)) {
        return;
      }
      if (!nonEmptyString(validationError)) {
        return;
      }

      const err = validateMicroMeasureColumn(d, t, colName);
      setValidationError(err);
    },
    [validationError]
  );

  const handleUpdateColumnName = useCallback(
    (nameRaw: string) => {
      const name = cleanColumnName(nameRaw);
      setColumnNameInput(name);
      revalidate(dataSelection, timeSelection, name);
    },
    [dataSelection, revalidate, timeSelection]
  );

  const resetAddMode = useCallback(() => {
    // We do not reset add mode or data selection
    // since the user may well want to continue adding similar columns

    setColumnNameInput("");
    setValidationError(undefined);
  }, []);

  const cancelAddMode = useCallback(() => {
    setDataSelection(undefined);
    resetAddMode();
    props.cancelAddMode();
  }, [props, resetAddMode, setDataSelection]);

  const handleUpdateTimeSelection = useCallback(
    (timespan: DateRangeRaw) => {
      const lockToLatest =
        defined(availableDates) &&
        canLockToLatest &&
        last(timespan) === last(availableDates);

      setSelectionParts({
        lockToLatest: lockToLatest,
        dataSelection,
        timeSelection: timespan,
      });
      revalidate(dataSelection, timespan, columnNameInput);
    },
    [
      availableDates,
      canLockToLatest,
      columnNameInput,
      dataSelection,
      revalidate,
    ]
  );

  const handleAddDataSelection = useCallback(() => {
    const err = validateMicroMeasureColumn(
      dataSelection,
      timeSelection,
      columnNameInput
    );
    if (defined(err)) {
      return setValidationError(err);
    }
    if (!defined(timeSelection)) {
      return;
    }
    if (!defined(dataSelection)) {
      return;
    }

    handleCardUpdate({
      ...card,
      data: {
        ...card.data,
        columns: [
          ...card.data.columns,
          {
            type: "micro",
            columnName: columnNameInput,
            selection: dataSelection,
            timeSelection,
            lockToLatestTime: lockToLatestSelection,
          },
        ],
      },
    });
    resetAddMode();
  }, [
    card,
    columnNameInput,
    dataSelection,
    handleCardUpdate,
    lockToLatestSelection,
    resetAddMode,
    timeSelection,
  ]);

  const [changePending, setChangePending] = useState(false);
  const handleChangeSubjectPath = useCallback(
    (_id: string | undefined, path: MicroSubjectPath) => {
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;
      const filledPath = autofill
        ? defaultSubject(path, microCategories)
        : path;
      if (!defined(filledPath) || !filledPath.every(defined)) {
        return Promise.resolve();
      }
      const prevSelection = dataSelection;
      const prevPath = prevSelection?.subjectPath;

      // If the resulting path is the same as the current path, do nothing.
      if (isEqual(filledPath, prevPath)) {
        logger.info("Path is the same, not updating");
        return Promise.resolve();
      }

      setChangePending(true);
      return getMicroMeasuresWithCache(
        filledPath,
        adminShowDraftData,
        EMPTY_ARRAY,
        ["decimal", "integer"]
      )
        .then(async (res) => {
          const availableMeasures = res.ok();
          if (!defined(availableMeasures)) {
            logger.warn("No available measures found for path:", filledPath);
            return;
          }
          if (shouldAbort()) {
            return;
          }

          const currentlySelectedMeasures = card.data.columns.filter(
            isDataframeMicroColumn
          );

          // Can use non-computed, non-geo measures only
          const selectableMeasures = availableMeasures.filter(
            (a) =>
              !currentlySelectedMeasures.some(
                (selected) =>
                  isMicroMeasureRegularDto(a) &&
                  !defined(a.computed_measurement_type) &&
                  selected.selection.measure?.id === a.mikro_id
              )
          );
          const defaultMeasure = selectableMeasures[0];

          // If no applicable measure found, still update UI
          // or else the user won't understand what's going on
          if (!defined(defaultMeasure)) {
            setDataSelection(undefined);
            return;
          }

          const dimensions = (
            await getMicroDimensionsWithCache(
              defaultMeasure.mikro_id,
              adminShowDraftData
            )
          )
            .map((d) => d ?? [])
            .ok();

          if (!defined(dimensions)) {
            logger.error(
              "dimensions object not populated (nor empty array) - this means an error occurred"
            );
            return;
          }

          const selectedDimensions = getDefaultSelectedDimensions(dimensions);
          const availableDates = (
            await getMicroAvailableDates(
              defaultMeasure.mikro_id,
              selectedDimensions,
              adminShowDraftData
            )
          ).ok();

          if (shouldAbort()) {
            return;
          }
          if (!defined(availableDates)) {
            logger.warn(
              "No available dates found for measure:",
              defaultMeasure
            );
            return undefined;
          }
          const microMeasure = new MicroMeasure(defaultMeasure, dimensions);
          const newSelection = makePrimaryMicroDataSelection(
            microMeasure.subjectPath(),
            microMeasure.dto(),
            microMeasure.dimensionsDto(),
            availableDates,
            selectedDimensions,
            undefined
          );
          const lastDate = last(availableDates);
          if (defined(lastDate)) {
            handleUpdateTimeSelection([lastDate, lastDate]);
          }
          setDataSelection(newSelection);
        })
        .finally(() => {
          setChangePending(false);
        });
    },
    [
      adminShowDraftData,
      autofill,
      card.data.columns,
      dataSelection,
      getCurrentCount,
      handleUpdateTimeSelection,
      incrementUpdateCount,
      microCategories,
      setDataSelection,
    ]
  );

  const getDefaultDataSelection = useCallback(
    async (
      subjectPath: MicroSubjectPath,
      measureId: number,
      measureDto: MicroMeasureDto
    ) => {
      const dims = (
        await getMicroDimensionsWithCache(measureId, adminShowDraftData)
      ).unwrap();
      const selectedDimensions: SelectedDimensionsV2 =
        getDefaultSelectedDimensions(dims);

      const availableDates = (
        await getMicroAvailableDates(
          measureId,
          selectedDimensions,
          adminShowDraftData
        )
      ).unwrap();

      return makePrimaryMicroDataSelection(
        subjectPath,
        measureDto,
        dims,
        availableDates ?? [],
        selectedDimensions,
        undefined
      );
    },
    [adminShowDraftData]
  );

  const handleChangeMeasure = useCallback(
    async (
      selectionId: string | undefined,
      measureId: number,
      // We don't support computed measurements here
      _computedMeasureType?: ComputedMeasurementType
    ) => {
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;
      setChangePending(true);
      try {
        const subjectPath = dataSelection?.subjectPath;
        if (!defined(subjectPath)) {
          logger.error("No subject path found for measure change");
          return;
        }
        if (!defined(geoTree)) {
          return;
        }
        const availableMeasures = (
          await getMicroMeasuresWithCache(
            subjectPath as any,
            adminShowDraftData,
            [],
            ["decimal", "integer"]
          )
        ).unwrap();
        const selectedMeasure = availableMeasures.find(
          (m) =>
            m.mikro_id === measureId &&
            isMicroMeasureRegularDto(m) &&
            !defined(m.computed_measurement_type)
        );
        if (!defined(selectedMeasure) || shouldAbort()) {
          return;
        }
        const defaultDataSelection = await getDefaultDataSelection(
          subjectPath,
          measureId,
          selectedMeasure
        );
        if (!defined(defaultDataSelection) || shouldAbort()) {
          logger.error("Could not get default data selection");
          return;
        }
        const lastDate = last(defaultDataSelection.availableDates);
        if (defined(lastDate)) {
          handleUpdateTimeSelection([lastDate, lastDate]);
        }
        setDataSelection(defaultDataSelection);
      } finally {
        setChangePending(false);
      }
    },
    [
      adminShowDraftData,
      dataSelection?.subjectPath,
      geoTree,
      getCurrentCount,
      getDefaultDataSelection,
      handleUpdateTimeSelection,
      incrementUpdateCount,
      setDataSelection,
    ]
  );

  const handleUpdateBreakdowns = useCallback(
    async (labelId: string, keys: number[], selected: boolean) => {
      if (!defined(dataSelection)) {
        throw new Error("dataSelection is not defined");
      }
      const selectedDimensions = selected
        ? {
            ...dataSelection.selectedDimensions,
            [labelId]: uniq([
              ...(dataSelection.selectedDimensions?.[labelId] ?? []),
              ...keys,
            ]),
          }
        : {
            ...dataSelection.selectedDimensions,
            [labelId]: (
              dataSelection.selectedDimensions?.[labelId] ?? []
            ).filter((k) => !keys.includes(k)),
          };
      setDataSelection({
        ...dataSelection,
        selectedDimensions,
      });
    },
    [dataSelection, setDataSelection]
  );

  const handleClearBreakdowns = useCallback(
    (dataColumn: string) => {
      if (!defined(dataSelection)) {
        throw new Error("dataSelection is not defined");
      }
      const selectedDimensions = {
        ...dataSelection.selectedDimensions,
        [dataColumn]: [],
      };
      setDataSelection({
        ...dataSelection,
        selectedDimensions,
      });
    },
    [dataSelection, setDataSelection]
  );

  const handleSetSingleBreakdownValue = useCallback(
    (dataColumn: string, key?: number) => {
      if (!defined(dataSelection)) {
        throw new Error("dataSelection is not defined");
      }
      const selectedDimensions = {
        ...dataSelection.selectedDimensions,
        [dataColumn]: defined(key) ? [key] : undefined,
      };
      setDataSelection({
        ...dataSelection,
        selectedDimensions,
      });
    },
    [dataSelection, setDataSelection]
  );

  const {
    areasInputProps,
    subareasInputProps,
    subjectsInputProps,
    measureInputProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedMeasureKey,
  } = useSelectionInputState(
    changePending,
    dataSelection,
    handleChangeSubjectPath,
    handleChangeMeasure,
    availableMeasures,
    [], // Selected measures is not relevant when creating a dataframe -- we can select the same measurement multiple times
    microCategories
  );

  const resolution = dataSelection?.measure?.timeResolution;

  return (
    <>
      <div className="micro-measure-selection">
        <StyledDropdown
          className="item"
          dropdownWidth="auto"
          {...areasInputProps}
        />
        <StyledDropdown
          className="item"
          dropdownWidth="auto"
          {...subareasInputProps}
        />
        <StyledDropdown
          className="item"
          dropdownWidth="auto"
          {...subjectsInputProps}
        />
        <StyledDropdown
          className="measure-dropdown item"
          dropdownWidth="auto"
          {...measureInputProps}
        ></StyledDropdown>
      </div>

      <div className="micro-breakdowns-selection">
        {defined(dataSelection) && defined(dataSelection.measure) && (
          <DimensionSelectionOuter
            changePending={changePending}
            multiSelect={enableMultiSelect} // Always enable multi-select for secondary (point measures etc) selections
            measureId={dataSelection.measure.id}
            handleClearBreakdowns={handleClearBreakdowns}
            handleSetSingleBreakdownKey={handleSetSingleBreakdownValue}
            selectedBreakdowns={dataSelection.selectedDimensions ?? {}}
            handleSetBreakdowns={handleUpdateBreakdowns}
          />
        )}
      </div>

      {card.data.columns.length === 0 && defined(dataSelection) && (
        <div className="checkboxes padding-y-md">
          <Checkbox
            className="checkbox"
            label="Flerval"
            checked={enableMultiSelect}
            onChange={() => setEnableMultiSelect(!enableMultiSelect)}
          />
        </div>
      )}

      {defined(availableDates) &&
        defined(timeSelection) &&
        defined(resolution) && (
          <div className="margin-top-sm">
            <TimeSelect
              lockToLatest={lockToLatestSelection ?? null}
              handleSetLockToLatestTime={(lock) => {
                setSelectionParts((prev) => ({ ...prev, lockToLatest: lock }));
              }}
              timeSelection={timeSelection}
              availableDates={availableDates}
              enableRange={false}
              timeResolution={resolution}
              handleUpdate={handleUpdateTimeSelection}
            />
          </div>
        )}

      <div className="column-submit">
        <TextField
          required
          label="Kolumnnamn"
          value={columnNameInput}
          onChange={(e) => {
            handleUpdateColumnName(e.currentTarget.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleAddDataSelection();
            } else if (e.key === "Escape") {
              cancelAddMode();
            }
          }}
        />
        <Button title="Avbryt" onClick={() => cancelAddMode()} />
        <Button
          disabled={defined(validationError)}
          intent="primary"
          title="Lägg till"
          onClick={handleAddDataSelection}
        />
      </div>
      {defined(validationError) && (
        <AlertBox intent="warning">
          <span>{validationError}</span>
        </AlertBox>
      )}
    </>
  );
}

function ExpressionEditor(props: {
  initialExpression: string;
  updateExpression: (expression: string) => void;
}) {
  const initialExpression = props.initialExpression;
  const [value, setValue] = useState(initialExpression);
  const { updateExpression } = props;

  useEffect(() => {
    if (value === initialExpression) {
      return;
    }

    const handle = setTimeout(() => {
      updateExpression(value);
    }, 500);
    return () => {
      clearTimeout(handle);
    };
  }, [initialExpression, updateExpression, value]);

  return (
    <TextField
      type="string"
      value={value}
      onChange={(e) => {
        setValue(e.currentTarget.value);
      }}
    />
  );
}

function listSelections(selection: GeoSelections): string {
  const maxLen = 30;
  const entries = flatten(Object.values(selection));
  if (entries.length > 3) {
    return (
      entries
        .slice(0, 3)
        .map((e) => e.label)
        .join(", ")
        .slice(0, maxLen) + "..."
    );
  }
  return truncate(entries.map((item) => item.label).join(", "), maxLen);
}

function displayColumn(c: DataframeStatsColumn | DataframeMicroColumn): string {
  if (c.type === "micro") {
    return (
      measureInfoMicro(c.selection) +
      "; " +
      displayTime(c.timeSelection, c.selection.measure?.timeResolution) +
      (c.lockToLatestTime ? " (låst till senaste)" : "")
    );
  }

  return (
    measureInfo(c.selection.measureSelection) +
    "; " +
    displayTime(
      c.timeSelection,
      c.selection.measureSelection?.measure.resolution
    ) +
    (c.lockToLatestTime ? " (låst till senaste)" : "") +
    "; " +
    listSelections(c.geoSelections)
  );
}

function displayRawDate(d: string, resolution?: string): string {
  if (!defined(resolution)) {
    return d;
  }
  const timeRes = TimeResolution.deserialize(resolution);
  const f = getDateFormatter(timeRes);
  return f(new Date(d));
}

function displayTime(dRaw: DateRangeRaw, resolution?: string): string {
  const displayDates = dRaw.map((dr) => displayRawDate(dr, resolution)) as [
    string,
    string
  ];
  if (isSingleDate(displayDates)) {
    return displayDates[0];
  }
  return displayDates.join(" - ");
}

function measureInfoMicro(selection: DataSelectionMicro): string {
  const measure = selection.measure;
  if (!defined(measure)) {
    return "";
  }
  const breakdowns = selection.selectedDimensions;
  const dims = measure.dimensions;
  const d = Object.entries(breakdowns)
    .map(([key, values]) => {
      const currentDim = dims?.find((d) => d.data_column === key);
      const labels = values?.map(
        (v) => currentDim?.values?.find((val) => val.id === v)?.label ?? ""
      );

      if (!defined(labels)) {
        return undefined;
      }
      if (labels.length === 1 && isBreakdownTotal(labels[0])) {
        return undefined;
      }

      return `${currentDim?.label}: ${labels.join(", ")}`;
    })
    .filter(defined);
  const base = `${selection.subjectPath[2] ?? ""} > ${measure.measure}`;
  if (d.length > 0) {
    return `${base}; (${d.join(", ")})`;
  }
  return base;
}

function measureInfo(measureSelection?: MeasureSelection) {
  if (!defined(measureSelection)) {
    return "";
  }
  const measure = measureSelection.measure;
  const breakdowns = measureSelection.breakdowns;
  const dims = measure.dimensions;
  const d = Object.entries(breakdowns)
    .map(([key, values]) => {
      const currentDim = dims.find((d) => d.data_column === key);
      const labels = values?.map(
        (v) => currentDim?.values?.find((val) => val.id === v)?.label ?? ""
      );

      if (!defined(labels)) {
        return undefined;
      }
      if (labels.length === 1 && isBreakdownTotal(labels[0])) {
        return undefined;
      }

      return `${currentDim?.label}: ${labels.join(", ")}`;
    })
    .filter(defined);
  const base = `${measure.subject} > ${measure.measure}`;
  if (d.length > 0) {
    return `${base}; (${d.join(", ")})`;
  }
  return base;
}

function getMeasureSelectionDefaultTime(
  sel: MeasureSelection
): DateRangeRaw | undefined {
  if (sel.availableDates.length === 0) {
    return undefined;
  }
  if (sel.availableDates.length === 1) {
    return [sel.availableDates[0], sel.availableDates[0]];
  }
  const lastDate = last(sel.availableDates);
  if (!defined(lastDate)) {
    return undefined;
  }
  return [lastDate, lastDate];
}

function getCurrentIndexTypes(
  geoSelections: GeoSelections | undefined,
  timeSelection: DateRangeRaw | undefined,
  breakdowns: SelectedDimensionsV2 | undefined
) {
  const indexTypes: string[] = [];
  if (
    defined(geoSelections) &&
    sum(Object.values(geoSelections).map((vals) => vals.length)) > 1
  ) {
    indexTypes.push("geografiska områden");
  }

  if (defined(timeSelection) && !isSingleDate(timeSelection)) {
    indexTypes.push("tid");
  }

  const breakdownMulti = Object.values(breakdowns ?? {}).some(
    (vals) => defined(vals) && vals.length > 1
  );

  if (breakdownMulti) {
    indexTypes.push("breakdowns");
  }

  return indexTypes;
}

// Take all inputs as args
function validateMeasureColumn(
  dataSelection: DataSelectionWithoutId,
  timeSelection: DateRangeRaw | undefined,
  columnNameInput: string,
  geoSelections: GeoSelections | undefined
) {
  const measureSelection = dataSelection.measureSelection;
  if (!defined(measureSelection)) {
    return "Mått saknas";
  }
  const isSurvey =
    measureSelectionIsSurvey(measureSelection) ||
    measureSelectionIsSurveyString(measureSelection);
  if (isSurvey) {
    return undefined;
  }

  if (!nonEmptyString(columnNameInput)) {
    return "Kolumnnamn saknas";
  }
  if (!defined(geoSelections)) {
    return "Val av geografiskt område saknas";
  }
  if (!defined(timeSelection)) {
    return "Val av tid saknas";
  }

  const currentIndexTypes = getCurrentIndexTypes(
    geoSelections,
    timeSelection,
    dataSelection.measureSelection?.breakdowns
  );

  if (currentIndexTypes.length > 1) {
    return (
      "Flera indextyper valda. Endast en av följande får ha mer än ett valt värde: " +
      currentIndexTypes.join(", ")
    );
  }
  return undefined;
}

function validateMicroMeasureColumn(
  dataSelection: DataSelectionMicro | undefined,
  timeSelection: DateRangeRaw | undefined,
  columnNameInput: string
) {
  if (!defined(dataSelection)) {
    return "Mått saknas";
  }
  const measureSelection = dataSelection;
  if (!defined(measureSelection)) {
    return "Mått saknas";
  }

  if (!defined(timeSelection)) {
    return "Val av tid saknas";
  }

  if (!nonEmptyString(columnNameInput)) {
    return "Kolumnnamn saknas";
  }

  return undefined;
}

function alignGeoSelections(
  measureSelection: MeasureSelection,
  currentGeo: GeoSelections | undefined
) {
  const supportedGeoTypes = measureSelection.measure.geo_types;
  const validGeoSelectionsPartial = fromPairs(
    Object.entries(currentGeo ?? emptyGeoSelections())
      .map(([key, value]) => {
        if (supportedGeoTypes?.includes(key as any)) {
          return [key, value];
        }
        return undefined;
      })
      .filter(defined)
  );

  return {
    ...emptyGeoSelections(),
    ...validGeoSelectionsPartial,
  } as GeoSelections | undefined;
}

function displayColumnName(c: string) {
  return `df["${c}"]`;
}
