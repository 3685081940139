import { config } from "../../../../../config";
import { DimensionV2Dto } from "../../../../domain/measure/definitions";
import { HttpResult } from "../../../../infra/HttpResult";
import { authedRequest, decodedAuthedRequest } from "../../shared";
import { DraftConflictDto, DraftConflictsDtoRT } from "./shared";

export function editStatsDimensionV2(
  dimensionId: number,
  dimension: DimensionV2Dto
): Promise<HttpResult<void>> {
  return authedRequest<void>(
    config.apis.statsV2,
    `admin/stats/dimensions/${dimensionId}`,
    dimension,
    "PUT"
  );
}

export function editSurveyDimensionV2(
  dimensionId: number,
  dimension: DimensionV2Dto
): Promise<HttpResult<void>> {
  return authedRequest<void>(
    config.apis.statsV2,
    `admin/survey/dimensions/${dimensionId}`,
    dimension,
    "PUT"
  );
}

export function editMicroDimension(
  dimensionId: number,
  dimension: DimensionV2Dto
): Promise<HttpResult<void>> {
  return authedRequest<void>(
    config.apis.statsV1,
    `admin/mikro/dimensions/${dimensionId}`,
    dimension,
    "PUT"
  );
}

export function getUpdateConflictsStats(
  id: number
): Promise<HttpResult<DraftConflictDto[]>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    `admin/stats/draftconflicts/${id}`,
    undefined,
    "GET",
    DraftConflictsDtoRT
  ).then((res) => res.map((data) => data ?? []));
}

export function getUpdateConflictsMikro(
  id: number
): Promise<HttpResult<DraftConflictDto[]>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    `admin/mikro/draftconflicts/${id}`,
    undefined,
    "GET",
    DraftConflictsDtoRT
  ).then((res) => res.map((data) => data ?? []));
}

export function getUpdateConflictsSurvey(
  id: number
): Promise<HttpResult<DraftConflictDto[]>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    `admin/survey/draftconflicts/${id}`,
    undefined,
    "GET",
    DraftConflictsDtoRT
  ).then((res) => res.map((data) => data ?? []));
}
