import mapboxgl from "mapbox-gl";

import { MicroDataset } from "../../../../../../lib/application/stats/datasets/MicroDataset";
import { assertNever } from "../../../../../../lib/core/assert";
import { defined } from "../../../../../../lib/core/defined";
import { mapOptional } from "../../../../../../lib/core/func/mapOptional";
import { nonEmptyString } from "../../../../../../lib/core/nonEmptyString";
import { logger } from "../../../../../../lib/infra/logging";
import { PointerResult } from "../useGeoPanelActions";
import "./HoverResult.scss";
import { MicroMapSettings } from "../../../../../../lib/application/state/stats/document-core/core-micro";

export interface HighlightedResult {
  type: "hover" | "fixed";
  position: mapboxgl.Point;
  lngLat: mapboxgl.LngLat;
  data: {
    label?: string;
    infoLines?: string[];
    value?: string;
    unit?: string;
  };
}

export function makeHoverPopup(
  highlightedResult: HighlightedResult,
  settings: MicroMapSettings
): mapboxgl.Popup | undefined {
  const {
    label: labelRaw,
    value: valueRaw,
    infoLines: infoLinesRaw,
    unit,
  } = highlightedResult.data;
  const label = settings.microGeoLabelShowLabel ?? true ? labelRaw : undefined;
  const value = settings.microGeoLabelShowValue ?? true ? valueRaw : undefined;
  const infoLines =
    settings.microGeoLabelShowInfoLines ?? true ? infoLinesRaw : undefined;

  if (
    !defined(label) &&
    !defined(value) &&
    (!defined(infoLines) || infoLines.length === 0)
  ) {
    return;
  }
  const html = `<div class="highlighted-result">${
    nonEmptyString(label) ? `<p class="label"><em>${label}</em></p>` : ""
  }${
    defined(infoLines) && infoLines.length > 0
      ? infoLines.map((l) => `<p>${l}</p>`).join("")
      : ""
  }${
    defined(value)
      ? `<p class="value"><strong>${value}</strong>${
          defined(unit) ? `<span class="unit"> ${unit}</span>` : ""
        }</p>`
      : ""
  }</div>`;
  const offset = 20;
  const popup = new mapboxgl.Popup({
    offset: {
      top: [0, offset],
      left: [offset, 0],
      right: [-offset, 0],
      bottom: [0, -offset],
    },
    closeButton: false,
  })
    .setHTML(html)
    .setLngLat(highlightedResult.lngLat);
  popup.addClassName("highlighted-result-popup");
  return popup;
}

export function getHightlightedResult(
  resultType: HighlightedResult["type"],
  hoverResult: PointerResult,
  dataset: MicroDataset | undefined
) {
  const mapFunc: (d: MicroDataset) => HighlightedResult | undefined = (
    d: MicroDataset
  ) => {
    const results = d.microMapResults();
    if (!defined(results)) {
      return;
    }
    if (results.resultsType === "compare-units-regso") {
      const match = results.regsoResults?.find(
        (a) =>
          hoverResult.properties.type === "regso" &&
          a.regso === hoverResult.properties.data.regso
      );
      return defined(match)
        ? {
            type: resultType,
            position: hoverResult.position,
            lngLat: hoverResult.lngLat,
            data: {
              label: match.regsoLabel,
              value: dataset?.displayValue(match.value),
            },
          }
        : undefined;
    } else if (results.resultsType === "compare-units-deso") {
      const match = results.desoResults?.find(
        (a) =>
          hoverResult.properties.type === "deso" &&
          a.deso === hoverResult.properties.data.deso
      );
      return defined(match)
        ? {
            type: resultType,
            position: hoverResult.position,
            lngLat: hoverResult.lngLat,
            data: {
              label: match.desoLabel,
              value: dataset?.displayValue(match.value),
            },
          }
        : undefined;
    } else if (results.resultsType === "compare-groups") {
      logger.warn("No support for highlighting groups");
      return;
    }
    assertNever(results.resultsType);
  };
  const datasetMatch: HighlightedResult | undefined = mapOptional(
    mapFunc,
    dataset
  );
  return datasetMatch;
}
