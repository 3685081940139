import { config } from "../../../../config";
import { defined } from "../../../core/defined";
import { CreateExternalMetadata } from "../../../domain-admin/external_metadata";
import {
  AdminMeasuresListResponse,
  AdminMeasuresListResponseMicro,
  AdminMeasuresListResponseMicroRT,
  AdminMeasuresListResponseRT,
  AdminMetadata,
  AdminMetadataOrAlias,
  AdminMetadataOrAliasRT,
  AdminMetadataRT,
} from "../../../domain-admin/metadata";
import { MeasureAlertStatus } from "../../../domain/alerts";
import { OrganizationResponse } from "../../../infra/api_responses/admin/organizations";
import { AlertStatusRT } from "../../../infra/api_responses/alerts";
import { SurveyValuesListResponseRT } from "../../../infra/api_responses/measures/survey";
import { HttpResult } from "../../../infra/HttpResult";
import { logger } from "../../../infra/logging";
import { LatestSettingsFormat } from "../../state/stats/default-settings/common";
import { clearCachedMeasureLists } from "../common_requests";
import { authedRequest, decodedAuthedRequest } from "../shared";
import { RestrictionMode } from "./measure_management/create";
import { listOrganizationsV2 } from "./organization_management";

export function submitMetadata(
  metadataBody: CreateExternalMetadata
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    "admin/measures/customersurvey",
    metadataBody,
    "POST"
  );
}

export function submitMetadataSurvey(
  metadataBody: CreateExternalMetadata,
  restrictionMode: RestrictionMode,
  create_default_background_filters: boolean,
  is_customer_data: boolean
): Promise<HttpResult<unknown>> {
  const body = {
    ...metadataBody,
    restriction_mode: restrictionMode,
    create_default_background_filters,
    is_customer_data,
  };
  return authedRequest(
    config.apis.importV2,
    "admin/survey/create",
    body,
    "POST"
  );
}

export function setDefaultMeasure(subjectPath: string[], measureId: number) {
  const [area, subarea, subject] = subjectPath;
  return authedRequest<unknown>(
    config.apis.statsV1,
    `admin/defaultmeasure`,
    { area, subarea, subject, data_id: measureId },
    "POST"
  ).then(() => {
    clearCachedMeasureLists();
  });
}

export function listMeasuresMetadata(
  limit: number,
  offset: number,
  colFilters: Record<string, string>,
  orderBy: { column: string; sort_order: "asc" | "desc" } | undefined
): Promise<HttpResult<AdminMeasuresListResponse>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    "admin/measures",
    { limit, offset, filters: colFilters, order_by: orderBy },
    "POST",
    AdminMeasuresListResponseRT
  );
}

export function listMeasuresMetadataMicro(
  limit: number,
  offset: number,
  colFilters: Record<string, string>,
  orderBy: { column: string; sort_order: "asc" | "desc" } | undefined
): Promise<HttpResult<AdminMeasuresListResponseMicro>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    "admin/mikro/measures",
    { limit, offset, filters: colFilters, order_by: orderBy },
    "POST",
    AdminMeasuresListResponseMicroRT
  );
}

export interface MetadataPatch {
  data_id: number;
  fields: {
    [key: string]: any;
  };
}
export interface MetadataPatchMicro {
  mikro_id: number;
  fields: {
    [key: string]: any;
  };
}

export function saveMetadataPatches(
  patchList: MetadataPatch[]
): Promise<HttpResult<unknown>> {
  return authedRequest<unknown>(
    config.apis.statsV1,
    `admin/measures`,
    { updates: patchList },
    "PATCH"
  );
}

export function saveMetadataPatchesMicro(
  patchList: MetadataPatchMicro[]
): Promise<HttpResult<unknown>> {
  return authedRequest<unknown>(
    config.apis.statsV1,
    `admin/mikro/measures`,
    { updates: patchList },
    "PATCH"
  );
}

export function getMeasureMetadata(measureId: number): Promise<AdminMetadata> {
  return authedRequest<AdminMetadata>(
    config.apis.statsV1,
    `admin/measures/${measureId}`,
    undefined,
    "GET"
  ).then((res) => {
    try {
      return AdminMetadataRT.check(res.unwrap());
    } catch (err) {
      logger.error("Failed to decode measure metadata", (err as any).details);
      throw err;
    }
  });
}

export function getSurveyValues(measureId: number) {
  return decodedAuthedRequest(
    config.apis.statsV2,
    `admin/survey/values/${measureId}`,
    undefined,
    "GET",
    SurveyValuesListResponseRT
  );
}

export function createSurveyValue(
  measureId: number,
  label: string,
  validFrom?: string
) {
  return authedRequest(
    config.apis.statsV2,
    `admin/survey/values/${measureId}`,
    { label, start_valid_period: validFrom },
    "POST"
  );
}

export function deleteSurveyValue(dimensionValueId: number) {
  return authedRequest(
    config.apis.statsV2,
    `admin/survey/values/${dimensionValueId}`,
    undefined,
    "DELETE"
  );
}

export function surveyValuePutDates(
  dimensionValueId: number,
  startValidPeriod: string | null,
  endValidPeriod: string | null
) {
  return authedRequest(
    config.apis.statsV2,
    `admin/survey/values/${dimensionValueId}/dates`,
    { start_valid_period: startValidPeriod, end_valid_period: endValidPeriod },
    "PUT"
  );
}

export function getMeasureOrAliasMetadata(
  measureId: number
): Promise<AdminMetadataOrAlias> {
  return authedRequest<AdminMetadataOrAlias>(
    config.apis.statsV1,
    `admin/measures/${measureId}`,
    undefined,
    "GET"
  ).then((res) => {
    try {
      return AdminMetadataOrAliasRT.check(res.unwrap());
    } catch (err) {
      logger.error("Failed to decode measure metadata", (err as any).details);
      throw err;
    }
  });
}

export function saveMeasureDefaultSettings(
  measureId: number,
  settings: LatestSettingsFormat
) {
  return authedRequest<unknown>(
    config.apis.statsV1,
    `admin/defaultsettings`,
    { data_id: measureId, settings },
    "POST"
  );
}

export function saveMeasureMetadata(
  measureId: number,
  field: keyof AdminMetadata,
  value: AdminMetadata[keyof AdminMetadata]
): Promise<HttpResult<unknown>> {
  return authedRequest<unknown>(
    config.apis.statsV1,
    `admin/measures/${measureId}`,
    { [field]: value },
    "PUT"
  );
}

// TODO: this and similar calls should all be PATCH?
/** Saves metadata for the canonical measure and all its derived measures */
export function saveMeasureMetadataMemberOrgsMeasure(
  measureId: number,
  field: keyof AdminMetadata,
  value: AdminMetadata[keyof AdminMetadata]
): Promise<HttpResult<unknown>> {
  return authedRequest<unknown>(
    config.apis.statsV1,
    `admin/member_orgs_measure/${measureId}`,
    { [field]: value },
    "PUT"
  );
}

export function saveMeasureMetadataMultiField(
  measureId: number,
  fields: {
    [key in keyof Partial<AdminMetadata>]: AdminMetadata[keyof AdminMetadata];
  }
): Promise<HttpResult<void>> {
  return authedRequest<void>(
    config.apis.statsV1,
    `admin/measures/${measureId}`,
    fields,
    "PUT"
  );
}

export function deleteTag(
  measureId: number,
  tag: string
): Promise<HttpResult<void>> {
  return authedRequest<void>(
    config.apis.statsV1,
    `admin/measures/${measureId}/tags/${encodeURIComponent(tag)}`,
    undefined,
    "DELETE"
  );
}

export function addTag(
  measureId: number,
  tag: string
): Promise<HttpResult<void>> {
  return authedRequest<void>(
    config.apis.statsV1,
    `admin/measures/${measureId}/tags/${encodeURIComponent(tag)}`,
    undefined,
    "PUT"
  );
}

export function retireMeasure(measureId: number) {
  return authedRequest(
    config.apis.statsV1,
    `admin/measures/${measureId}/retire`,
    undefined,
    "POST"
  );
}

export function unretireMeasure(measureId: number) {
  return authedRequest(
    config.apis.statsV1,
    `admin/measures/${measureId}/unretire`,
    undefined,
    "POST"
  );
}

export function getAlertStatus(
  measureId: number
): Promise<HttpResult<MeasureAlertStatus>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `admin/measures/${measureId}/alert`,
    undefined,
    "GET",
    AlertStatusRT
  ).then((res) => res.map((dto) => new MeasureAlertStatus(dto)));
}

export function deleteAlert(measureId: number): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    `admin/measures/${measureId}/alert`,
    undefined,
    "DELETE"
  );
}

export function createMeasureAlert(
  measureId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    `admin/alerts`,
    { data_id: measureId },
    "POST"
  );
}

export function createDocumentAlert(
  documentId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    `admin/alerts`,
    { node_id: documentId },
    "POST"
  );
}

let organizationsResponse: OrganizationResponse | undefined;
export function getOrganizationsWithCache(): Promise<OrganizationResponse> {
  if (defined(organizationsResponse)) {
    return Promise.resolve(organizationsResponse);
  }
  return listOrganizationsV2().then((res) => {
    const orgs = res.unwrap();
    organizationsResponse = orgs;
    return orgs;
  });
}
