import { useState, useCallback, useEffect, useMemo, useRef } from "react";
import * as d3scale from "d3-scale";
import { Checkbox, Dropdown, Slider, TextField } from "@fluentui/react";
import { range } from "lodash";

import {
  FluentModalBody,
  FluentModalFooter,
  FluentModalTall,
} from "../../../../../components/Modal";
import { DocCardStats } from "../../../../../lib/application/state/stats/document-core/core";
import { defined } from "../../../../../lib/core/defined";

import { useAppMessages } from "../../../../../lib/application/hooks/useAppMessages";
import { StatsDataset } from "../../../../../lib/application/stats/datasets/StatsDataset";
import {
  DEFAULT_NUM_BREAKPOINTS,
  findDistinctiveFormatter,
  getColorScaleInfo,
  getIntegerColorScaleAuto,
} from "../../../../../lib/application/stats/map/scales";
import {
  significantFiguresRounderLocale,
  swedishLocaleSimple,
} from "../../../../../lib/application/stats/format";
import { parseSwedishNumber } from "../../../../../lib/core/numeric/parseNum";
import { logger } from "../../../../../lib/infra/logging";
import {
  ButtonsFooter,
  ButtonsFooterLeft,
  ButtonsFooterRight,
} from "../../../../../components/ButtonContainers";
import { Button } from "../../../../../components/Button";
import { config } from "../../../../../config";
import {
  NumericalRange,
  ScaleInfo,
  displayNumericalRange as displayNumericalRangeBase,
} from "../../../../../lib/application/stats/map/types";
import { ColorDropdown } from "./ColorOptionsDialog";
import {
  extendedStandardColors,
  standardColors,
} from "../../../../../lib/application/stats/shared/core/colors/colors";

import "./StatsMapColorsAndScalesDialog.scss";
import { assertNever } from "../../../../../lib/core/assert";
import {
  DEFAULT_STATS_MAP_COLOR_SCHEME,
  StatsMapColorScheme,
  availableColorSchemes,
  getColorRamp,
} from "../../../../../lib/application/stats/map/colors";
import { OutputPreviewInner } from "../OutputPreview";
import {
  DataOutputSettings,
  MapChartSettings,
} from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";

const MAX_NUM_BREAKPOINTS = 13;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  dataset: StatsDataset;
  settings: DataOutputSettings;
  card: DocCardStats;
  setSettings: (settings: DataOutputSettings) => void;
}

export function StatsMapColorsAndScalesDialog(props: Props) {
  const card = props.card;

  const scaleInfo = useMemo(() => {
    const d = card.data.loadedData?.statsMapsState?.loadedMapsData;
    const drawableData = d?.data;
    if (!defined(drawableData)) {
      return;
    }

    return drawableData.style.scaleInfo;
  }, [card.data.loadedData?.statsMapsState?.loadedMapsData]);

  const appMessages = useAppMessages();

  if (!defined(scaleInfo)) {
    appMessages?.add("error", "Kunde inte ladda kartdata/skalinfo");
    return null;
  }

  return (
    <StatsMapColorsAndScalesDialogInner {...props} scaleInfo={scaleInfo} />
  );
}

function StatsMapColorsAndScalesDialogInner({
  isOpen,
  onClose,
  setSettings,
  dataset,
  settings,
  card,
  scaleInfo,
}: Props & { scaleInfo: ScaleInfo }) {
  const [numBreakpoints, setNumBreakpoints] = useState(() => {
    if (defined(settings.mapChart.manualBreakpoints)) {
      return settings.mapChart.manualBreakpoints.length;
    }
    return DEFAULT_NUM_BREAKPOINTS;
  });
  const displayNumericalRange = useMemo(() => {
    const mainType = dataset.primaryValueType;
    switch (mainType) {
      case "decimal":
        return (r: NumericalRange, rounder?: (num: number) => string) => {
          return displayNumericalRangeBase(r, rounder ?? twoSFRounder);
        };
      case "integer":
        return (r: NumericalRange, rounder?: (num: number) => string) => {
          return displayNumericalRangeBase(r, rounder ?? ((n) => n.toString()));
        };
    }
    return (r: NumericalRange) =>
      displayNumericalRangeBase(r, (n) => n.toString());
  }, [dataset.primaryValueType]);

  const calculateMapColors = useCallback(
    (
      dataset: StatsDataset,
      settings: DataOutputSettings,
      formatter?: (num: number) => string
    ) => {
      const mapDatasetCollection = dataset.convertForMapChart(
        config.statsMapChartsLimit
      );
      const chartData = mapDatasetCollection.sets.map((d) =>
        d.dataset.chartData()
      );
      const info = getColorScaleInfo(dataset, chartData, settings);
      if (info.type === "sequential") {
        return info.ranges.map((r) => ({
          label: displayNumericalRange(r, formatter),
          color: r.color,
        }));
      }
      return info.categories.map((c) => ({ label: c.label, color: c.color }));
    },
    [displayNumericalRange]
  );

  const [useCustomBreakpoints, setUseCustomBreakpoints] = useState(false);
  const [currentColorScale, setCurrentColorScale] = useState<
    string | undefined
  >(settings.mapChart.colorScale);

  const [startingBreakpoints] = useState<string[]>(() => {
    const manualBreakpoints = settings.mapChart.manualBreakpoints;
    const mainType = dataset.primaryValueType;
    if (defined(manualBreakpoints)) {
      switch (mainType) {
        case "integer":
          return manualBreakpoints.map((b) => b.toString());
        case "decimal":
          return manualBreakpoints.map((b) => twoSFRounder(b));
      }
      return [];
    }
    return suggestBreakpoints(dataset, numBreakpoints).breakpoints;
  });
  const [breakpoints, setBreakpoints] = useState<string[] | undefined>();

  const [startingColors, setStartingColors] = useState<
    { label: string; color: string }[]
  >(() => {
    switch (scaleInfo.type) {
      case "sequential":
        return scaleInfo.ranges.map((r) => ({
          label: displayNumericalRange(r),
          color: r.color,
        }));
      case "category":
        return scaleInfo.categories.map((c) => ({
          label: c.label,
          color: c.color,
        }));
    }
  });

  const [customMapColors, setCustomMapColors] = useState<
    { label: string; color: string }[] | undefined
  >();

  const appMessages = useAppMessages();

  const editedMapChartSettings: MapChartSettings | undefined = useMemo(() => {
    let manualColorsForCategories: { [key: string]: string } | undefined;
    let manualColorsForRanges: string[] | undefined;
    switch (dataset.primaryValueType) {
      case "category":
        const categoryColors: { [key: string]: string } = {};
        for (const c of customMapColors ?? []) {
          categoryColors[c.label] = c.color;
        }
        manualColorsForCategories = categoryColors;
        break;
      case "decimal":
      case "integer":
        manualColorsForRanges = customMapColors?.map((c) => c.color);
        break;
      case "survey":
      case "survey_string":
        appMessages?.add(
          "error",
          "Kan inte använda egna färger för surveydata"
        );
        break;
    }
    try {
      return {
        ...settings.mapChart,
        colorScale: currentColorScale,
        manualBreakpoints: defined(breakpoints)
          ? parseBreakpoints(breakpoints)
          : undefined,
        manualColorsForRanges,
        manualColorsForCategories,
      } as MapChartSettings;
    } catch (e) {
      return undefined;
    }
  }, [
    appMessages,
    breakpoints,
    currentColorScale,
    customMapColors,
    dataset.primaryValueType,
    settings.mapChart,
  ]);

  const handleSliderChange = useCallback(
    (value: number) => {
      if (!defined(editedMapChartSettings)) {
        return;
      }
      if (value === numBreakpoints) {
        return;
      }

      setNumBreakpoints(value);
      const suggested = suggestBreakpoints(dataset, value);
      try {
        const parsedBreakpoints = parseBreakpoints(suggested.breakpoints);
        const colors = calculateMapColors(
          dataset,
          {
            ...settings,
            mapChart: {
              ...editedMapChartSettings,
              manualColorsForCategories: undefined,
              manualColorsForRanges: undefined,
              manualBreakpoints: parsedBreakpoints,
            },
          },
          suggested.formatter
        );
        setCustomMapColors(colors);
        setBreakpoints(suggested.breakpoints);
      } catch (e) {
        logger.error("Failed to parse breakpoints", e);
        appMessages?.add("error", "Kunde inte tolka brytpunkterna");
      }
    },
    [
      appMessages,
      calculateMapColors,
      dataset,
      editedMapChartSettings,
      numBreakpoints,
      settings,
    ]
  );

  const handleBreakpointChange = useCallback(
    (index: number, value: string | undefined) => {
      if (!defined(breakpoints)) {
        logger.error("[handleBreakpointChange] Breakpoints not defined");
        return;
      }

      const newBreakpoints = [...breakpoints];
      if (index >= newBreakpoints.length) {
        logger.error("Index out of bounds");
        return;
      }

      newBreakpoints[index] = value ?? "";
      setBreakpoints(newBreakpoints);

      if (!defined(editedMapChartSettings)) {
        return;
      }

      try {
        const parsedBreakpoints = parseBreakpoints(newBreakpoints);
        if (defined(validateBreakpoints(parsedBreakpoints))) {
          return;
        }

        const mapColors = customMapColors ?? startingColors;
        const newColors = [...mapColors];
        newColors[index] = {
          ...newColors[index],
          label: displayNumericalRange({
            min: parsedBreakpoints[index - 1],
            max: parsedBreakpoints[index],
          }),
        };
        if (defined(newColors[index + 1])) {
          newColors[index + 1] = {
            ...newColors[index + 1],
            label: displayNumericalRange({
              min: parsedBreakpoints[index],
              max: parsedBreakpoints[index + 1],
            }),
          };
        }
        setCustomMapColors(newColors);
      } catch (e) {
        // Ignore parsing errors here
      }
    },
    [
      breakpoints,
      customMapColors,
      displayNumericalRange,
      editedMapChartSettings,
      startingColors,
    ]
  );

  const handleSetUseCustomBreakpoints = useCallback(
    (useCustom: boolean) => {
      if (useCustom && !defined(breakpoints)) {
        setBreakpoints(startingBreakpoints);
      }
      setUseCustomBreakpoints(useCustom);
    },
    [breakpoints, startingBreakpoints]
  );

  const handleApplyColor = useCallback(
    (color: string, index: number) => {
      const colors = defined(customMapColors)
        ? customMapColors
        : calculateMapColors(dataset, settings);
      const newColors = [...colors];
      newColors[index] = { ...newColors[index], color };
      setCustomMapColors(newColors);
    },
    [calculateMapColors, customMapColors, dataset, settings]
  );

  const handleApplyNoColor = useCallback(
    (index: number) => {
      const colors = defined(customMapColors)
        ? customMapColors
        : calculateMapColors(dataset, settings);
      const newColors = [...colors];
      newColors[index] = { ...newColors[index], color: "" };
      setCustomMapColors(newColors);
    },
    [calculateMapColors, customMapColors, dataset, settings]
  );

  const handleSetColorScale = useCallback(
    (colorScale: string) => {
      try {
        if (defined(breakpoints)) {
          const parsedBreakpoints = parseBreakpoints(breakpoints);
          const colors = calculateMapColors(dataset, {
            ...settings,
            mapChart: {
              ...settings.mapChart,
              colorScale,
              manualColorsForCategories: undefined,
              manualColorsForRanges: undefined,
              manualBreakpoints: parsedBreakpoints,
            },
          });
          setCustomMapColors(colors);
          setCurrentColorScale(colorScale);
        } else {
          const suggested = suggestBreakpoints(dataset, numBreakpoints);
          const parsedBreakpoints = parseBreakpoints(suggested.breakpoints);
          const colors = calculateMapColors(
            dataset,
            {
              ...settings,
              mapChart: {
                ...settings.mapChart,
                colorScale,
                manualColorsForCategories: undefined,
                manualColorsForRanges: undefined,
                manualBreakpoints: parsedBreakpoints,
              },
            },
            suggested.formatter
          );
          setCustomMapColors(colors);
          setCurrentColorScale(colorScale);
        }
      } catch (e) {
        logger.error("Failed to parse breakpoints", e);
        appMessages?.add("error", "Kunde inte tolka brytpunkterna");
      }
    },
    [
      appMessages,
      breakpoints,
      calculateMapColors,
      dataset,
      numBreakpoints,
      settings,
    ]
  );

  const hasChanges = useMemo(() => {
    return (
      defined(breakpoints) ||
      defined(customMapColors) ||
      currentColorScale !== settings.mapChart.colorScale
    );
  }, [breakpoints, currentColorScale, customMapColors, settings.mapChart]);

  const handleReset = useCallback(() => {
    setNumBreakpoints(DEFAULT_NUM_BREAKPOINTS);
    setUseCustomBreakpoints(false);
    setBreakpoints(undefined);
    setCustomMapColors(undefined);

    const defaultSettings: DataOutputSettings = {
      ...settings,
      mapChart: {
        ...settings.mapChart,
        colorScale: undefined,
        scaleType: undefined,
        manualBreakpoints: undefined,
        manualColorsForCategories: undefined,
        manualColorsForRanges: undefined,
      },
    };
    setSettings(defaultSettings);
    setStartingColors(calculateMapColors(dataset, defaultSettings));
  }, [calculateMapColors, dataset, setSettings, settings]);

  const handleSave = useCallback(() => {
    if (defined(breakpoints)) {
      try {
        const updatedBreakpoints = parseBreakpoints(breakpoints);
        const error = validateBreakpoints(updatedBreakpoints);
        if (defined(error)) {
          appMessages?.add("error", error);
          return;
        }
      } catch (e) {
        appMessages?.add("error", "Kunde inte tolka brytpunkterna");
        logger.error("Failed to parse breakpoints", e);
        return;
      }
    }

    if (!defined(editedMapChartSettings)) {
      appMessages?.add("error", "Kunde inte tolka inställningarna");
      return;
    }
    setSettings({
      ...settings,
      mapChart: {
        ...editedMapChartSettings,
      },
    });
    onClose();
  }, [
    appMessages,
    breakpoints,
    editedMapChartSettings,
    onClose,
    setSettings,
    settings,
  ]);

  const canUseBreakpoints = useMemo(() => {
    const primaryValueType = dataset.primaryValueType;
    switch (primaryValueType) {
      case "decimal":
      case "integer":
        return true;
      case "category":
      case "survey":
      case "survey_string":
        return false;
      default:
        assertNever(primaryValueType);
    }
  }, [dataset.primaryValueType]);

  const cardWithUnsavedChanges: DocCardStats = useMemo(() => {
    if (!defined(editedMapChartSettings)) {
      return card;
    }
    return {
      ...card,
      data: {
        ...card.data,
        settings: {
          ...card.data.settings,
          mapChart: editedMapChartSettings,
        },
      },
    };
  }, [card, editedMapChartSettings]);

  return (
    <FluentModalTall
      title="Färger ..."
      isOpen={isOpen}
      onClose={onClose}
      width="md"
      containerClassName="stats-map-colors-and-scales-dialog"
    >
      <FluentModalBody>
        <div className="margin-bottom-md">
          {canUseBreakpoints && (
            <>
              <div className="flex flex-row">
                <Slider
                  className="flex-grow"
                  label="Antal brytpunkter"
                  min={1}
                  max={MAX_NUM_BREAKPOINTS}
                  step={1}
                  value={numBreakpoints}
                  showValue
                  onChange={handleSliderChange}
                />
                <Dropdown
                  className="flex-grow"
                  label="Färgskala"
                  options={availableColorSchemes.map((scale) => {
                    return { key: scale, text: scale };
                  })}
                  onRenderTitle={(options) => {
                    const option = options?.[0];
                    if (!defined(option)) {
                      return null;
                    }

                    return (
                      <div className="stats-map-color-scale-option">
                        <ColorSchemeIndicator
                          scheme={option.key as StatsMapColorScheme}
                        />
                      </div>
                    );
                  }}
                  onRenderOption={(option) => {
                    if (!defined(option)) {
                      return null;
                    }
                    return (
                      <div className="stats-map-color-scale-option">
                        <ColorSchemeIndicator
                          scheme={option.key as StatsMapColorScheme}
                        />
                      </div>
                    );
                  }}
                  selectedKey={
                    currentColorScale ?? DEFAULT_STATS_MAP_COLOR_SCHEME
                  }
                  onChange={(_, option) => {
                    if (!option) {
                      return;
                    }
                    handleSetColorScale(option.key as string);
                  }}
                />
              </div>
              <p>
                Varje intervall är inklusive den övre brytpunkten och exklusive
                den nedre brytpunkten.
              </p>
            </>
          )}

          <div className="custom-colors margin-top-md">
            {(customMapColors ?? startingColors).map((c, index) => (
              <div className="color-dropdown-container">
                <ColorDropdown
                  key={c.label}
                  applyColor={(label, color) => handleApplyColor(color, index)}
                  classNames="color-dropdown"
                  applyNoColor={() => handleApplyNoColor(index)}
                  mode={"both"}
                  swatchColors={standardColors.concat(extendedStandardColors)}
                  colorCode={c.color}
                  label={c.label}
                />
                <label>{c.label}</label>
              </div>
            ))}
          </div>
        </div>

        <>
          {canUseBreakpoints && (
            <div className="margin-bottom-md">
              <Checkbox
                label="Ändra brytpunkter"
                checked={useCustomBreakpoints}
                onChange={(_, checked) =>
                  handleSetUseCustomBreakpoints(!!checked)
                }
                className="margin-bottom-lg"
              />
              <>
                {useCustomBreakpoints && defined(breakpoints) && (
                  <>
                    <div className="breakpoints-grid">
                      {range(0, numBreakpoints).map((_, index) => (
                        <TextField
                          key={numBreakpoints + "_" + index}
                          label={"Brytpunkt " + (index + 1)}
                          value={breakpoints[index] ?? ""}
                          onChange={(e, newValue) =>
                            handleBreakpointChange(index, newValue)
                          }
                        />
                      ))}
                    </div>
                  </>
                )}
              </>
            </div>
          )}
          {hasChanges && (
            <div className="preview">
              <h2>Förhandsvisning</h2>
              <OutputPreviewInner card={cardWithUnsavedChanges} applyChanges />
            </div>
          )}
        </>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterLeft>
            <Button onClick={onClose} title="Avbryt" />
          </ButtonsFooterLeft>
          <ButtonsFooterRight>
            <>
              <Button onClick={handleReset} title="Återställ" />
              <Button
                disabled={!hasChanges}
                intent="primary"
                onClick={handleSave}
                title="Spara"
              />
            </>
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModalTall>
  );
}

const twoSFRounder = significantFiguresRounderLocale(2, swedishLocaleSimple);

interface BreakpointsResult {
  breakpoints: string[];
  formatter: (n: number) => string;
}

function suggestBreakpoints(
  dataset: StatsDataset,
  numBreakpoints: number
): BreakpointsResult {
  const res = suggestedBreakpointsInner(dataset, numBreakpoints);
  if (res.breakpoints.length < numBreakpoints) {
    return {
      breakpoints: range(0, numBreakpoints).map(() => ""),
      formatter: res.formatter,
    };
  }
  return res;
}

function suggestedBreakpointsInner(
  dataset: StatsDataset,
  numBreakpoints: number
): BreakpointsResult {
  const mainType = dataset.primaryValueType;
  const extent = dataset.chartData().rangeExtent(false);
  if (mainType === "integer") {
    const { ranges } = getIntegerColorScaleAuto(
      extent[0],
      extent[1],
      (numColors) => range(0, numColors).map((i) => i.toString()),
      numBreakpoints + 1
    );
    return {
      breakpoints: ranges
        .map((r) => r.max)
        .filter(defined)
        .map((b) => b.toString()),
      formatter: (n: number) => n.toString(),
    };
  }

  const s = d3scale
    .scaleQuantize<string, string>()
    .domain(extent)
    .range(range(numBreakpoints + 1).map((_, i) => i + ""));

  const formatter = findDistinctiveFormatter(s.thresholds());

  return {
    breakpoints: s.thresholds().map(formatter),
    formatter: formatter,
  };
}

function validateBreakpointPair(
  breakpoints: number[],
  firstIndex: number,
  secondIndex: number
): string | undefined {
  const first = breakpoints[firstIndex];
  const second = breakpoints[secondIndex];

  if (first >= second) {
    return `Brytpunkt ${
      firstIndex + 1
    } (${first}) måste vara mindre än brytpunkt ${secondIndex + 1} (${second})`;
  }
}

/** throws */
function parseBreakpoints(points: string[]): number[] {
  let parsedBreakpoints: number[] = [];
  for (const b of points) {
    const res = parseSwedishNumber(b);
    res.match({
      ok: (num) => {
        parsedBreakpoints.push(num);
      },
      err: (err) => {
        throw new Error(err + ": Parsing num failed: " + b);
      },
    });
  }
  return parsedBreakpoints;
}

function ColorSchemeIndicator(props: { scheme: StatsMapColorScheme }) {
  const canvasContainerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const current = canvasContainerRef?.current;
    if (!defined(current)) {
      return;
    }

    const rampSteps = 100;
    const colorRamp = getColorRamp(props.scheme, rampSteps);
    const canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 100;
    canvas.style.width = "100%";
    canvas.style.height = "18px";
    const ctx = canvas.getContext("2d");
    if (!defined(ctx)) {
      throw new Error("Could not get context");
    }
    for (let i = 0; i < rampSteps; i++) {
      ctx.fillStyle = colorRamp[i];
      ctx.fillRect(i, 0, 1, 100);
    }

    current.appendChild(canvas);

    return () => {
      current.removeChild(canvas);
    };
  }, [props.scheme]);

  return (
    <div ref={canvasContainerRef} className="color-scheme-container"></div>
  );
}

function validateBreakpoints(breakpointsParsed: number[]) {
  for (let i = 1; i < breakpointsParsed.length; i++) {
    const err = validateBreakpointPair(breakpointsParsed, i - 1, i);
    if (defined(err)) {
      return err;
    }
  }
  return undefined;
}
