import { useCallback } from "react";
import { useSetRecoilState } from "recoil";

import { DocCardPython } from "../../../stats/document-core/core";
import { cardQuery } from "../../../stats/document-core/queries/card";
import { useSaveCard } from "../../useSaveDocument";

/**
 * Fetch data for a given card and store in card state.
 */
export function useHandleCardChangePython(cardId: string) {
  const setCard = useSetRecoilState(cardQuery(cardId));

  const handleSaveCard = useSaveCard();

  const handleUpdate = useCallback(
    async (card: DocCardPython) => {
      setCard(card);
      handleSaveCard?.(card);
      return card;
    },
    [handleSaveCard, setCard]
  );

  return handleUpdate;
}
