import { chain, groupBy, sortBy } from "lodash";
import { defined } from "../../../core/defined";
import {
  DimensionV2Dto,
  DimensionValueV2Dto,
} from "../../../domain/measure/definitions";
import {
  MicroLineDatasetDto,
  MicroPointDatasetDto,
  MicroPolygonDatasetDto,
} from "../../../infra/api_responses/micro_dataset";
import { Dimension } from "../shared/core/definitions";
import {
  DimColumnAndValues,
  getAllValidCombinations,
} from "./table/table_base/table_dimensions";
import {
  getNextBorderColorGeoMicroV2,
  getNextFillColorGeoMicroV2,
} from "../../state/stats/document-style/operations";
import { assertNever } from "@fluentui/react";
import { GroupStyleGeoMicro } from "../../state/stats/document-style/definitions";
import { MicroSubjectPath } from "../../state/stats/document-core/core-micro";

export interface DimensionAndStringValue {
  dimension: string;
  value: string;
}

export type DrawableGroupItemV2 = {
  geometry: GeoJSON.Geometry;
  label?: string;
  infoLines?: string[];
  value?: string;
  unit?: string;
};

export interface DrawableGroupV2 {
  id: string;
  datasetChecksum: string;
  /** Area, subarea, subject, measure */
  measurePath: string[];
  breakdownCombination: DimensionAndStringValue[];
  label: string;
  items: DrawableGroupItemV2[];
  type: "point" | "line" | "polygon";
  style: {
    label: string;
    fill?: string;
    fillOpacity?: number;
    lineDashArray?: number[];
    border?: string;
  };
}

export type DrawableGroupSpec = Omit<DrawableGroupV2, "items">;
export type DrawableGroupSet = DrawableGroupV2[];

const NON_DATA_DIMENSIONS = [
  "geometry",
  "midpoint",
  Dimension.date,
  "label",
  Dimension.value,
];
export function microDimensions(
  dto: MicroPolygonDatasetDto | MicroPointDatasetDto | MicroLineDatasetDto,
  dimSpecs: DimensionV2Dto[]
) {
  const dataDimensions = dto.header.dimensions
    .concat(Object.keys(dto.header.lifted))
    .filter((d) => !NON_DATA_DIMENSIONS.includes(d))
    .sort();
  const dimensionsAndValues: DimColumnAndValues[] = chain(dataDimensions)
    .map((dim) => {
      const dimSpec = dimSpecs.find((d) => d.data_column === dim);
      const labels = Object.keys(
        groupBy(
          dto.rows.filter((r) => defined(r[dim] || dto.header.lifted[dim])),
          (r) => r[dim] || dto.header.lifted[dim]
        )
      );

      return !defined(dimSpec)
        ? labels.map((l) => [dim, l] as [string, string])
        : labels.map((label) => {
            const found = dimSpec.values?.find((v) => v.label === label);
            if (!defined(found)) {
              throw new Error(
                `Could not find value for dimension ${dim} with label ${label}`
              );
            }
            return [dim, found] as [string, DimensionValueV2Dto];
          });
    })
    .value();

  const allCombinations: { dimension: string; value: string }[][] =
    dimensionsAndValues.length > 0
      ? getAllValidCombinations(dimensionsAndValues).map((combo) =>
          combo.map(([dimColumn, value]) => ({
            dimension: dimColumn,
            value: typeof value === "string" ? value : value.label,
          }))
        )
      : [
          Object.entries(dto.header.lifted ?? {})
            .map(([dimension, value]) => ({
              value,
              dimension,
            }))
            .filter((d) => !NON_DATA_DIMENSIONS.includes(d.dimension)),
        ];
  return {
    variableDimensions: dataDimensions,
    allCombinations: sortBy(allCombinations, (c) =>
      c
        .map((part) => part.dimension + ":" + part.value)
        .sort()
        .join(";")
    ),
  };
}

export type DrawableGroupWithoutStyles = Omit<DrawableGroupV2, "style">;
export function makeDrawableGroupsV2(
  dto: MicroPointDatasetDto | MicroPolygonDatasetDto | MicroLineDatasetDto,
  subjectPath: MicroSubjectPath,
  datasetId: string,
  datasetChecksum: string,
  dimensionsSpec: DimensionV2Dto[],
  assertGeometry: (geometry: any) => void
): Omit<DrawableGroupV2, "style">[] {
  const { variableDimensions, allCombinations: breakdownCombinations } =
    microDimensions(dto, dimensionsSpec);

  const groups =
    variableDimensions.length > 0
      ? groupBy(dto.rows, (r) =>
          joinToKey(
            variableDimensions.map(
              (d) => (r[d] || dto.header.lifted[d]) as string
            )
          )
        )
      : undefined;

  const displayValue = (value: unknown): string => {
    if (typeof value === "number") {
      return value.toString();
    } else if (typeof value === "string") {
      return value;
    } else {
      return JSON.stringify(value);
    }
  };

  const drawableGroupsWithoutStyles: Omit<DrawableGroupV2, "style">[] = [];

  for (const breakdownCombination of breakdownCombinations) {
    const key = joinToKey(
      sortBy(breakdownCombination, (c) => c.dimension).map((d) => d.value)
    );
    const rows = defined(groups) ? groups[key] ?? [] : dto.rows;
    const id =
      datasetId +
      "-" +
      breakdownCombination.map((bc) => `${bc.dimension}:${bc.value}`).join("-");

    drawableGroupsWithoutStyles.push({
      id: id,
      measurePath: [
        ...subjectPath.map((part) => part ?? ""),
        dto.header.measure,
      ],
      breakdownCombination: breakdownCombination,
      datasetChecksum,
      type: dto.header.value_type,
      label:
        dto.header.descr_long +
        (breakdownCombination.length === 0
          ? ""
          : `: ${breakdownCombination.map((bc) => bc.value).join(", ")}`),
      items: rows.map((row) => {
        const geometry = row.geometry as any;
        assertGeometry(geometry);
        return {
          geometry,
          label: row.label,
          infoLines: breakdownCombination.map(
            (bc) =>
              `${
                dimensionsSpec.find((d) => d.data_column === bc.dimension)
                  ?.label
              }: ${bc.value}`
          ),
          value: displayValue(row.value),
          unit: dto.header.unit_label,
        } as DrawableGroupItemV2;
      }),
    });
  }

  return drawableGroupsWithoutStyles;
}

export function getDrawableStyle(
  currentStyles: GroupStyleGeoMicro[],
  group: Omit<DrawableGroupV2, "style">
): GroupStyleGeoMicro {
  const border = getNextBorderColorGeoMicroV2(currentStyles);
  switch (group.type) {
    case "polygon":
      return {
        label: group.label,
        border,
      };
    case "point": {
      const fill = getNextFillColorGeoMicroV2(currentStyles);
      return {
        label: group.label,
        fill: fill,
      };
    }
    case "line": {
      const fill = getNextFillColorGeoMicroV2(currentStyles);
      return {
        label: group.label,
        fill,
      };
    }
    default:
      assertNever(group.type);
  }
}

function joinToKey(s: string[]): string {
  return s.sort().join("-");
}
