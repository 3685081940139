import { TextField } from "@fluentui/react";
import { sortBy } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AlertBox } from "../../../../../components/AlertBox";
import { Button } from "../../../../../components/Button";

import { InfoBox } from "../../../../../components/InfoBox";
import { DefaultLoading } from "../../../../../components/Loading";
import { FluentModal, FluentModalBody } from "../../../../../components/Modal";
import {
  AppMessagesContext,
  ShowDraftDataContext,
} from "../../../../../lib/application/contexts";
import {
  createSurveyValue,
  deleteSurveyValue,
  getMeasureMetadata,
  getOrganizationsWithCache,
  getSurveyValues,
  retireMeasure,
  saveMeasureMetadata,
  saveMeasureMetadataMultiField,
  surveyValuePutDates,
  unretireMeasure,
} from "../../../../../lib/application/requests/admin/common_requests_admin";
import {
  editStatsDimensionV2,
  editSurveyDimensionV2,
} from "../../../../../lib/application/requests/admin/measure_management/edit";
import { defined } from "../../../../../lib/core/defined";
import { Progress } from "../../../../../lib/core/progress";
import { voidFunc } from "../../../../../lib/core/voidFunc";
import {
  AdminMetadata,
  AdminMetadataOrAlias,
  MeasureAlias,
} from "../../../../../lib/domain-admin/metadata";
import { DimensionV2Dto } from "../../../../../lib/domain/measure/definitions";
import { validResolutions } from "../../../../../lib/domain/time";
import { OrganizationResponse } from "../../../../../lib/infra/api_responses/admin/organizations";
import { aggregationMethods } from "../../../../../lib/infra/api_responses/dataset";
import {
  displayHttpErrorInternal,
  HttpResult,
} from "../../../../../lib/infra/HttpResult";
import { logger } from "../../../../../lib/infra/logging";
import { DimensionsEditorV2 } from "../../../../admin/views_data_admin/shared/DimensionsEditorV2";
import {
  SimpleSubjectSelect,
  SubjectPathSelection,
} from "../../../../admin/views_data_admin/shared/SimpleSubjectSelect";

import "./MetadataAdmin.scss";
import {
  OrgAccessEditor,
  ORG_ACCESS_FIELD_LABEL,
  DataFieldDef,
  DataFields,
} from "./shared";
import { TagsEditor } from "./TagsEditor";
import { createAlias } from "../../../../../lib/application/requests/admin/measure_management/aliases";
import { nonEmptyString } from "../../../../../lib/core/nonEmptyString";
import {
  ButtonsFooter,
  ButtonsFooterRight,
} from "../../../../../components/ButtonContainers";
import { deleteAlias } from "../../../../../lib/application/requests/admin/measure_management/aliases";
import {
  useLoadStatsDimensions,
  useSaveDimensionsOrder,
} from "../../../../../lib/application_admin/hooks/dimensions";
import { InfostatDatePickerControlled } from "../../../../../components/input/DatePicker";
import { SurveyValueDto } from "../../../../../lib/infra/api_responses/measures/survey";
import { FoldoutPanel } from "../../../../../components/FoldoutPanel";
import { classNames } from "../../../../../lib/core/classNames";
import { formatDateSE } from "../../../../../lib/core/time";

interface Props {
  measureId?: number;
  primarySubjectPath: string[] | undefined;
}
export function MetadataAdmin(props: Props) {
  const { measureId, primarySubjectPath } = props;
  const [metadata, setMetadata] = useState<AdminMetadataOrAlias>();
  const [progress, setProgress] = useState(Progress.NotStarted);
  const [isEditingSubjectPath, setIsEditingSubjectPath] = useState(false);
  const [isEditingOrgAccess, setIsEditingOrgAccess] = useState(false);
  const [isEditingDimensions, setIsEditingDimensions] = useState(false);

  const showDraftData = useContext(ShowDraftDataContext);
  const [dimensions, setDimensions] = useState<DimensionV2Dto[]>();
  const [organizations, setOrganizations] = useState<OrganizationResponse>();

  const [answerValues, setAnswerValues] = useState<SurveyValueDto[]>();

  const appMessagesHandler = useContext(AppMessagesContext);

  useEffect(() => {
    getOrganizationsWithCache().then((res) =>
      setOrganizations(sortBy(res, "name"))
    );
  }, []);

  const loadDimensions = useLoadStatsDimensions(
    setDimensions,
    measureId,
    showDraftData
  );

  useEffect(() => {
    loadDimensions();
  }, [appMessagesHandler, loadDimensions, measureId, primarySubjectPath]);

  const loadData = useCallback(
    (measureId: number) => {
      getMeasureMetadata(measureId).then((res) => {
        // We can only handle singlechoice questions
        if (res.survey_question_type !== "singlechoice") {
          return;
        }

        return getSurveyValues(measureId).then((res) => {
          res.match({
            ok: (values) => {
              if (defined(values)) {
                setAnswerValues(values);
              } else {
                appMessagesHandler?.add(
                  "error",
                  "Kunde inte hämta svarsalternativ"
                );
              }
            },
            err: (err) => {
              appMessagesHandler?.add("error", displayHttpErrorInternal(err));
            },
          });
        });
      });
    },
    [appMessagesHandler]
  );

  useEffect(() => {
    if (metadata?.value_type === "survey") {
      if (!defined(measureId)) {
        return;
      }
      loadData(measureId);
    }
  }, [metadata, measureId, appMessagesHandler, loadData]);

  const loadMetadata = useCallback(() => {
    if (!defined(measureId)) {
      return;
    }
    setProgress(Progress.InProgress);
    getMeasureMetadata(measureId)
      .then((data) => {
        setMetadata(data);
        setProgress(Progress.Success);
      })
      .catch(() => {
        setProgress(Progress.Error);
      });
  }, [measureId]);

  useEffect(() => {
    loadMetadata();
  }, [loadMetadata, measureId]);

  const handleSetTags = useCallback(
    (measureId: number, tags: string[]) => {
      return saveMeasureMetadata(measureId, "tags", tags).then((res) => {
        res.match({
          ok: () => {
            appMessagesHandler?.add("success", "Tags saved");
            loadMetadata();
          },
          err: (err) => {
            appMessagesHandler?.add("error", displayHttpErrorInternal(err));
          },
        });
        return res;
      });
    },
    [appMessagesHandler, loadMetadata]
  );

  const handleSaveOrgAccess = useCallback(
    (orgIds: string[]) => {
      if (orgIds.length === 0) {
        alert("Måttet kommer göras publikt. Fortsätt?");
      }
      if (!defined(measureId)) {
        appMessagesHandler?.add("error", "Inget  Mått-ID hittat");
        return Promise.resolve(HttpResult.fromOk(null));
      }
      return saveMeasureMetadata(
        measureId,
        "organization_access_restrictions",
        orgIds.length === 0 ? null : orgIds // null means public
      ).then((res) => {
        res.match({
          ok: () => {
            setIsEditingOrgAccess(false);
            loadMetadata();
          },
          err: (err) => {
            logger.error(err);
            appMessagesHandler?.add("error", "Kunde inte spara");
          },
        });
        return res;
      });
    },
    [appMessagesHandler, loadMetadata, measureId]
  );

  const handleRetireMeasure = useCallback(
    (mId: number) => {
      retireMeasure(mId)
        .then(() => {
          appMessagesHandler?.add("success", "Mått pensionerat");
          loadMetadata();
        })
        .catch((e) => {
          logger.error(e);
          appMessagesHandler?.add("error", e);
        });
    },
    [appMessagesHandler, loadMetadata]
  );

  const handleUnretireMeasure = useCallback(
    (mId: number) => {
      unretireMeasure(mId)
        .then(() => {
          appMessagesHandler?.add("success", "Mått återaktiverat");
          loadMetadata();
        })
        .catch((e) => {
          logger.error(e);
          appMessagesHandler?.add("error", e);
        });
    },
    [appMessagesHandler, loadMetadata]
  );

  const handleSaveDimensionsOrder = useSaveDimensionsOrder(loadDimensions);

  const handleEditDimension = useCallback(
    (dimensionId: number, dimension: DimensionV2Dto) => {
      switch (dimension.type) {
        case "stats_breakdown":
          return editStatsDimensionV2(dimensionId, dimension).then((res) => {
            res.match({
              ok: () => {
                setDimensions((prevDims) =>
                  prevDims?.map((d) =>
                    d.dimension_id === dimensionId ? dimension : d
                  )
                );
              },
              err: voidFunc,
            });
            return res;
          });
        case "survey_background":
        case "survey_subquestion":
        case "survey_value":
          return editSurveyDimensionV2(dimensionId, dimension).then((res) => {
            res.match({
              ok: () => {
                setDimensions((prevDims) =>
                  prevDims?.map((d) =>
                    d.dimension_id === dimensionId ? dimension : d
                  )
                );
              },
              err: voidFunc,
            });
            return res;
          });
      }

      throw new Error("Invalid dimension type");
    },
    []
  );

  const orgIdToName = useCallback(
    (id: string) => {
      return organizations?.find((org) => org.id === id)?.name;
    },
    [organizations]
  );

  const memberOrgMeasurementOwner = useMemo(() => {
    if (!defined(metadata)) {
      return undefined;
    }
    if (
      metadata?.value_type === "category" ||
      metadata?.value_type === "decimal" ||
      metadata?.value_type === "integer"
    ) {
      return metadata.member_org_measurement_owner_org;
    }
  }, [metadata]);

  const fields: DataFieldDef<AdminMetadata>[] = useMemo(() => {
    const baseFields: DataFieldDef<AdminMetadata>[] = [
      { key: "label", editable: false, type: "input" },
      { key: "data_id", editable: false, type: "int-input" },
      { key: "value_type", editable: false, type: "input" },
      { key: "measure", editable: true, type: "input" },
    ];

    if (metadata?.value_type !== "alias") {
      baseFields.push.apply(baseFields, [
        { key: "last_update", editable: true, type: "date", deletable: true },
        { key: "next_update", editable: true, type: "date", deletable: true },
        {
          key: "agg_method_geo",
          editable: false,
          type: "select-single",
          options: aggregationMethods.map((m) => ({ key: m, text: m })),
        },
        {
          key: "resolution",
          editable: true,
          type: "select-single",
          options: validResolutions.map((r) => ({ key: r, text: r })),
        },
        { key: "descr_long", editable: true, type: "input" },
        { key: "unit_label", editable: true, type: "input" },
        { key: "ext_label", editable: true, type: "input" },
        { key: "ext_descr", editable: true, type: "textarea" },
        { key: "ext_descr_long", editable: true, type: "textarea" },
        { key: "public_comment", editable: true, type: "textarea" },
        { key: "source", editable: true, type: "input" },
        { key: "source_url", editable: true, deletable: true, type: "input" },
        { key: "ext_source", editable: true, type: "textarea" },
      ]);
    }

    if (metadata?.value_type === "survey") {
      baseFields.push.apply(baseFields, [
        {
          key: "survey_question",
          editable: true,
          type: "input",
        },
        {
          key: "survey_target_group_info_public",
          editable: true,
          type: "textarea",
        },
        {
          key: "survey_filter_info",
          editable: true,
          type: "textarea",
        },
        {
          key: "survey_internal_comment",
          editable: true,
          type: "textarea",
        },
        {
          key: "survey_method",
          editable: true,
          type: "textarea",
        },
        {
          key: "survey_method_info_public",
          editable: true,
          type: "textarea",
        },
        {
          key: "survey_low_base_limit",
          editable: true,
          type: "int-input",
        },
        {
          key: "survey_dubious_base_limit",
          editable: true,
          type: "int-input",
        },
        { key: "survey_weight_info", editable: true, type: "textarea" },
      ]);
    }

    return baseFields;
  }, [metadata?.value_type]);

  if (!defined(measureId) || !defined(primarySubjectPath)) {
    return <InfoBox mainHeader="Inget mått valt" sections={[]}></InfoBox>;
  }

  if (progress === Progress.NotStarted || progress === Progress.InProgress) {
    return (
      <div className="admin-measure-metadata">
        <DefaultLoading></DefaultLoading>
      </div>
    );
  }

  if (!defined(metadata) || progress === Progress.Error) {
    return (
      <div className="admin-measure-metadata">
        <h2>Oklart fel!</h2>
      </div>
    );
  }

  return (
    <div className="admin-measure-metadata content-padding-default">
      <h2>Metadata ({metadata.label})</h2>
      {metadata.value_type !== "alias" && metadata.retired && (
        <AlertBox intent="warning">
          <p>
            <strong>{metadata.label}</strong> är pensionerat.
          </p>
        </AlertBox>
      )}
      {defined(memberOrgMeasurementOwner) && (
        <AlertBox intent="warning">
          <p>
            Detta är ett mått för medlemsorganisationer och ägs av{" "}
            <strong>{orgIdToName(memberOrgMeasurementOwner)}</strong> (
            {memberOrgMeasurementOwner}) .
          </p>
        </AlertBox>
      )}

      {metadata.value_type !== "alias" && defined(metadata.aliases) && (
        <AlertBox intent="warning">
          <h3>Alias</h3>
          <section>
            {!defined(metadata.aliases) || metadata.aliases.length === 0 ? (
              <p>Inga alias hittade</p>
            ) : (
              <div>
                Det finns alias som pekar på det här måttet:
                {metadata.aliases.map((alias) => (
                  <AliasEditor
                    handleRemove={(aliasId) => {
                      if (
                        !window.confirm(
                          "Är du säker på att du vill ta bort aliaset?"
                        )
                      ) {
                        return Promise.resolve(undefined);
                      }
                      return deleteAlias(metadata.data_id, aliasId).then(
                        (res) => {
                          res.match({
                            ok: () => {
                              loadMetadata();
                            },
                            err: voidFunc,
                          });
                          return res;
                        }
                      );
                    }}
                    handleSave={(m) =>
                      saveMeasureMetadataMultiField(m.alias_data_id, {
                        area: m.area,
                        subarea: m.subarea,
                        subject: m.subject,
                        measure: m.measure,
                      }).then((res) => {
                        res.match({
                          ok: () => {
                            loadMetadata();
                          },
                          err: voidFunc,
                        });
                        return res;
                      })
                    }
                    alias={alias}
                    key={alias.alias_data_id}
                  />
                ))}
              </div>
            )}
          </section>
          <NewAliasCreator
            handleCreateAlias={(measureId, a) =>
              createAlias(measureId, a).then((res) => {
                res.match({
                  ok: () => {
                    loadMetadata();
                  },
                  err: voidFunc,
                });
                return res;
              })
            }
            measureId={metadata.data_id}
          />
        </AlertBox>
      )}

      <>
        {isEditingSubjectPath ? (
          <SimpleSubjectSelect
            defaultPath={[metadata.area, metadata.subarea, metadata.subject]}
            onCancel={() => setIsEditingSubjectPath(false)}
            submitText="Spara"
            onSubmit={(path) => {
              setIsEditingSubjectPath(false);
              const [area, subarea, subject] = path;
              if (!defined(area) || !defined(subarea) || !defined(subject)) {
                throw new Error(
                  "Area, subarea or subject is undefined! Path: " + path
                );
              }
              return saveMeasureMetadataMultiField(measureId, {
                area,
                subarea,
                subject,
              }).then((res) => {
                return res.unwrap();
              });
            }}
          ></SimpleSubjectSelect>
        ) : (
          <div className="editable-field subject-path">
            <div>
              <div className="label">
                <label>{`area > subarea > subject`}</label>
              </div>
              <TextField
                disabled
                readOnly
                value={` ${metadata.area} > ${metadata.subarea} > ${metadata.subject} `}
              ></TextField>
            </div>
            <Button
              intent="primary"
              title="Redigera"
              onClick={() => setIsEditingSubjectPath(true)}
            ></Button>
          </div>
        )}

        {metadata.value_type !== "alias" &&
          !defined(memberOrgMeasurementOwner) && (
            <>
              {!defined(organizations) ? (
                <DefaultLoading></DefaultLoading>
              ) : isEditingOrgAccess ? (
                <OrgAccessEditor
                  organizations={organizations}
                  selected={metadata.organization_access_restrictions ?? []}
                  handleCancel={() => setIsEditingOrgAccess(false)}
                  handleSave={handleSaveOrgAccess}
                ></OrgAccessEditor>
              ) : (
                <div className="editable-field org-access">
                  <div>
                    <div className="label">
                      <label>{ORG_ACCESS_FIELD_LABEL}</label>
                    </div>
                    <TextField
                      disabled
                      readOnly
                      value={
                        (metadata.organization_access_restrictions ?? [])
                          .length === 0
                          ? "[PUBLIKT MÅTT!]"
                          : metadata.organization_access_restrictions
                              ?.map(
                                (id) =>
                                  organizations?.find((org) => org.id === id)
                                    ?.name ?? "--"
                              )
                              .join("; ")
                      }
                    ></TextField>
                  </div>
                  <Button
                    intent="primary"
                    title="Redigera"
                    onClick={() => setIsEditingOrgAccess(true)}
                  ></Button>
                </div>
              )}
            </>
          )}

        <DataFields<AdminMetadata>
          fields={fields}
          data={metadata as AdminMetadata}
          handleSave={(field, value) => {
            return saveMeasureMetadata(measureId, field, value);
          }}
        ></DataFields>

        {metadata.value_type !== "alias" && (
          <TagsEditor
            tags={metadata.tags ?? undefined}
            saveTags={handleSetTags}
            measureId={measureId}
          ></TagsEditor>
        )}
        <section></section>

        {metadata.value_type === "survey" && (
          <AnswerValuesEditor
            measureId={measureId}
            answerValues={answerValues}
            setAnswerValues={setAnswerValues}
            refresh={loadData}
          />
        )}

        {defined(dimensions) && dimensions.length > 0 && (
          <>
            <FluentModal
              coverViewport
              title="Redigera dimensioner"
              isOpen={isEditingDimensions}
              onClose={() => setIsEditingDimensions(false)}
            >
              <FluentModalBody>
                <DimensionsEditorV2
                  handleSaveDimensionsOrder={handleSaveDimensionsOrder}
                  handleEditDimension={handleEditDimension}
                  dimensions={dimensions}
                ></DimensionsEditorV2>
              </FluentModalBody>
            </FluentModal>
            <section>
              <Button
                title="Redigera dimensioner"
                onClick={() => setIsEditingDimensions(true)}
              ></Button>
            </section>
          </>
        )}
        <div>
          {metadata.value_type !== "alias" && (
            <>
              {metadata.retired ? (
                <Button
                  intent="danger"
                  title="Avpensionera mått"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Vill du verkligen avpensionera måttet? Måttet kommer att visas i menyer igen."
                      )
                    ) {
                      handleUnretireMeasure(measureId);
                    }
                  }}
                ></Button>
              ) : (
                <Button
                  intent="danger"
                  title="Pensionera mått"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Vill du verkligen pensionera måttet? Måttet kommer inte längre att visas förutom i dokument som redan använder det."
                      )
                    ) {
                      handleRetireMeasure(measureId);
                    }
                  }}
                ></Button>
              )}
            </>
          )}
        </div>
      </>
    </div>
  );
}

function AliasEditor(props: {
  alias: MeasureAlias;
  handleSave: (alias: MeasureAlias) => Promise<HttpResult<unknown>>;
  handleRemove: (aliasId: number) => Promise<HttpResult<unknown> | undefined>;
}) {
  const { alias } = props;
  const [isEditingSubjectPath, setIsEditingSubjectPath] = useState(false);
  const [path, setPath] = useState<string[]>([
    alias.area,
    alias.subarea,
    alias.subject,
  ]);
  const [measure, setMeasure] = useState<string>(alias.measure);
  const messagesHandler = useContext(AppMessagesContext);
  return (
    <div>
      {isEditingSubjectPath ? (
        <>
          <section>
            <SubjectPathSelection
              area={path[0]}
              subarea={path[1]}
              subject={path[2]}
              handleUpdateSelection={(p) => setPath(p)}
            />
            <TextField
              value={measure}
              onChange={(e) => setMeasure(e.currentTarget.value)}
              label="Mått"
            ></TextField>
          </section>
          <ButtonsFooter>
            <ButtonsFooterRight>
              <>
                <Button
                  title="Avbryt"
                  onClick={() => setIsEditingSubjectPath(false)}
                ></Button>
                <Button
                  intent="primary"
                  title="Spara"
                  onClick={() => {
                    setIsEditingSubjectPath(false);
                    const [area, subarea, subject] = path;
                    if (
                      !defined(area) ||
                      !defined(subarea) ||
                      !defined(subject)
                    ) {
                      throw new Error(
                        "Area, subarea or subject is undefined! Path: " + path
                      );
                    }
                    if (!defined(measure)) {
                      throw new Error("Measure is undefined!");
                    }
                    props
                      .handleSave({
                        alias_data_id: alias.alias_data_id,
                        area,
                        subarea,
                        subject,
                        measure,
                      })
                      .then((res) => {
                        res.match({
                          err: (err) =>
                            messagesHandler?.add(
                              "error",
                              displayHttpErrorInternal(err)
                            ),
                          ok: () => {
                            setIsEditingSubjectPath(false);
                            messagesHandler?.add(
                              "success",
                              "Alias uppdaterat!"
                            );
                          },
                        });
                      });
                  }}
                ></Button>
              </>
            </ButtonsFooterRight>
          </ButtonsFooter>
        </>
      ) : (
        <div className="editable-field subject-path">
          <div>
            <div className="label">
              <label>{`area > subarea > subject > measure`}</label>
            </div>
            <TextField
              disabled
              readOnly
              value={` ${alias.area} > ${alias.subarea} > ${alias.subject} > ${alias.measure}`}
            ></TextField>
          </div>
          <Button
            intent="danger"
            title="Ta bort"
            onClick={() => props.handleRemove(alias.alias_data_id)}
          ></Button>
          <Button
            intent="primary"
            title="Redigera"
            onClick={() => setIsEditingSubjectPath(true)}
          ></Button>
        </div>
      )}
    </div>
  );
}

function NewAliasCreator(props: {
  measureId: number;
  handleCreateAlias: (
    measureId: number,
    alias: Omit<MeasureAlias, "alias_data_id">
  ) => Promise<HttpResult<unknown>>;
}) {
  const [isCreatingAlias, setIsCreatingAlias] = useState(false);
  const [measure, setMeasure] = useState<string>("");
  const messagesHandler = useContext(AppMessagesContext);
  const [path, setPath] = useState<string[]>([]);

  const handleSubmit = useCallback(() => {
    const [area, subarea, subject] = path;
    if (!defined(area) || !defined(subarea) || !defined(subject)) {
      return messagesHandler?.add("error", "Ogiltig sökväg") as any;
    }
    if (!defined(measure)) {
      return messagesHandler?.add("error", "Mått saknas") as any;
    }
    if (!nonEmptyString(measure)) {
      return messagesHandler?.add("error", "Mått saknas") as any;
    }

    return props
      .handleCreateAlias(props.measureId, {
        area,
        subarea,
        subject,
        measure,
      })
      .then((res) => {
        res.unwrap() as any;
        setIsCreatingAlias(false);
      });
  }, [measure, messagesHandler, path, props]);

  return (
    <div>
      {isCreatingAlias ? (
        <>
          <section>
            <SubjectPathSelection
              area={path[0]}
              subarea={path[1]}
              subject={path[2]}
              handleUpdateSelection={(p) => setPath(p)}
            ></SubjectPathSelection>
            <TextField
              label="Mått"
              required
              onChange={(e) => setMeasure(e.currentTarget.value)}
              value={measure}
            ></TextField>
          </section>
          <ButtonsFooter>
            {/* <ButtonsFooterLeft></ButtonsFooterLeft> */}
            <ButtonsFooterRight>
              <>
                <Button
                  title="Avbryt"
                  onClick={() => setIsCreatingAlias(false)}
                ></Button>
                <Button
                  intent="primary"
                  title="Spara"
                  onClick={handleSubmit}
                ></Button>
              </>
            </ButtonsFooterRight>
          </ButtonsFooter>
        </>
      ) : (
        <Button
          title="Nytt alias"
          onClick={() => setIsCreatingAlias(true)}
        ></Button>
      )}
    </div>
  );
}

function AnswerValuesEditor(props: {
  measureId: number;
  answerValues: SurveyValueDto[] | undefined;
  setAnswerValues: React.Dispatch<
    React.SetStateAction<SurveyValueDto[] | undefined>
  >;
  refresh: (measureId: number) => void;
}) {
  const { measureId, answerValues, setAnswerValues } = props;
  const [isAdding, setIsAdding] = useState(false);
  const appMessagesHandler = useContext(AppMessagesContext);

  const deleteAnswerValue = useCallback(
    (id: number) => {
      return deleteSurveyValue(id).then((res) => {
        res.match({
          ok: () => {
            setAnswerValues((prev) =>
              prev?.filter((av) => av.dimension_value_id !== id)
            );
          },
          err: (err) => {
            appMessagesHandler?.add(
              "error",
              "Kunde inte ta bort värdet. Endast oanvända värden kan tas bort. Fel: " +
                displayHttpErrorInternal(err)
            );
          },
        });
      });
    },
    [appMessagesHandler, setAnswerValues]
  );

  const updateAnswerValue = useCallback(
    (updatedAnswerValue: SurveyValueDto) => {
      return surveyValuePutDates(
        updatedAnswerValue.dimension_value_id,
        updatedAnswerValue.start_valid_period ?? null,
        updatedAnswerValue.end_valid_period ?? null
      ).then((res) => {
        res.match({
          ok: () => {
            appMessagesHandler?.add("success", "Uppdaterat");
            props.refresh(measureId);
          },
          err: (err) => {
            appMessagesHandler?.add(
              "error",
              "Kunde inte uppdatera värdet. Fel: " +
                displayHttpErrorInternal(err)
            );
          },
        });
        return res;
      });
    },
    [appMessagesHandler, measureId, props]
  );

  return (
    <div>
      <FoldoutPanel
        title="Svarsalternativ"
        className="survey-values-editor margin-bottom-md"
      >
        <div className="answer-values-list">
          {answerValues && answerValues.length > 0 ? (
            answerValues.map((answerValue) => (
              <AnswerValueItem
                key={answerValue.dimension_value_id}
                answerValue={answerValue}
                onDelete={(id) => {
                  if (
                    !window.confirm(
                      "Är du säker på att du vill ta bort värdet?"
                    )
                  ) {
                    return;
                  }
                  deleteAnswerValue(id);
                }}
                onSave={(updatedAnswerValue) => {
                  return updateAnswerValue(updatedAnswerValue).then((res) => {
                    res.match({
                      ok: () => {
                        props.refresh(measureId);
                      },
                      err: (err) => {
                        appMessagesHandler?.add(
                          "error",
                          "Kunde inte uppdatera värdet."
                        );
                      },
                    });
                    return res;
                  });
                }}
              />
            ))
          ) : (
            <p>Inga svarsalternativ tillgängliga.</p>
          )}
        </div>
        <>
          {isAdding ? (
            <NewAnswerValueForm
              onCancel={() => setIsAdding(false)}
              onSave={(newAnswerValue) => {
                createSurveyValue(
                  measureId,
                  newAnswerValue.label,
                  newAnswerValue.start_valid_period
                ).then((res) => {
                  res.match({
                    ok: () => {
                      setIsAdding(false);
                      props.refresh(measureId);
                    },
                    err: (err) => {
                      appMessagesHandler?.add(
                        "error",
                        "Kunde inte skapa nytt värde: " +
                          displayHttpErrorInternal(err)
                      );
                    },
                  });
                });
              }}
            />
          ) : (
            <div>
              <Button
                onClick={() => setIsAdding(true)}
                title="Lägg till nytt värde"
              ></Button>
            </div>
          )}
        </>
      </FoldoutPanel>
    </div>
  );
}

function AnswerValueItem(props: {
  answerValue: SurveyValueDto;
  onDelete: (id: number) => void;
  onSave: (updatedAnswerValue: SurveyValueDto) => Promise<HttpResult<unknown>>;
}) {
  const { answerValue, onDelete, onSave } = props;

  const [validFrom, setValidFrom] = useState<Date | undefined>(
    answerValue.start_valid_period
      ? new Date(answerValue.start_valid_period)
      : undefined
  );
  const [validTo, setValidTo] = useState<Date | undefined>(
    answerValue.end_valid_period
      ? new Date(answerValue.end_valid_period)
      : undefined
  );

  const [isEditing, setIsEditing] = useState(false);

  const validPeriod = useMemo(() => {
    let text = "";
    const start = answerValue.start_valid_period;
    if (defined(start)) {
      text += start.slice(0, 10) + "–";
    }
    const end = answerValue.end_valid_period;
    if (defined(end)) {
      text += `${nonEmptyString(text) ? "" : "–"}${end.slice(0, 10)}`;
    }
    return text;
  }, [answerValue.end_valid_period, answerValue.start_valid_period]);

  return (
    <div
      className={classNames(isEditing ? "editing" : "", "answer-value-item")}
    >
      <div>
        <strong>{answerValue.label}</strong>
        {nonEmptyString(validPeriod) && <span> ({validPeriod})</span>}
      </div>
      {isEditing ? (
        <>
          <div className="valid-dates">
            <InfostatDatePickerControlled
              label="Giltigt från"
              value={validFrom}
              onChange={setValidFrom}
              disabled={false}
            />
            <InfostatDatePickerControlled
              label="Giltigt till"
              value={validTo}
              onChange={setValidTo}
              disabled={false}
            />
          </div>
          <div className="actions">
            <Button title="Avbryt" onClick={() => setIsEditing(false)}></Button>
            <Button
              title="Spara"
              intent="primary"
              onClick={() => {
                if (
                  defined(validFrom) &&
                  defined(validTo) &&
                  validFrom > validTo
                ) {
                  return alert(
                    "Giltigt från-datum måste vara före giltigt till-datum."
                  );
                }

                return onSave({
                  ...answerValue,
                  start_valid_period: validFrom
                    ? formatDateSE(validFrom)
                    : undefined,
                  end_valid_period: validTo ? formatDateSE(validTo) : undefined,
                }).then(() => setIsEditing(false));
              }}
            ></Button>
          </div>
        </>
      ) : (
        // Show Edit and Delete buttons
        <div className="actions">
          <Button
            small
            title="Redigera"
            intent="primary"
            onClick={() => setIsEditing(true)}
          ></Button>
          <Button
            small
            title="Ta bort"
            intent="danger"
            onClick={() => onDelete(answerValue.dimension_value_id)}
          ></Button>
        </div>
      )}
    </div>
  );
}

function NewAnswerValueForm(props: {
  onCancel: () => void;
  onSave: (newAnswerValue: {
    label: string;
    start_valid_period?: string;
  }) => void;
}) {
  const { onCancel, onSave } = props;

  const [label, setLabel] = useState<string>("");
  const [validFrom, setValidFrom] = useState<Date | undefined>(undefined);
  const appMessagesHandler = useContext(AppMessagesContext);

  const handleSubmit = () => {
    if (!label.trim()) {
      appMessagesHandler?.add("error", "Etikett får inte vara tom.");
      return;
    }
    onSave({
      label,
      start_valid_period: validFrom ? formatDateSE(validFrom) : undefined,
    });
  };

  return (
    <div className="new-answer-value-form">
      <h3>Nytt svarsalternativ</h3>
      <div className="fields">
        <TextField
          label="Etikett"
          required
          value={label}
          onChange={(e) => setLabel(e.currentTarget.value)}
        />
        <InfostatDatePickerControlled
          label="Giltigt från"
          value={validFrom}
          onChange={setValidFrom}
          disabled={false}
        />
      </div>
      <div className="actions">
        <Button title="Avbryt" onClick={onCancel}></Button>
        <Button title="Spara" intent="primary" onClick={handleSubmit}></Button>
      </div>
    </div>
  );
}
