import { useCallback } from "react";

import {
  ButtonsFooter,
  ButtonsFooterRight,
} from "../../../../../../components/ButtonContainers";
import {
  EditableInputText,
  EditableInputTextArea,
} from "../../../../../../components/input/EditableInput";
import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../../components/Modal";
import { Button } from "../../../../../../components/Button";
import { MicroMapSettings } from "../../../../../../lib/application/state/stats/document-core/core-micro";

/**
 * Component that allows editing:
 * - Title
 * - Subtitles
 */
export function CustomHeadersDialog(props: {
  cardId: string;
  onClose: () => void;
  settings: MicroMapSettings;
  handleUpdateSettings: (
    updater: (prev: MicroMapSettings) => MicroMapSettings
  ) => void;
}) {
  const { settings, handleUpdateSettings } = props;

  const handleSetTitle = useCallback(
    (title: string | null) => {
      handleUpdateSettings((prev) => ({
        ...prev,
        customHeader: title ?? undefined,
      }));
      return Promise.resolve();
    },
    [handleUpdateSettings]
  );

  const handleSetSubtitles = useCallback(
    (subtitlesRaw: string | null) => {
      handleUpdateSettings((prev) => ({
        ...prev,
        customSubheaders: subtitlesRaw ?? undefined,
      }));
      return Promise.resolve();
    },
    [handleUpdateSettings]
  );

  return (
    <FluentModal width="md" isOpen onClose={props.onClose} title="Ändra texter">
      <FluentModalBody>
        <div id="custom-description-dialog">
          <p>
            Fyll i egna texter för att ersätta rubriker och underrubriker för
            kartan. Rubrikerna visas endast när kortet är i visningsläge.
          </p>
          <section>
            <EditableInputText
              label="Rubrik"
              handleSave={handleSetTitle}
              handleClear={() => handleSetTitle(null)}
              initialValue={settings.customHeader ?? ""}
            />
          </section>
          <section>
            <EditableInputTextArea
              label="Underrubriker"
              handleSave={handleSetSubtitles}
              handleClear={() => handleSetSubtitles(null)}
              initialValue={settings.customSubheaders ?? ""}
            />
          </section>
        </div>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterRight>
            <Button title="Stäng" onClick={props.onClose}></Button>
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModal>
  );
}
