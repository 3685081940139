import {
  Array as ArrayRT,
  Record,
  Number as NumberRT,
  String as StringRT,
  Static,
  Null,
  Optional,
} from "runtypes";

export const SurveyValueDtoRT = Record({
  data_id: NumberRT,
  dimension_value_id: NumberRT,
  label: StringRT,
  start_valid_period: Optional(StringRT).Or(Null),
  end_valid_period: Optional(StringRT).Or(Null),
});
export type SurveyValueDto = Static<typeof SurveyValueDtoRT>;
export const SurveyValueListDtoRT = ArrayRT(SurveyValueDtoRT);
export const SurveyValuesListResponseRT =
  Optional(SurveyValueListDtoRT).Or(Null);
