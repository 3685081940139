import {
  Null,
  Array as ArrayRT,
  Number as NumberRT,
  Boolean as BooleanRT,
  Optional,
  Record,
  String as StringRT,
  Static,
  Literal,
} from "runtypes";
import { GeoTypeMicro, GeoTypeMicroRT } from "../domain/geography";
import {
  AggregationMethodRT,
  DataValueTypeAnyRT,
  DataValueTypeMicroAllRT,
  DataValueTypeRegular,
  DataValueTypeRegularRT,
} from "../infra/api_responses/dataset";
import { ComputedMeasurementTypeRT } from "../infra/api_responses/micro_dataset";

const MeasureAliasRT = Record({
  alias_data_id: NumberRT,
  measure: StringRT,
  area: StringRT,
  subarea: StringRT,
  subject: StringRT,
});

export type MeasureAlias = Static<typeof MeasureAliasRT>;

const OptStringOrNull = Optional(StringRT.Or(Null));
export const AdminMetadataRT = Record({
  value_type: DataValueTypeAnyRT,
  data_id: NumberRT,
  auto_import_spec_id: Optional(NumberRT.Or(Null)),
  aliases: Optional(Null.Or(ArrayRT(MeasureAliasRT))),
  area: StringRT,
  subarea: StringRT,
  subject: StringRT,
  measure: StringRT,
  agg_method_geo: AggregationMethodRT,
  resolution: StringRT,
  descr_long: StringRT,
  unit_label: StringRT,
  ext_descr: OptStringOrNull,
  ext_label: OptStringOrNull,
  ext_descr_long: OptStringOrNull,
  public_comment: OptStringOrNull,
  state: OptStringOrNull,
  tags: Optional(Null.Or(ArrayRT(StringRT))),
  retired: Optional(BooleanRT), // FIXME: This should be required
  label: StringRT,
  source: StringRT,
  source_url: OptStringOrNull,
  last_update: OptStringOrNull,
  next_update: OptStringOrNull,
  ext_source: OptStringOrNull,
  organization_access_restrictions: Null.Or(ArrayRT(StringRT)),
  organization_access_restriction_names: Null.Or(ArrayRT(StringRT)),
  member_org_measurement_owner_org: Null.Or(StringRT),
  dates: Optional(Null.Or(ArrayRT(StringRT))),
  // Survey values
  survey_low_base_limit: Optional(NumberRT),
  survey_dubious_base_limit: Optional(NumberRT),
  survey_question: OptStringOrNull,
  survey_question_type: Optional(
    Literal("singlechoice").Or(Literal("multichoice"))
  ),
  survey_filter_info: OptStringOrNull,
  survey_internal_comment: OptStringOrNull,
  survey_method_info_public: OptStringOrNull,
  survey_target_group_info_public: OptStringOrNull,
  survey_method: OptStringOrNull,
  survey_weight_info: OptStringOrNull,
});
export type AdminMetadata = Static<typeof AdminMetadataRT>;

// export const AdminMetadataOrAliasRT = AdminMetadataRT;
export const AdminMetadataOrAliasRT = AdminMetadataRT.Or(
  Record({
    data_id: NumberRT,
    value_type: Literal("alias"),
    alias_label: OptStringOrNull,
    label: StringRT,
    area: StringRT,
    subarea: StringRT,
    subject: StringRT,
    measure: StringRT,
  })
);
export type AdminMetadataOrAlias = Static<typeof AdminMetadataOrAliasRT>;

export const AdminMeasuresListResponseRT = Record({
  rows: ArrayRT(AdminMetadataOrAliasRT).Or(Null),
  total_count: NumberRT,
});

export const AdminMetadataMicroRT = Record({
  mikro_id: NumberRT,
  agg_method_geo: AggregationMethodRT,
  geo_types: ArrayRT(GeoTypeMicroRT),
  resolution: StringRT,
  last_update: OptStringOrNull,
  next_update: OptStringOrNull,
  label: StringRT,
  area: StringRT,
  subarea: StringRT,
  subject: StringRT,
  measure: StringRT,
  value_type: DataValueTypeMicroAllRT,
  descr_long: StringRT,
  ext_descr: OptStringOrNull,
  ext_descr_long: OptStringOrNull,
  ext_source: OptStringOrNull,
  ext_source_long: OptStringOrNull,
  source: StringRT,
  public_comment: OptStringOrNull,
  source_url: OptStringOrNull,
  unit_label: StringRT,
  tags: Optional(Null.Or(ArrayRT(StringRT))),
  retired: Optional(BooleanRT),
  state: OptStringOrNull,
  enabled_computed_measures: Null.Or(ArrayRT(ComputedMeasurementTypeRT)),
  organization_access_restrictions: Null.Or(ArrayRT(StringRT)),
});
export type AdminMetadataMicroDto = Static<typeof AdminMetadataMicroRT>;

export const AdminMeasuresListResponseMicroRT = Record({
  rows: ArrayRT(AdminMetadataMicroRT).Or(Null),
  total_count: NumberRT,
});

export type AdminMeasuresListResponseMicro = Static<
  typeof AdminMeasuresListResponseMicroRT
>;

export type AdminMeasuresListResponse = Static<
  typeof AdminMeasuresListResponseRT
>;

export const NewMetadataInputRT = Record({
  area: StringRT,
  subarea: StringRT,
  subject: StringRT,
  measure: StringRT,
  resolution: StringRT,
  value_type: DataValueTypeRegularRT,
  agg_method_geo: AggregationMethodRT,
  descr_long: StringRT,
  unit_label: StringRT,
  ext_descr: OptStringOrNull,
  ext_label: OptStringOrNull,
  ext_descr_long: OptStringOrNull,
  public_comment: OptStringOrNull,
  retired: BooleanRT,
  label: StringRT,
  source: StringRT,
  source_url: OptStringOrNull,
  last_update: OptStringOrNull,
  next_update: OptStringOrNull,
  ext_source: OptStringOrNull,
  breakdowns: Null.Or(
    Optional(ArrayRT(Record({ label: StringRT, column: StringRT })))
  ),
});

export type NewMetadataInput = Static<typeof NewMetadataInputRT>;
export interface NewMetadataInputMicro {
  area: string;
  subarea: string;
  subject: string;
  measure: string;
  agg_method_geo: string;
  resolution: string;
  geo_types: GeoTypeMicro[];
  value_type: DataValueTypeRegular;
  breakdowns: { label: string; column: string }[] | null;
  descr_long: string;
  unit_label: string;
  ext_descr?: string;
  ext_label?: string;
  ext_descr_long?: string;
  public_comment?: string;
  retired: boolean;
  label: string;
  source: string;
  source_url?: string;
  last_update?: string;
  next_update?: string;
  ext_source?: string;
}
