import { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { sortBy, uniq } from "lodash";
import {
  Checkbox,
  DetailsList,
  Dropdown,
  IColumn,
  SelectionMode,
  TextField,
} from "@fluentui/react";

import { useIncrementer } from "../../../../lib/application/hooks/useIncrementer";
import {
  AppMessagesContext,
  UserInfoContext,
} from "../../../../lib/application/contexts";
import { defined } from "../../../../lib/core/defined";
import { Table } from "../../../../components/Table";
import { Button } from "../../../../components/Button";
import { orgAdminApi } from "../../../../lib/application/requests/org_admin/org_admin_api";
import { nonEmptyString } from "../../../../lib/core/nonEmptyString";
import { logger } from "../../../../lib/infra/logging";
import {
  HttpError,
  displayHttpErrorInternal,
} from "../../../../lib/infra/HttpResult";
import { DefaultLoading } from "../../../../components/Loading";
import { displayHttpError } from "../../../../components/errors/HttpErrorNotice";
import { MyOrganizationDetails } from "../../../../lib/infra/api_responses/org_admin/organization";
import { Card } from "../../../../components/Card";
import {
  filterApplicableLicensesIds,
  licenseDepsFulfilled,
} from "../../../../lib/domain/licenses/license_tree";
import { AlertBox } from "../../../../components/AlertBox";
import { OrganizationLicenseV2Dto } from "../../../../lib/infra/api_responses/admin/organizations";
import {
  PROGRESS_BAR_BACKGROUND_GREEN,
  PROGRESS_BAR_GREEN,
  PROGRESS_BAR_GREEN_LIGHT,
  PROGRESS_BAR_RED,
  PROGRESS_BAR_RED_LIGHT,
  ProgressBar,
} from "../../../../components/ProgressBar";
import { displayCommonError } from "../../../../lib/infra/errors";

export function OrgLicenseManagement(props: { orgId: string }) {
  const [org, setOrg] = useState<MyOrganizationDetails>();
  // Keeps track of number of changes, so that getData reloads on changes initiated by this component
  const [numChanges, incNumChanges] = useIncrementer(0);

  const userInfo = useContext(UserInfoContext);

  const [newLicenseEmails, setNewLicenseEmails] = useState<string>("");
  const [changePending, setChangePending] = useState(false);
  const [selectedLicenseTypesForInvite, setSelectedLicenseTypesForInvite] =
    useState<number[]>([]);
  const [inviteValidationError, setInviteValidationError] =
    useState<string>("");
  const [showInviteValidationError, setShowInviteValidationError] =
    useState<boolean>(false);
  const [selectedRolesForInvite, setSelectedRolesForInvite] = useState<
    number[]
  >([]);

  const [
    selectedLicenseTypesForBulkChange,
    setSelectedLicenseTypesForBulkChange,
  ] = useState<number[]>([]);
  const [selectedRolesForBulkChange, setSelectedRolesForBulkChange] = useState<
    number[]
  >([]);
  const [selectedUsers, setSelectedUsers] = useState<{
    [key: string]: boolean;
  }>({});

  const appMessages = useContext(AppMessagesContext);

  const currentEmails = useMemo(
    () =>
      newLicenseEmails
        .trim()
        .split(/[\s ,;]+/)
        .filter(nonEmptyString)
        .map((email) => email.trim()),
    [newLicenseEmails]
  );

  const someEmailNotInAllowedDomains = useMemo(() => {
    for (const email of currentEmails) {
      const notAllowedDomain = org?.email_domains.every((domain) => {
        return !email.endsWith("@" + domain);
      });
      if (notAllowedDomain) {
        return true;
      }
    }

    return false;
  }, [currentEmails, org?.email_domains]);

  useEffect(() => {
    orgAdminApi.getMyOrganization().then((res) => {
      const orgInfo = res.unwrap();
      setOrg({
        ...orgInfo,
        licenses: sortBy(orgInfo.licenses, (l) => l.license_id),
      });
    });
  }, [props.orgId, numChanges]);

  const handleRemoveLicensesFromSelectedUsers = useCallback(() => {
    const users = org?.users;
    if (!defined(users)) {
      return;
    }
    setChangePending(true);
    Promise.all(
      Object.entries(selectedUsers)
        .filter(([, selected]) => selected === true)
        .flatMap((user) =>
          selectedLicenseTypesForBulkChange.map((licenseId) =>
            orgAdminApi.removeUserLicense(user[0], licenseId)
          )
        )
    )
      .then((res) => {
        const errors: string[] = [];
        const handleErr = (err: HttpError) => {
          logger.error(displayHttpError(err));
          errors.push(displayHttpError(err));
        };

        for (const r of res) {
          r.match({
            ok: () => {},
            err: handleErr,
          });
        }

        if (errors.length === 0) {
          setSelectedLicenseTypesForBulkChange([]);
          incNumChanges();
          appMessages?.add("success", "Licenser borttagna");
        } else {
          appMessages?.add(
            "error",
            "Ett eller flera fel uppstod:\n\n" + uniq(errors).join("\n")
          );
        }
        setChangePending(false);
      })
      .finally(() => setChangePending(false));
  }, [
    appMessages,
    incNumChanges,
    org?.users,
    selectedLicenseTypesForBulkChange,
    selectedUsers,
  ]);

  const handleAddLicensesToSelectedUsers = useCallback(() => {
    const users = org?.users;
    if (!defined(users)) {
      return;
    }
    setChangePending(true);
    Promise.all(
      Object.entries(selectedUsers)
        .filter(([, selected]) => selected === true)
        .flatMap((user) =>
          selectedLicenseTypesForBulkChange
            .filter((l) => {
              // Avoid attempting to set licenses that users already have
              const userDetails = org?.users?.find((u) => u.id === user[0]);
              if (!defined(userDetails)) {
                return false;
              }
              const hasLicense =
                userDetails.licenses?.some(
                  (license) => license.license_id === l
                ) ?? false;
              return !hasLicense;
            })
            .map((licenseId) => orgAdminApi.addUserLicense(user[0], licenseId))
        )
    )
      .then((res) => {
        const errors: string[] = [];
        const handleErr = (err: HttpError) => {
          logger.error(displayHttpError(err));
          errors.push(displayHttpError(err));
        };

        for (const r of res) {
          r.match({
            ok: () => {},
            err: handleErr,
          });
        }

        if (errors.length === 0) {
          setSelectedLicenseTypesForBulkChange([]);
          incNumChanges();
          appMessages?.add("success", "Licenser tillagda");
        } else {
          appMessages?.add(
            "error",
            "Ett eller flera fel uppstod:\n\n" + uniq(errors).join("\n")
          );
        }
        setChangePending(false);
      })
      .finally(() => setChangePending(false));
  }, [
    appMessages,
    incNumChanges,
    org?.users,
    selectedLicenseTypesForBulkChange,
    selectedUsers,
  ]);

  const handleAddRolesToSelectedUsers = useCallback(() => {
    const users = org?.users;
    if (!defined(users)) {
      return;
    }
    setChangePending(true);
    Promise.all(
      Object.entries(selectedUsers)
        .filter(([, selected]) => selected === true)
        .flatMap((user) =>
          selectedRolesForBulkChange
            .filter((r) => {
              // Avoid attempting to set licenses that users already have
              const userDetails = org?.users?.find((u) => u.id === user[0]);
              if (!defined(userDetails)) {
                return false;
              }
              const hasRole =
                userDetails.roles?.some((roles) => roles.role_id === r) ??
                false;
              return !hasRole;
            })
            .map((roleId) => orgAdminApi.addUserRole(user[0], roleId))
        )
    )
      .then((res) => {
        const errors: string[] = [];
        const handleErr = (err: HttpError) => {
          logger.error(displayHttpError(err));
          errors.push(displayHttpError(err));
        };

        for (const r of res) {
          r.match({
            ok: () => {},
            err: handleErr,
          });
        }

        if (errors.length === 0) {
          setSelectedRolesForBulkChange([]);
          incNumChanges();
          appMessages?.add("success", "Roller tillagda");
        } else {
          appMessages?.add(
            "error",
            "Ett eller flera fel uppstod:\n\n" + uniq(errors).join("\n")
          );
        }
        setChangePending(false);
      })
      .finally(() => setChangePending(false));
  }, [
    appMessages,
    incNumChanges,
    org?.users,
    selectedRolesForBulkChange,
    selectedUsers,
  ]);

  const handleRemoveRolesFromSelectedUsers = useCallback(() => {
    const users = org?.users;
    if (!defined(users)) {
      return;
    }
    setChangePending(true);
    Promise.all(
      Object.entries(selectedUsers)
        .filter(([, selected]) => selected === true)
        .flatMap((user) =>
          selectedRolesForBulkChange.map((roleId) =>
            orgAdminApi.removeUserRole(user[0], roleId)
          )
        )
    )
      .then((res) => {
        const errors: string[] = [];
        const handleErr = (err: HttpError) => {
          logger.error(displayHttpErrorInternal(err));
          errors.push(displayCommonError(err) ?? displayHttpError(err));
        };

        for (const r of res) {
          r.match({
            ok: () => {},
            err: handleErr,
          });
        }

        if (errors.length === 0) {
          setSelectedRolesForBulkChange([]);
          incNumChanges();
          appMessages?.add("success", "Roller borttagna");
        } else {
          appMessages?.add(
            "error",
            "Ett eller flera fel uppstod:\n\n" + uniq(errors).join("\n")
          );
        }
        setChangePending(false);
      })
      .finally(() => setChangePending(false));
  }, [
    appMessages,
    incNumChanges,
    org?.users,
    selectedRolesForBulkChange,
    selectedUsers,
  ]);

  const handleAddNewInvites = useCallback(
    (newLicenseEmails: string[], licenseIds: number[], roleIds: number[]) => {
      setChangePending(true);
      Promise.all(
        newLicenseEmails.map((email) =>
          orgAdminApi.addOrganizationInvite(email, licenseIds, roleIds)
        )
      )
        .then((res) => {
          let allOk = true;
          const errors: string[] = [];
          const handleErr = (err: HttpError) => {
            const commonError = displayCommonError(err);
            errors.push(commonError ?? displayHttpError(err));
            logger.error(err);
            allOk = false;
          };

          for (let i = 0; i < res.length; i++) {
            const r = res[i];
            r.match({
              ok: () => {},
              err: handleErr,
            });
          }

          for (const error of uniq(errors)) {
            appMessages?.add("error", error, { durationMs: 59999 });
          }

          if (allOk) {
            setNewLicenseEmails("");
            setSelectedRolesForInvite([]);
            setSelectedLicenseTypesForInvite([]);
            setInviteValidationError("");
            incNumChanges();
            appMessages?.add("success", "Licenser tillagda");
          }
          setChangePending(false);
        })
        .finally(() => setChangePending(false));
    },
    [appMessages, incNumChanges]
  );

  const handleRemoveSingleUserLicense = useCallback(
    (userId: string, licenseId: number) => {
      return orgAdminApi.removeUserLicense(userId, licenseId).then((res) => {
        res.match({
          ok: () => {
            incNumChanges();
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Kunde inte ta bort licens: " + displayHttpError(err)
            );
          },
        });
      });
    },
    [appMessages, incNumChanges]
  );

  const handleAddSingleUserLicense = useCallback(
    (userId: string, licenseId: number) => {
      return orgAdminApi.addUserLicense(userId, licenseId).then((res) => {
        res.match({
          ok: () => {
            incNumChanges();
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Kunde inte lägga till licens: " + displayHttpError(err)
            );
          },
        });
      });
    },
    [appMessages, incNumChanges]
  );

  const handleRemoveInvite = useCallback(
    (inviteId: number) => {
      return orgAdminApi.deleteOrganizationInvite(inviteId).then((res) => {
        res.match({
          ok: () => {
            incNumChanges();
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Kunde inte ta bort inbjudan: " + displayHttpError(err)
            );
          },
        });
      });
    },
    [appMessages, incNumChanges]
  );

  const handleSendInviteEmail = (inviteId: number) => {
    return orgAdminApi
      .sendOrganizationInviteEmailToUser(inviteId)
      .then((res) => {
        res.match({
          ok: () => {
            incNumChanges();
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Kunde inte skicka mejl: " + displayHttpError(err)
            );
          },
        });
      });
  };

  const handleRemoveUserFromOrg = useCallback(
    (userId: string) => {
      return orgAdminApi.deleteUserFromOrganization(userId).then((res) => {
        res.match({
          ok: () => {
            incNumChanges();
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Kunde inte ta bort användare: " + displayHttpError(err)
            );
          },
        });
      });
    },
    [appMessages, incNumChanges]
  );

  const allUsersSelected = useMemo(() => {
    if (!defined(org) || !defined(org.users)) {
      return false;
    }
    return org.users.every((user) => selectedUsers[user.id] ?? false);
  }, [org, selectedUsers]);

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "licenseType",
        name: "Licenstyp",
        fieldName: "label",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "progress-bar",
        fieldName: "current_usage_count",
        minWidth: 100,
        maxWidth: 500,
        name: "Aktiva licenser och inbjudningar",
        onRender: (item: OrganizationLicenseV2Dto) => {
          const totalUsage =
            item.current_usage_count + item.current_invite_count;
          const max = item.max_count;
          return (
            <div className="flex-row">
              <ProgressBar
                sections={[
                  {
                    value: item.current_usage_count,
                    color:
                      totalUsage > max ? PROGRESS_BAR_RED : PROGRESS_BAR_GREEN,
                    hoverText: `${item.current_usage_count} tilldelade`,
                  },
                  {
                    value: item.current_invite_count,
                    color:
                      totalUsage > max
                        ? PROGRESS_BAR_RED_LIGHT
                        : PROGRESS_BAR_GREEN_LIGHT,
                    hoverText: `${item.current_invite_count} inbjudna`,
                  },
                  {
                    value: item.max_count - totalUsage,
                    color: PROGRESS_BAR_BACKGROUND_GREEN,
                    hoverText: `${item.max_count - totalUsage} oanvända`,
                  },
                ]}
              />
              <span className="margin-left-sm">
                {totalUsage} / {item.max_count}
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  if (!defined(org)) {
    return <DefaultLoading />;
  }

  const hasSelectedUsers =
    Object.values(selectedUsers).filter((v) => v === true).length !== 0;
  const hasSelectedLicensesBulkEdit =
    selectedLicenseTypesForBulkChange.length !== 0;

  return (
    <div id="org-license-management">
      <Card>
        <h2>Licenser</h2>
        <DetailsList
          items={org.licenses ?? []}
          columns={columns}
          setKey="set"
          selectionMode={SelectionMode.none}
        />
      </Card>

      <Card>
        <>
          <h2>Bjud in användare</h2>
          <p>
            Tillåtna domäner:{" "}
            <strong>
              {org.email_domains.length === 0
                ? "Inga. Kontakta support"
                : org.email_domains.join(", ")}
            </strong>
          </p>
          <div className="flex-row">
            <TextField
              className="license-handling-field"
              value={newLicenseEmails}
              placeholder="Mejladresser"
              onChange={(e) => {
                if (!someEmailNotInAllowedDomains) {
                  setInviteValidationError("");
                }
                setNewLicenseEmails(e.currentTarget.value);
              }}
            />
            <Dropdown
              id="new-user-licenses"
              className="margin-left-sm dropdown"
              placeholder="Välj licenser"
              dropdownWidth="auto"
              multiSelect
              selectedKeys={selectedLicenseTypesForInvite}
              options={
                org.licenses?.map((type) => ({
                  key: type.license_id,
                  disabled: !licenseDepsFulfilled(
                    type,
                    selectedLicenseTypesForInvite.map((id) => ({
                      license_id: id,
                    }))
                  ),
                  text: type.label,
                })) ?? []
              }
              onChange={(event, item) => {
                if (!defined(item)) {
                  return;
                }
                const key = item.key as number;
                setSelectedLicenseTypesForInvite(
                  filterApplicableLicensesIds(
                    selectedLicenseTypesForInvite.includes(key)
                      ? selectedLicenseTypesForInvite.filter((i) => i !== key)
                      : [...selectedLicenseTypesForInvite, key],
                    []
                  )
                );
              }}
            />
            <Dropdown
              id="new-user-roles"
              className="margin-x-sm dropdown"
              placeholder="Välj roller (valfritt)"
              dropdownWidth="auto"
              multiSelect
              selectedKeys={selectedRolesForInvite}
              options={org.available_roles.map((role) => ({
                key: role.role_id,
                text: role.label,
              }))}
              onChange={(event, item) => {
                if (!defined(item)) {
                  return;
                }
                const key = item.key as number;
                setSelectedRolesForInvite(
                  selectedRolesForInvite.includes(key)
                    ? selectedRolesForInvite.filter((i) => i !== key)
                    : [...selectedRolesForInvite, key]
                );
              }}
            />
            <Button
              intent="primary"
              title="Bjud in användare"
              disabled={
                changePending ||
                selectedLicenseTypesForInvite.length === 0 ||
                !currentEmails.every(
                  (email) => nonEmptyString(email) && email.includes("@")
                )
              }
              onClick={() => {
                if (someEmailNotInAllowedDomains) {
                  setShowInviteValidationError(true);
                  setInviteValidationError(
                    "Endast mejladresser med tillåtna domäner kan bjudas in. Tillåtna domäner: " +
                      org.email_domains.join(", ")
                  );
                  return;
                }
                handleAddNewInvites(
                  currentEmails,
                  selectedLicenseTypesForInvite,
                  selectedRolesForInvite
                );
              }}
            />
          </div>

          {showInviteValidationError &&
            nonEmptyString(inviteValidationError) && (
              <AlertBox className="margin-top-sm" intent="warning">
                <span>{inviteValidationError}</span>
              </AlertBox>
            )}

          <h3 className="margin-top-md">Aktiva inbjudningar</h3>
          {!defined(org.invites) ? (
            "Inga aktiva inbjudningar"
          ) : (
            <Table
              columns={[
                { name: "Inbjuden epostadress", type: "text" },
                { name: "Licenser", type: "text" },
                { name: "Roller", type: "text" },
                { name: "Inbjudan skapad av", type: "text" },
                { name: "Mejl skickat", type: "text" },
                { name: "", type: "icon" },
              ]}
              data={org.invites.map((invite) => {
                let user_notified_at = "";
                if (defined(invite.user_notified_at)) {
                  user_notified_at = new Date(
                    invite.user_notified_at
                  ).toLocaleString("sv-SE");
                }
                return {
                  id: invite.organization_invite_id,
                  cells: [
                    invite.invited_email,
                    invite.license_labels?.join(", ") ?? "",
                    <strong>{invite.role_labels?.join(", ") ?? ""}</strong>,
                    invite.created_by_email,
                    user_notified_at,
                    <>
                      <Button
                        small
                        title="Ta bort"
                        onClick={() => {
                          handleRemoveInvite(invite.organization_invite_id);
                        }}
                      />
                      <Button
                        small
                        title="Skicka mejl"
                        onClick={() => {
                          if (
                            !window.confirm(
                              "Är du säker på att du vill skicka mejl till användaren?"
                            )
                          ) {
                            return;
                          }
                          handleSendInviteEmail(invite.organization_invite_id);
                        }}
                      />
                    </>,
                  ],
                };
              })}
            ></Table>
          )}
        </>
      </Card>

      <Card>
        <h2>Användare</h2>
        <>
          {defined(org.licenses) && (
            <div className="flex-row">
              <Dropdown
                className="margin-right-sm margin-bottom-sm dropdown"
                id="bulk-change-licenses"
                placeholder="Välj licenser"
                dropdownWidth="auto"
                multiSelect
                selectedKeys={selectedLicenseTypesForBulkChange}
                options={org.licenses.map((type) => ({
                  key: type.license_id,
                  disabled: !licenseDepsFulfilled(
                    type,
                    selectedLicenseTypesForBulkChange.map((id) => ({
                      license_id: id,
                    }))
                  ),
                  text: type.label,
                }))}
                onChange={(event, item) => {
                  if (!defined(item)) {
                    return;
                  }
                  const key = item.key as number;
                  setSelectedLicenseTypesForBulkChange(
                    filterApplicableLicensesIds(
                      selectedLicenseTypesForBulkChange.includes(key)
                        ? selectedLicenseTypesForBulkChange.filter(
                            (i) => i !== key
                          )
                        : [...selectedLicenseTypesForBulkChange, key],
                      []
                    )
                  );
                }}
              />

              <Button
                id="bulk-remove-licenses"
                title="Ta bort licenser"
                intent="danger"
                onClick={() => {
                  if (
                    !window.confirm(
                      "Är du säker på att du vill ta bort valda licenser för alla valda användare?"
                    )
                  ) {
                    return;
                  }
                  handleRemoveLicensesFromSelectedUsers();
                }}
                disabled={!hasSelectedUsers || !hasSelectedLicensesBulkEdit}
              />
              <Button
                id="bulk-add-licenses"
                title="Lägg till licenser"
                intent="primary"
                disabled={!hasSelectedUsers || !hasSelectedLicensesBulkEdit}
                onClick={() => {
                  handleAddLicensesToSelectedUsers();
                }}
              />
            </div>
          )}
        </>
        <>
          {defined(org.available_roles) && org.available_roles.length > 0 && (
            <div className="flex-row margin-bottom-sm">
              <Dropdown
                id="bulk-change-roles"
                className="margin-right-sm dropdown"
                placeholder="Välj roller"
                multiSelect
                selectedKeys={selectedRolesForBulkChange}
                options={org.available_roles.map((role) => ({
                  key: role.role_id,
                  text: role.label,
                }))}
                onChange={(event, item) => {
                  if (!item) return;
                  const key = item.key as number;
                  const newSelectedRoles = selectedRolesForBulkChange.includes(
                    key
                  )
                    ? selectedRolesForBulkChange.filter((id) => id !== key)
                    : [...selectedRolesForBulkChange, key];
                  setSelectedRolesForBulkChange(newSelectedRoles);
                }}
              />
              <Button
                id="bulk-remove-roles"
                title="Ta bort roller"
                intent="danger"
                onClick={handleRemoveRolesFromSelectedUsers}
                disabled={
                  !hasSelectedUsers || selectedRolesForBulkChange.length === 0
                }
              />
              <Button
                id="bulk-add-roles"
                title="Lägg till roller"
                intent="primary"
                onClick={handleAddRolesToSelectedUsers}
                disabled={
                  !hasSelectedUsers || selectedRolesForBulkChange.length === 0
                }
              />
            </div>
          )}
        </>

        <section>
          <section>
            {defined(org.users) && (
              <Checkbox
                checked={allUsersSelected}
                label="Välj alla"
                onChange={(e, checked) => {
                  if (!defined(checked)) {
                    return;
                  }
                  const users = org.users;
                  if (!defined(users)) {
                    return;
                  }
                  setSelectedUsers(
                    users.reduce(
                      (prev, curr) => ({ ...prev, [curr.id]: checked }),
                      {} as { [key: string]: boolean }
                    )
                  );
                }}
              ></Checkbox>
            )}

            {defined(org.users) && (
              <Table
                columns={[
                  { name: "Välj", type: "icon" },
                  { name: "Mejladress", type: "text" },
                  ...(org.licenses?.map((l) => ({
                    name: l.label,
                    type: "text" as const,
                  })) ?? []),
                  { name: "", classNames: "action-column", type: "icon" },
                ]}
                data={org.users.map((user) => {
                  const canRemoveUser = user.email !== userInfo?.email();
                  return {
                    id: user.id,
                    cells: [
                      <Checkbox
                        checked={selectedUsers[user.id] ?? false}
                        onChange={(e, checked) => {
                          if (!defined(checked)) {
                            return;
                          }
                          setSelectedUsers((prev) => ({
                            ...prev,
                            [user.id]: checked,
                          }));
                        }}
                      ></Checkbox>,
                      <span>
                        {user.email}
                        {defined(user.roles) ? (
                          <strong>
                            {" "}
                            ({user.roles.map((r) => r.label).join(", ")})
                          </strong>
                        ) : (
                          ""
                        )}
                      </span>,
                      ...(org.licenses?.map((l) => {
                        return (
                          <Checkbox
                            disabled={!licenseDepsFulfilled(l, user.licenses)}
                            checked={
                              user.licenses?.some(
                                (license) => license.license_id === l.license_id
                              ) ?? false
                            }
                            onChange={(e, checked) => {
                              if (!defined(checked)) {
                                return;
                              }
                              if (checked) {
                                handleAddSingleUserLicense(
                                  user.id,
                                  l.license_id
                                );
                              } else {
                                handleRemoveSingleUserLicense(
                                  user.id,
                                  l.license_id
                                );
                              }
                            }}
                          />
                        );
                      }) ?? []),
                      <Button
                        id="remove-user-from-org"
                        small
                        disabled={!canRemoveUser}
                        title="Ta bort"
                        onClick={() => {
                          if (
                            !window.confirm(
                              "Är du säker på att du vill ta bort användaren från organisationen?"
                            )
                          ) {
                            return;
                          }
                          handleRemoveUserFromOrg(user.id);
                        }}
                      />,
                    ],
                  };
                })}
              />
            )}
          </section>
        </section>
      </Card>
    </div>
  );
}
