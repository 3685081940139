import * as _ from "lodash";

import { defined } from "../../../core/defined";
import {
  LabelAreas,
  LegendPositioningBottom,
  LegendPositioningSide,
  LEGEND_COLOR_INDICATOR_CHAR,
  Orientation,
} from "./core/definitions";
import {
  calculateTextWidth,
  MAX_LABEL_WIDTH,
} from "./core/text_containers/measure";
import { defaultDimensionLabelTextStyle, TextStyle } from "./core/TextStyle";
import {
  LabelRowSet,
  LabelsRow,
  MultilineText,
  TextContainerPositioned,
} from "./core/text_containers/";
import {
  LegendLabel,
  makeDefaultMultilineLabelVerticalBars,
} from "./bar_chart/bar_chart_common";
import { TicksStyle, YTicksContainer } from "./core/ticks";
import { Position2D } from "../../../core/space/position";
import { max, sum } from "lodash";

const LEGEND_LABEL_WIDTH_PADDING = 25;
const LEGEND_LABEL_HEIGHT_PADDING = 2;
const LEGEND_MIN_FEASIBLE_WIDTH = 25;
const COLOR_INDICATOR_PADDING_RIGHT = 5;
export const GROUPED_LABELS_HORIZONTAL_BARS_PADDING_RIGHT = 10;

/**
 * Calculate height when used as multi-line label
 */
export function labelHeight(label: string, textStyle: TextStyle): number {
  return makeDefaultMultilineLabelVerticalBars(label, textStyle).height;
}

function verticallyStackedLabelsToWidth(
  labels: string[],
  textStyle: TextStyle
): number {
  return (
    _.max(
      labels.map(
        (l) => makeDefaultMultilineLabelVerticalBars(l, textStyle).maxWidth
      )
    ) ?? 0
  );
}

export function calculateLabelAreasBarChartVertical(
  rangeLabels: string[][],
  layeredLabelsTotalHeight: number | undefined,
  legendOrientation: Orientation | undefined,
  legendLabels: string[] | undefined,
  ticksStyleYAxis: TicksStyle | undefined,
  ticksStyleXAxis: TicksStyle | undefined,
  textStyle: TextStyle,
  desiredMaxLabelWidthVerticalBars?: number
): LabelAreas {
  const labelsBottomRestArgs = [
    0,
    ticksStyleXAxis?.totalSize ?? 0,
    textStyle,
    desiredMaxLabelWidthVerticalBars,
  ] as const;

  const legendLabelsBottom = labelsToRows(
    legendOrientation === Orientation.horizontal ? [legendLabels ?? []] : [],
    ...labelsBottomRestArgs
  );
  return {
    labelsRight: defined(legendLabels)
      ? {
          totalWidth: verticallyStackedLabelsToWidth(legendLabels, textStyle),
        }
      : undefined,
    labelsLeft: {
      totalWidth:
        (ticksStyleYAxis?.totalSize ?? 0) +
        _.sum(
          rangeLabels.map((l) => verticallyStackedLabelsToWidth(l, textStyle))
        ),
    },
    labelsBottom: {
      heightWithLegend:
        legendLabelsBottom.totalHeight + (layeredLabelsTotalHeight ?? 0),
      heightWithoutLegend: layeredLabelsTotalHeight ?? 0,
    },
  };
}

export function calculateLabelAreasBarChartHorizontal(
  labelLayersTotalWidth: number | undefined,
  legendLabels: string[] | undefined,
  ticksStyleYAxis: TicksStyle | undefined,
  ticksStyleXAxis: TicksStyle | undefined,
  textStyle: TextStyle,
  desiredMaxLabelWidthVerticalBars?: number
): LabelAreas {
  const labelsBottomRestArgs = [
    0,
    ticksStyleXAxis?.totalSize ?? 0,
    textStyle,
    desiredMaxLabelWidthVerticalBars,
  ] as const;
  const labelsWithLegend = labelsToRows(
    [legendLabels ?? []],
    ...labelsBottomRestArgs
  );
  const labelsWithoutLegend = labelsToRows([], ...labelsBottomRestArgs);
  return {
    labelsRight: undefined,
    labelsLeft: {
      totalWidth:
        (ticksStyleYAxis?.totalSize ?? 0) + (labelLayersTotalWidth ?? 0),
    },
    labelsBottom: {
      heightWithoutLegend: labelsWithoutLegend.totalHeight,
      heightWithLegend: labelsWithLegend.totalHeight,
    },
  };
}

function getLabel(legendLabel: LegendLabel): string {
  return legendLabel.customLabel ?? legendLabel.fullOriginalLabel;
}

export function calculateBottomLegend(
  maxWidth: number,
  legendHeader: string | undefined,
  labelStrings: LegendLabel[],
  baseLabelTextSize: number,
  textStyle: TextStyle
): LegendPositioningBottom | undefined {
  if (labelStrings.length === 0) {
    return;
  }

  const labels = labelStrings.map(
    (l) =>
      new MultilineText(getLabel(l), textStyle, {
        colorKey: l.colorKey,
        desiredMaxWidth: MAX_LABEL_WIDTH,
        boxPaddingTop: LEGEND_LABEL_HEIGHT_PADDING,
      })
  );
  const labelLengths = labels.map((l) => l.maxWidth);

  const maxLabelWidthTextOnly = _.max(labelLengths);
  if (!defined(maxLabelWidthTextOnly)) {
    return;
  }

  const columnPadding = 10;
  const maxLabelWidth =
    maxLabelWidthTextOnly +
    calculateTextWidth(LEGEND_COLOR_INDICATOR_CHAR, textStyle) +
    columnPadding;

  const numColumns = Math.max(1, Math.floor(maxWidth / maxLabelWidth));
  const remainingAvailableLineSpace =
    maxWidth - maxLabelWidth * Math.min(numColumns, labels.length);
  const marginLeft = Math.round(remainingAvailableLineSpace / 2);

  const horizontalLabels: TextContainerPositioned[] = [];
  let labelsAreaHeight = 25;

  let headerLabel: TextContainerPositioned | undefined;
  let headerHeight = 0;
  if (defined(legendHeader)) {
    const multilineText = new MultilineText(
      legendHeader,
      defaultDimensionLabelTextStyle(baseLabelTextSize),
      {
        desiredMaxWidth: maxWidth,
      }
    );
    const position: Position2D = { x: maxWidth / 2, y: labelsAreaHeight };
    headerLabel = new TextContainerPositioned(
      position,
      "middle",
      "center",
      multilineText,
      multilineText.style,
      {}
    );
    headerHeight += headerLabel.height + 10;
  }

  // If we have a small number of labels, we try to fit them all on one line.
  if (labels.length < 10) {
    const columnPadding = 15;
    const mockText = new TextContainerPositioned(
      { x: 0, y: 0 },
      "left",
      "top",
      labels[0],
      textStyle,
      {
        colorIndicator: {
          colorKey: labels[0].colorKey,
          content: LEGEND_COLOR_INDICATOR_CHAR,
          paddingRight: COLOR_INDICATOR_PADDING_RIGHT,
        },
      }
    );
    const requiredLabelSpace = sum(
      labels.map(
        (l, i) =>
          l.maxWidth + mockText.indicatorWidth + (i === 0 ? 0 : columnPadding)
      )
    );

    if (maxWidth > requiredLabelSpace) {
      let yOffset = headerHeight + 10;
      let xOffset = 0;
      const outerMargins = (maxWidth - requiredLabelSpace) / 2;
      const textContainers = labels.map((l, colIndex) => {
        const margin = colIndex === 0 ? outerMargins : 0;
        const container = new TextContainerPositioned(
          {
            x: xOffset + margin,
            y: yOffset,
          },
          "left",
          "top",
          l,
          textStyle,
          {
            paddingTop: 0,
            colorIndicator: {
              colorKey: l.colorKey,
              content: LEGEND_COLOR_INDICATOR_CHAR,
              paddingRight: COLOR_INDICATOR_PADDING_RIGHT,
            },
          }
        );

        xOffset += margin + container.width + columnPadding;
        return container;
      });

      const maxLabelHeight = max(textContainers.map((l) => l.height)) ?? 0;
      yOffset += maxLabelHeight;
      if (yOffset > labelsAreaHeight) {
        labelsAreaHeight = yOffset;
      }
      return {
        headerLabel,
        orientation: Orientation.horizontal,
        labels: textContainers,
        height: labelsAreaHeight,
      };
    }
  }

  // Generic algorithm for fitting labels into multiple columns.
  // Note that the columns are all the same width, which is not ideal.
  // The next step is to allow the columns to be different widths and fit them
  // together more nicely.
  let numLabelsPlaced = 0;
  for (let colIndex = 0; colIndex < numColumns; colIndex++) {
    let yOffset = headerHeight + 10;
    const startIndex = numLabelsPlaced;
    const numRemainingLabels = labels.length - numLabelsPlaced;
    const numRemainingCols = numColumns - colIndex;
    const numLabelsToTake = Math.ceil(numRemainingLabels / numRemainingCols);
    const currentColumnLabels = labels.slice(
      startIndex,
      startIndex + numLabelsToTake
    );

    for (
      let lineIndex = 0;
      lineIndex < currentColumnLabels.length;
      lineIndex++
    ) {
      const currentLabel = currentColumnLabels[lineIndex];
      const margin = lineIndex === 0 ? marginLeft : marginLeft;
      const textContainer = new TextContainerPositioned(
        {
          x: margin + colIndex * maxLabelWidth,
          y: yOffset,
        },
        "left",
        "top",
        currentLabel,
        textStyle,
        {
          paddingTop: 0,
          colorIndicator: {
            colorKey: currentLabel.colorKey,
            content: LEGEND_COLOR_INDICATOR_CHAR,
            paddingRight: COLOR_INDICATOR_PADDING_RIGHT,
          },
        }
      );
      horizontalLabels.push(textContainer);
      yOffset += textContainer.height;
      numLabelsPlaced += 1;
    }
    if (yOffset > labelsAreaHeight) {
      labelsAreaHeight = yOffset;
    }
  }

  return {
    headerLabel,
    orientation: Orientation.horizontal,
    labels: horizontalLabels,
    height: labelsAreaHeight,
  };
}

export function calculateSideLegend(
  maxWidth: number,
  maxHeight: number,
  legendHeader: string | undefined,
  labels: LegendLabel[],
  baseLabelTextSize: number,
  textStyle: TextStyle
): LegendPositioningSide | undefined {
  if (labels.length === 0 || maxWidth <= LEGEND_MIN_FEASIBLE_WIDTH) {
    return;
  }

  // Calculate indicator width
  const container = new TextContainerPositioned(
    { x: 0, y: 0 },
    "left",
    "top",
    new MultilineText("", textStyle, {
      colorKey: "abc",
      desiredMaxWidth: 100, // mock number, not relevant
    }),
    textStyle,
    {
      paddingTop: 0,
      colorIndicator: {
        colorKey: "abc",
        content: "⬤",
        paddingRight: COLOR_INDICATOR_PADDING_RIGHT,
      },
    }
  );
  const indicatorWidth = container.indicatorWidth;
  // END calc indicator width

  const desiredLabelMaxWidth = Math.min(
    MAX_LABEL_WIDTH,
    maxWidth - indicatorWidth
  );

  const multilineLabels = labels.map(
    (l) =>
      new MultilineText(getLabel(l), textStyle, {
        colorKey: l.colorKey,
        desiredMaxWidth: desiredLabelMaxWidth,
        boxPaddingTop: LEGEND_LABEL_HEIGHT_PADDING,
      })
  );

  const totalLabelsHeight = _.sum(multilineLabels.map((l) => l.height)) ?? 0;
  const remainingAvailableVerticalSpace = maxHeight - totalLabelsHeight;

  const labelLengths = labels.map(
    (l) =>
      calculateTextWidth(getLabel(l), textStyle) + LEGEND_LABEL_WIDTH_PADDING
  );
  const maxLabelWidth = _.max(labelLengths);
  if (!defined(maxLabelWidth)) {
    return;
  }

  const marginTop = Math.round(remainingAvailableVerticalSpace / 2);
  let offsetY = 0;

  let headerLabel: TextContainerPositioned | undefined;
  if (defined(legendHeader)) {
    const multilineText = new MultilineText(
      legendHeader,
      defaultDimensionLabelTextStyle(baseLabelTextSize),
      {
        desiredMaxWidth: maxWidth,
      }
    );
    const position: Position2D = { x: 0, y: marginTop };
    headerLabel = new TextContainerPositioned(
      position,
      "left",
      "top",
      multilineText,
      multilineText.style,
      {}
    );
    offsetY += headerLabel.height;
  }

  const verticalLabels: TextContainerPositioned[] = [];
  for (const label of multilineLabels) {
    const container = new TextContainerPositioned(
      { x: 0, y: marginTop + offsetY },
      "left",
      "top",
      label,
      textStyle,
      {
        paddingTop: 0,
        colorIndicator: {
          colorKey: label.colorKey,
          content: "⬤",
          paddingRight: COLOR_INDICATOR_PADDING_RIGHT,
        },
      }
    );
    verticalLabels.push(container);
    offsetY += container.height;
  }

  const width =
    Math.max(...verticalLabels.map((l) => l.width), headerLabel?.width ?? 0) ??
    0;

  return {
    headerLabel,
    orientation: Orientation.vertical,
    labels: verticalLabels,
    width,
  };
}

export function labelsToRows(
  labelRows: string[][],
  rowPaddingTop: number,
  rowPaddingTopFirst: number,
  textStyle: TextStyle,
  desiredMaxLabelWidthVerticalBars?: number
): LabelRowSet {
  const rows = labelRows.map((labels) => {
    const rowHeight =
      _.max(
        labels.map(
          (l) =>
            makeDefaultMultilineLabelVerticalBars(
              l,
              textStyle,
              desiredMaxLabelWidthVerticalBars
            ).height
        )
      ) ?? 0;
    return new LabelsRow(rowHeight, rowPaddingTop);
  });
  return new LabelRowSet(rows, rowPaddingTopFirst);
}

export function calculateLabelAreasLineChart(
  rangeLabelsRaw: YTicksContainer,
  legendLabels: string[] | undefined,
  ticksStyleYAxis: TicksStyle | undefined,
  ticksStyleXAxis: TicksStyle | undefined,
  textStyle: TextStyle
): LabelAreas {
  const rangeLabels = rangeLabelsRaw.ticks.map((t) => t.text);
  const bottomRows = labelsToRows([rangeLabels], 0, 0, textStyle);
  return {
    labelsRight: defined(legendLabels)
      ? { totalWidth: verticallyStackedLabelsToWidth(legendLabels, textStyle) }
      : undefined,
    labelsLeft: {
      totalWidth:
        (ticksStyleYAxis?.totalSize ?? 0) +
        verticallyStackedLabelsToWidth(rangeLabels, textStyle),
    },
    labelsBottom: {
      heightWithoutLegend:
        bottomRows.totalHeight + (ticksStyleXAxis?.totalSize ?? 0),
    },
  };
}
