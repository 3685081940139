import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { defined } from "../../../../../lib/core/defined";
import {
  getMicroPolygonDatasetDto,
  getMicroPointDatasetDto,
  getMicroLineDatasetDto,
  GeoDatasetFetcher,
} from "../../../../../lib/application/requests/datasets/micro";
import { assertDefined } from "../../../../../lib/core/assert";
import { BoundingBox } from "../../../../../lib/domain/cartography/BoundingBox";
import { MeasureSelectionGeoMicroFull } from "../../../../../lib/domain/measure/definitions";
import { availableDatesToMaximumSpan } from "../../../../../lib/domain/micro/timeSelection";
import { logger } from "../../../../../lib/infra/logging";
import { DocCardMicro } from "../../../../../lib/application/state/stats/document-core/core";
import {
  DrawableGroupSet,
  DrawableGroupSpec,
  DrawableGroupV2,
  DrawableGroupWithoutStyles,
  getDrawableStyle,
  makeDrawableGroupsV2,
} from "../../../../../lib/application/stats/datasets/MicroDatasetGeo";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { withoutUndefinedProperties } from "../../../../../lib/core/object";
import { flatMap, isEmpty, isEqual } from "lodash";
import { MAPBOX_LABELS_LAYER } from "./useMapSetup";

import { InfostatBoundingBox } from "../../../../../lib/domain/cartography/types";
import { microGeoSelectionsToGeocodes } from "../../../../../lib/application/state/stats/document-core/core-micro";
import { useSynchronizeState } from "../../../../../lib/application/hooks/useSynchronizeState";
import {
  microGeoLineSelectionsQuery,
  microGeoPointSelectionsQuery,
  microGeoPolygonSelectionsQuery,
  singleMicroCardQuery,
} from "../../../../../lib/application/state/stats/document-core/queries/microCard";
import { Progress } from "../../../../../lib/core/progress";
import { MicroMapState } from "../../../../../lib/application/state/stats/document-core/_core-shared";
import { useGetStylesGeoMicroByCardId } from "../../../../../lib/application/state/actions/micro/geoColors";
import {
  MicroLineDatasetDto,
  MicroPointDatasetDto,
  MicroPolygonDatasetDto,
} from "../../../../../lib/infra/api_responses/micro_dataset";
import { StyleContainerGeoMicro } from "../../../../../lib/application/state/stats/document-style/definitions";
import { useReadMicroCardState } from "../../../../../lib/application/state/actions/micro/updates";
import { CardUpdateCountContext } from "../../../../../lib/application/contexts";

type DrawableGroupsAndScreenMode = [DrawableGroupSet | undefined, boolean];
type GeoDataset<Dto> = [
  datasetDto: Dto,
  checksum: string,
  selection: MeasureSelectionGeoMicroFull
];

/**
 * Fetch and render point/polygon/line datasets
 */
export function useGeometricRendering(
  map: mapboxgl.Map | undefined,
  card: DocCardMicro,
  fullscreen: boolean,
  showAdminDraftData: boolean
) {
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const loadedPolygons = card.data.loadedData?.microMapState?.loadedPolygons;
  const loadedLines = card.data.loadedData?.microMapState?.loadedLines;
  const loadedPoints = card.data.loadedData?.microMapState?.loadedPoints;

  const getMicroStyles = useGetStylesGeoMicroByCardId(card.id);

  const pointMeasures = useRecoilValue(
    microGeoPointSelectionsQuery({ cardStateId: card.id })
  );
  const lineMeasures = useRecoilValue(
    microGeoLineSelectionsQuery({ cardStateId: card.id })
  );
  const polygonMeasures = useRecoilValue(
    microGeoPolygonSelectionsQuery({ cardStateId: card.id })
  );

  const setCard = useSetRecoilState(
    singleMicroCardQuery({ cardStateId: card.id })
  );

  const { getCurrentValue: getCurrentCount } = useContext(
    CardUpdateCountContext
  );

  const readCard = useReadMicroCardState(card.id);

  const mapSettings = card.data.settings.map;
  const currentGeocodes = useMemo(() => {
    const geoSelections = card.data.geoSelections;
    if (!defined(geoSelections)) {
      return [];
    }
    return microGeoSelectionsToGeocodes(geoSelections);
  }, [card.data.geoSelections]);

  const fetchDatasetsV2 = useCallback(
    <Dto>(
      mapLocationBounds: InfostatBoundingBox,
      showAdminDraftData: boolean,
      shouldAbort: () => boolean,
      measureSelections: MeasureSelectionGeoMicroFull[],
      getDatasetDto: GeoDatasetFetcher<Dto>
    ): Promise<GeoDataset<Dto>[] | null> => {
      // Return null if stopped
      if (measureSelections.length === 0) {
        return Promise.resolve([]);
      }
      return Promise.all(
        measureSelections
          .map((m) => {
            const timeSelection = availableDatesToMaximumSpan(m.availableDates);
            if (!defined(timeSelection) || !defined(m.measure)) {
              return;
            }
            return getDatasetDto(
              m.measure.id,
              timeSelection,
              m.selectedDimensions,
              BoundingBox.fromInfostatBoundingBox(mapLocationBounds),
              mapSettings.hideGeoObjectsOutsideSelection
                ? currentGeocodes
                : undefined,
              showAdminDraftData
            ).then((d) => [d, m] as const);
          })
          .filter(defined)
      ).then((datasetResults) => {
        if (shouldAbort()) {
          return null;
        }
        const datasets = datasetResults
          .map(([r, selection]) => {
            return r.match({
              ok: (d) => {
                const checksum = r.md5checksum();
                assertDefined(checksum);
                return [d, checksum, selection] as [
                  Dto,
                  string,
                  MeasureSelectionGeoMicroFull
                ];
              },
              err: (e) => {
                logger.error(e);
                return undefined;
              },
            });
          })
          .filter(defined);
        return datasets;
      });
    },
    [currentGeocodes, mapSettings.hideGeoObjectsOutsideSelection]
  );

  /**
   * Render/remove polygon data
   */
  useRenderGeometricResourceV2(
    card.id,
    map,
    loadedPolygons,
    polygonSetToFeatureCollectionV2,
    "polygon",
    fullscreen
  );

  /**
   * Render/remove line data
   */
  useRenderGeometricResourceV2(
    card.id,
    map,
    loadedLines,
    lineSetToFeatureCollection,
    "line",
    fullscreen
  );

  /**
   * Render/remove point data
   */
  useRenderGeometricResourceV2(
    card.id,
    map,
    loadedPoints,
    pointsToFeatureCollection,
    "point",
    fullscreen
  );

  // Fetch geometric shapes
  useEffect(() => {
    if (!defined(map)) {
      return;
    }

    // Outline:
    // Fetch geo datasets
    // Match them against the currently used sets
    // If there are new or changed sets, add them to the map

    const currentCount = getCurrentCount();
    const shouldAbort = () => getCurrentCount() > currentCount;
    const commonArgs = [
      card.data.mapLocationBounds,
      showAdminDraftData,
      shouldAbort,
    ] as const;

    Promise.all([
      fetchDatasetsV2(
        ...commonArgs,
        polygonMeasures,
        getMicroPolygonDatasetDto
      ),
      fetchDatasetsV2(...commonArgs, pointMeasures, getMicroPointDatasetDto),
      fetchDatasetsV2(...commonArgs, lineMeasures, getMicroLineDatasetDto),
    ]).then(([polygonSets, pointSets, lineSets]) => {
      if (shouldAbort()) {
        return;
      }

      const storedMicroStyles = isFirstLoad ? getMicroStyles() : undefined;

      setIsFirstLoad(false);
      const c = readCard();

      const groupsPolygon = getDrawableGeoFeatures(polygonSets, (geometry) => {
        if (!["MultiPolygon", "Polygon"].includes(geometry?.type)) {
          throw new Error("Invalid polygon geometry: " + geometry?.type);
        }
      });
      const groupsPoint = getDrawableGeoFeatures(pointSets, (geometry) => {
        if (geometry.type !== "Point") {
          throw new Error("Invalid point geometry: " + geometry?.type);
        }
      });
      const groupsLine = getDrawableGeoFeatures(lineSets, (geometry) => {
        if (!["LineString", "MultiLineString"].includes(geometry?.type)) {
          throw new Error("Invalid polygon geometry: " + geometry?.type);
        }
      });

      const existingGroupLookup: { [key: string]: DrawableGroupV2 } = {};
      for (const g of c.data.loadedData?.microMapState?.loadedLines ?? []) {
        existingGroupLookup[g.id] = g;
      }
      for (const g of c.data.loadedData?.microMapState?.loadedPoints ?? []) {
        existingGroupLookup[g.id] = g;
      }
      for (const g of c.data.loadedData?.microMapState?.loadedPolygons ?? []) {
        existingGroupLookup[g.id] = g;
      }

      const microLatentStylesPre = c.data.loadedData?.microLatentStyles ?? [];
      const updatedMicroMapState: MicroMapState = produceMicroMapState({
        groupsLine,
        groupsPoint,
        groupsPolygon,
        storedMicroStyles,
        existingGroupLookup,
        microLatentStylesPre,
      });

      const microLatentStylesFiltered = microLatentStylesPre.filter((s) => {
        // Ensure latent styles are not added as regular style
        switch (s.type) {
          case "point":
            return !(
              updatedMicroMapState.loadedPoints?.some((l) => l.id === s.id) ??
              false
            );
          case "line":
            return !(
              updatedMicroMapState.loadedLines?.some((l) => l.id === s.id) ??
              false
            );
          case "polygon":
            return !(
              updatedMicroMapState.loadedPolygons?.some((l) => l.id === s.id) ??
              false
            );
        }
      });

      // Set styles for all drawable groups

      if (isEmpty(updatedMicroMapState)) {
        setCard(c);
      } else {
        const removedPointGroups =
          c.data.loadedData?.microMapState?.loadedPoints
            ?.filter(
              (p) =>
                !updatedMicroMapState.loadedPoints?.some((l) => l.id === p.id)
            )
            .map(drawableGroupToSpec) ?? [];
        const removedLineGroups =
          c.data.loadedData?.microMapState?.loadedLines
            ?.filter(
              (p) =>
                !updatedMicroMapState.loadedLines?.some((l) => l.id === p.id)
            )
            .map(drawableGroupToSpec) ?? [];
        const removedPolygonGroups =
          c.data.loadedData?.microMapState?.loadedPolygons
            ?.filter(
              (p) =>
                !updatedMicroMapState.loadedPolygons?.some((l) => l.id === p.id)
            )
            .map(drawableGroupToSpec) ?? [];

        const latentStylesFinal = [
          ...microLatentStylesFiltered,
          ...removedPointGroups,
          ...removedLineGroups,
          ...removedPolygonGroups,
        ];
        setCard({
          ...c,
          data: {
            ...c.data,
            loadedData: {
              ...c.data.loadedData,
              microLatentStyles: latentStylesFinal,
              microMapState: {
                ...c.data.loadedData?.microMapState,
                ...updatedMicroMapState,
              },
            },
          },
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    card.data.mapLocationBounds,
    map,
    lineMeasures,
    pointMeasures,
    polygonMeasures,
    showAdminDraftData,
    fetchDatasetsV2,
    setCard,
    // isFirstLoad -- since this is set from with the function, we can't react to it or we'll get into a loop
    getMicroStyles,
  ]);

  const layerIds = useMemo(() => {
    const tempIds: string[] = [];
    for (const group of loadedPoints ?? []) {
      tempIds.push(group.id);
    }
    for (const group of loadedLines ?? []) {
      tempIds.push(group.id);
    }

    for (const group of loadedPolygons ?? []) {
      tempIds.push(group.id);
      // Polygons have both fill layers and line layers
      tempIds.push(getPolygonFillLayerId(group.id));
      // ... and fat line layers for interaction
      tempIds.push(getPolygonFatLineLayerId(group.id));
    }

    return tempIds;
  }, [loadedPoints, loadedLines, loadedPolygons]);

  return layerIds;
}

function getGroupsWithStyleInfo(
  groups: DrawableGroupWithoutStyles[],
  storedMicroStyles: StyleContainerGeoMicro | undefined,
  microLatentStyles: DrawableGroupSpec[],
  currentStylesMut: DrawableGroupV2["style"][],
  existingGroupLookup: { [key: string]: DrawableGroupV2 }
) {
  const groupsWithStyles: DrawableGroupV2[] = [];
  const groupsWithMissingStyles: DrawableGroupWithoutStyles[] = [];

  for (const g of groups) {
    if (defined(storedMicroStyles)) {
      const found = storedMicroStyles.styles.find(
        (s) => s.dataGroupId === g.id
      );
      if (defined(found)) {
        const foundStyle: DrawableGroupV2["style"] = {
          label: found.label,
          lineDashArray: found.lineDashArray,
          fill: found.fill,
          border: found.border,
          fillOpacity: found.fillOpacity,
        };
        groupsWithStyles.push({ ...g, style: foundStyle });
        currentStylesMut.push(foundStyle);
        continue;
      }
    }
    const existingGroup = existingGroupLookup?.[g.id];
    if (!defined(existingGroup)) {
      const latentStyle = microLatentStyles.find((l) => l.id === g.id);
      if (defined(latentStyle)) {
        groupsWithStyles.push({ ...g, style: latentStyle.style });
        currentStylesMut.push(latentStyle.style);
      } else {
        groupsWithMissingStyles.push(g);
      }
    } else {
      groupsWithStyles.push({ ...g, style: existingGroup.style });
      currentStylesMut.push(existingGroup.style);
    }
  }

  return [groupsWithStyles, groupsWithMissingStyles] as const;
}

/**
 * Get the modifications between two lists of datasets
 * @param prev
 * @param next
 * @returns
 */
function getModificationsV2(
  prev: DrawableGroupsAndScreenMode | undefined,
  next: DrawableGroupsAndScreenMode | undefined
): {
  removed: DrawableGroupV2[];
  changed: DrawableGroupV2[];
  added: DrawableGroupV2[];
} {
  const prevGroups = prev?.[0] ?? [];
  const prevFullscreenMode = prev?.[1] ?? false;
  const nextGroups = next?.[0] ?? [];
  const nextFullscreenMode = next?.[1] ?? false;

  // On fullscreen mode change, the map is recreated and thus we nede to add all datasets
  // again from scratch
  if (prevFullscreenMode !== nextFullscreenMode) {
    return {
      removed: [],
      changed: [],
      added: flatMap(nextGroups),
    };
  }

  const prevLookup: { [key: string]: DrawableGroupV2 } = {};
  for (const g of prevGroups) {
    prevLookup[g.id] = g;
  }

  const nextLookup: { [key: string]: DrawableGroupV2 } = {};
  for (const g of nextGroups) {
    nextLookup[g.id] = g;
  }

  const removed: DrawableGroupV2[] = [];
  for (const group of prevGroups) {
    if (nextLookup[group.id]) {
      continue;
    }

    removed.push(group);
  }

  const changed: DrawableGroupV2[] = [];
  const added: DrawableGroupV2[] = [];
  for (const group of nextGroups) {
    const prev = prevLookup[group.id];
    if (!prev) {
      added.push(group);
    }

    if (
      defined(prev) &&
      (prev.datasetChecksum !== group.datasetChecksum ||
        !isEqual(prev.style, group.style))
    ) {
      changed.push(group);
    }
  }

  return { removed, changed, added };
}

function polygonSetToFeatureCollectionV2(
  group: DrawableGroupV2
): GeoJSON.FeatureCollection {
  return geoFeatureSetToFeatureCollection(group);
}

function lineSetToFeatureCollection(
  group: DrawableGroupV2
): GeoJSON.FeatureCollection {
  return geoFeatureSetToFeatureCollection(group);
}

function pointsToFeatureCollection(
  group: DrawableGroupV2
): GeoJSON.FeatureCollection {
  return geoFeatureSetToFeatureCollection(group);
}

function geoFeatureSetToFeatureCollection(
  group: DrawableGroupV2
): GeoJSON.FeatureCollection {
  return {
    type: "FeatureCollection",
    features: group.items.map(
      (p) =>
        ({
          type: "Feature",
          geometry: p.geometry,
          properties: {
            type: "micro-geo",
            label: p.label,
            infoLines: p.infoLines,
            value: p.value,
            unit: p.unit,
          },
        } as const)
    ),
  };
}

function useRenderGeometricResourceV2(
  cardId: string,
  map: mapboxgl.Map | undefined,
  drawableGroupSets: DrawableGroupSet | undefined,
  groupToFeatureCollection: (g: DrawableGroupV2) => GeoJSON.FeatureCollection,
  geometryType: "point" | "polygon" | "line",
  fullscreen: boolean
) {
  const [fullscreenMode, setFullscreenMode] = useState(false);

  /**
   * After toggling fullscreen mode, we need to re-render geometric measures
   * because the map is recreated. Give the map a second to load before re-rendering.
   * Note that we do _not_ use useStateTransition here because it becomes unreliable in
   * combination with timed functions. It executes only once per transition, but our timeout function
   * may be cancelled due to re-rendering and we must thus run it again, even if our state appears to not
   * have changed.
   */
  useSynchronizeState(fullscreen, fullscreenMode, setFullscreenMode, 1500);

  const drawableGroupsAndScreenMode: DrawableGroupsAndScreenMode =
    useMemo(() => {
      return [drawableGroupSets, fullscreenMode];
    }, [drawableGroupSets, fullscreenMode]);

  const [lastRendered, setLastRendered] =
    useState<DrawableGroupsAndScreenMode>();

  useEffect(() => {
    if (!defined(map)) {
      return;
    }
    const { removed, changed, added } = getModificationsV2(
      lastRendered,
      drawableGroupsAndScreenMode
    );

    for (const removedGroup of removed) {
      const groupId = removedGroup.id;
      const layer = map.getLayer(groupId);
      if (defined(layer)) {
        map.removeLayer(groupId);
      }
      const polygonFillLayerId = getPolygonFillLayerId(groupId);
      const polygonFatLineLayerId = getPolygonFatLineLayerId(groupId);
      const polygonFatLineLayer = map.getLayer(polygonFatLineLayerId);
      if (defined(polygonFatLineLayer)) {
        map.removeLayer(polygonFatLineLayerId);
      }
      const polygonFillLayer = map.getLayer(polygonFillLayerId);
      if (defined(polygonFillLayer)) {
        map.removeLayer(polygonFillLayerId);
      }
      const source = map.getSource(groupId);
      if (defined(source)) {
        map.removeSource(groupId);
      }
    }

    for (const group of changed) {
      const sourceId = group.id;
      const source = map.getSource(sourceId);
      const data = groupToFeatureCollection(group);
      if (!defined(source)) {
        logger.warn(
          `Source ${sourceId} not found, was expected because dataset has only changed.`
        );
        continue;
      }
      if (source.type !== "geojson") {
        throw new Error("Unexpected source type");
      }

      source.setData(data);
      setDrawableGroupColor(map, group);
    }

    for (const group of added) {
      const sourceId = group.id;
      const fill = group.style?.fill;
      const stroke = group.style?.border;

      let layers: mapboxgl.AnyLayer[] = makeGroupLayers(
        geometryType,
        sourceId,
        fill,
        group.style.fillOpacity,
        stroke,
        group.style.lineDashArray
      );
      try {
        map.addSource(sourceId, {
          type: "geojson",
          data: groupToFeatureCollection(group),
        });
      } catch (e) {
        return logger.error("Could not add source", e);
      }
      for (const layer of layers) {
        try {
          map.addLayer(layer, MAPBOX_LABELS_LAYER);
        } catch (e) {
          return logger.error("Could not add layer", e);
        }
      }

      setDrawableGroupColor(map, group);
    }

    setLastRendered(drawableGroupsAndScreenMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    drawableGroupsAndScreenMode,
    geometryType,
    groupToFeatureCollection,
    // lastRendered, // Ignore this, it's just a cache
    map,
  ]);
}

function setDrawableGroupColor(map: mapboxgl.Map, group: DrawableGroupV2) {
  const layer = map.getLayer(group.id);
  if (!defined(layer)) {
    return;
  }

  // Polygons have two layers, one for border and one for fill.
  // We need to explicitly add the fill layer here.
  if (group.type === "polygon") {
    const fillLayer = map.getLayer(getPolygonFillLayerId(group.id));
    if (defined(fillLayer)) {
      const fill = group.style?.fill;
      const fillOpacity = group.style?.fillOpacity;
      const currentFillColor = map.getPaintProperty(fillLayer.id, "fill-color");
      const currentFillOpacity = map.getPaintProperty(
        fillLayer.id,
        "fill-opacity"
      );
      if (defined(fill)) {
        if (fill !== currentFillColor) {
          map.setPaintProperty(fillLayer.id, "fill-color", fill);
          map.setLayoutProperty(fillLayer.id, "visibility", "visible");
        }
        if (defined(fillOpacity)) {
          if (fillOpacity !== currentFillOpacity) {
            map.setPaintProperty(fillLayer.id, "fill-opacity", fillOpacity);
          }
        } else {
          map.setPaintProperty(fillLayer.id, "fill-opacity", 1);
        }
      } else {
        map.setPaintProperty(fillLayer.id, "fill-color", "transparent");
      }
    }
  }

  if (layer.type === "fill") {
    const fill = group.style?.fill;
    const currentFillColor = map.getPaintProperty(group.id, "fill-color");
    if (defined(fill) && fill !== currentFillColor) {
      map.setPaintProperty(group.id, "fill-color", fill);
      map.setLayoutProperty(group.id, "visibility", "visible");
    } else {
      map.setPaintProperty(group.id, "fill-color", "transparent");
    }
    const stroke = group.style?.border;
    const currentFillOutline = layer.paint?.["fill-outline-color"];
    if (defined(stroke) && stroke !== currentFillOutline) {
      map.setPaintProperty(group.id, "fill-outline-color", stroke);
      map.setLayoutProperty(group.id, "visibility", "visible");
    } else {
      map.setPaintProperty(group.id, "fill-outline-color", "transparent");
    }
  } else if (layer.type === "line") {
    const lineDashArray = group.style.lineDashArray;
    const border = group.style?.border;
    const fill = group.style?.fill;

    if (group.type === "polygon") {
      // Polygons have special border handling
      const currentLineColor = map.getPaintProperty(group.id, "line-color");
      const borderChanged = border !== currentLineColor;
      if (borderChanged) {
        if (defined(border)) {
          map.setPaintProperty(group.id, "line-color", border);
          map.setLayoutProperty(group.id, "visibility", "visible");
        } else {
          map.setPaintProperty(group.id, "line-color", "transparent");
        }
      }

      if (defined(border)) {
        const currentLineDashArray = map.getPaintProperty(
          group.id,
          "line-dasharray"
        );
        if (!isEqual(lineDashArray, currentLineDashArray)) {
          if (!defined(lineDashArray)) {
            map.setPaintProperty(group.id, "line-dasharray", [1, 0]); // FIXME
          } else {
            map.setPaintProperty(group.id, "line-dasharray", lineDashArray);
          }
        }
      }
    } else if (group.type === "line") {
      // Lines have fill only
      const currentLineColor = map.getPaintProperty(group.id, "line-color");
      const fillChanged = fill !== currentLineColor;
      if (fillChanged) {
        if (defined(fill)) {
          map.setPaintProperty(group.id, "line-color", fill);
          map.setLayoutProperty(group.id, "visibility", "visible");
        } else {
          map.setPaintProperty(group.id, "line-color", "transparent");
        }
      }

      if (defined(fill)) {
        const currentLineDashArray = map.getPaintProperty(
          group.id,
          "line-dasharray"
        );
        if (!isEqual(lineDashArray, currentLineDashArray)) {
          if (!defined(lineDashArray)) {
            map.setPaintProperty(group.id, "line-dasharray", [1, 0]); // FIXME
          } else {
            map.setPaintProperty(group.id, "line-dasharray", lineDashArray);
          }
        }
      }
    }
  } else if (layer.type === "circle") {
    const fill = group.style?.fill;
    const currentCircleColor = layer.paint?.["circle-color"];
    if (defined(fill) && fill !== currentCircleColor) {
      map.setPaintProperty(group.id, "circle-color", fill);
      map.setLayoutProperty(group.id, "visibility", "visible");
    } else if (!defined(fill)) {
      map.setPaintProperty(group.id, "circle-color", "transparent");
    }
    const fillOpacity = group.style.fillOpacity;
    const currentFillOpacity = map.getPaintProperty(group.id, "circle-opacity");
    if (defined(fillOpacity)) {
      if (fillOpacity !== currentFillOpacity) {
        map.setPaintProperty(group.id, "circle-opacity", fillOpacity);
      }
    } else if (currentFillOpacity !== 1) {
      map.setPaintProperty(group.id, "circle-opacity", 1);
    }

    const stroke = group.style?.border;
    const currentCircleStrokeColor = layer.paint?.["circle-stroke-color"];
    if (defined(stroke) && stroke !== currentCircleStrokeColor) {
      map.setPaintProperty(group.id, "circle-stroke-color", stroke);
      map.setLayoutProperty(group.id, "visibility", "visible");
    } else if (!defined(stroke)) {
      map.setPaintProperty(group.id, "circle-stroke-color", "transparent");
    }
  }
}

function makeGroupLayers(
  geometryType: string,
  sourceId: string,
  fill: string | undefined,
  fillOpacity: number | undefined,
  stroke: string | undefined,
  lineDashArray: number[] | undefined
): mapboxgl.AnyLayer[] {
  if (geometryType === "point") {
    return [
      {
        id: sourceId,
        type: "circle",
        source: sourceId,
        paint: withoutUndefinedProperties({
          "circle-color": fill,
          "circle-stroke-color": stroke,
          "circle-opacity": fillOpacity,
        }),
        layout: {
          visibility: defined(fill) ? "visible" : "none",
        },
      },
    ];
  } else if (geometryType === "polygon") {
    const layers: mapboxgl.AnyLayer[] = [
      {
        id: sourceId,
        type: "line",
        source: sourceId,
        paint: withoutUndefinedProperties({
          "line-width": 2,
          "line-color": stroke,
          "line-dasharray": lineDashArray,
        }),
        layout: {
          visibility: defined(stroke) ? "visible" : "none",
        },
      },
      {
        id: getPolygonFillLayerId(sourceId),
        type: "fill",
        source: sourceId,
        paint: withoutUndefinedProperties({
          "fill-color": fill ?? "transparent",
          "fill-opacity": fillOpacity,
        }),
      },
    ];

    if (sourceId !== "deso" && sourceId !== "regso") {
      layers.push({
        id: getPolygonFatLineLayerId(sourceId),
        type: "line",
        source: sourceId,
        paint: {
          "line-width": 30,
          "line-opacity": 0,
        },
      });
    }

    return layers;
  } else if (geometryType === "line") {
    return [
      {
        id: sourceId,
        type: "line",
        source: sourceId,
        paint: withoutUndefinedProperties({
          "line-width": 2,
          "line-dasharray": lineDashArray,
          "line-color": fill,
        }),
        layout: {
          visibility: defined(fill) ? "visible" : "none",
        },
      },
    ];
  } else {
    throw new Error("Unexpected geometry type");
  }
}

/**
 * Polygon layers have both fill layers and line layers, so that we can more
 * finely tune the appearance of strokes. The fill layers just act as something
 * to click on, so we don't want to show them.
 *
 * This function returns the layer ID containing fills corresponding to a line layer.
 */
function getPolygonFillLayerId(lineLayerId: string): string {
  return lineLayerId + "-fill";
}

/**
 * Polygon layers have fat outline layers, so that we can
 * base interactions on the edges of the polygon, but with a tolerance threshold.
 */
function getPolygonFatLineLayerId(lineLayerId: string): string {
  return lineLayerId + "-fat-lines";
}

/**
 * Calculate all the drawable groups for fetched datasets.
 */
function getDrawableGeoFeatures(
  datasets:
    | GeoDataset<
        MicroPointDatasetDto | MicroPolygonDatasetDto | MicroLineDatasetDto
      >[]
    | null,
  assertGeometry: (geometry: GeoJSON.Geometry) => void
): DrawableGroupWithoutStyles[] {
  if (datasets === null) {
    return [];
  }

  const groups: DrawableGroupWithoutStyles[] = [];
  for (const d of datasets) {
    const [dto, checksum, measureSelection] = d;
    const baseId = `${measureSelection.id}-${measureSelection.measure.id}`;

    const drawableGroups = makeDrawableGroupsV2(
      dto,
      measureSelection.subjectPath,
      baseId,
      checksum,
      measureSelection.measure.dimensions ?? [],
      assertGeometry
    );

    for (const group of drawableGroups) {
      groups.push(group);
    }
  }

  return groups;
}

function produceMicroMapState(args: {
  groupsLine: DrawableGroupWithoutStyles[];
  groupsPoint: DrawableGroupWithoutStyles[];
  groupsPolygon: DrawableGroupWithoutStyles[];
  /** micro styles stored in the document in db, only relevant for first render */
  storedMicroStyles: StyleContainerGeoMicro | undefined;
  existingGroupLookup: { [key: string]: DrawableGroupV2 };
  /** Styles that are currently not used but may be used */
  microLatentStylesPre: DrawableGroupSpec[];
}): MicroMapState {
  // Keeps track of current/used styles so we can decide what style to use next,
  // for new groups that don't have a style yet.
  const currentStylesMut: DrawableGroupV2["style"][] = [];
  const {
    groupsLine,
    groupsPoint,
    groupsPolygon,
    storedMicroStyles,
    existingGroupLookup,
    microLatentStylesPre,
  } = args;

  const getGroups = (groups: DrawableGroupWithoutStyles[]) =>
    getGroupsWithStyleInfo(
      groups,
      storedMicroStyles,
      microLatentStylesPre,
      currentStylesMut,
      existingGroupLookup
    );

  const [lineGroups, lineGroupsWithoutStyles] = getGroups(groupsLine);
  const [pointGroups, pointGroupsWithoutStyles] = getGroups(groupsPoint);
  const [polygonGroups, polygonGroupsWithoutStyles] = getGroups(groupsPolygon);

  const getFinalGroups = (
    currentGroupsWithStyles: DrawableGroupV2[],
    currentGroupsWithoutStyles: DrawableGroupWithoutStyles[]
  ) => {
    const finalGroups = currentGroupsWithStyles.slice();
    for (const g of currentGroupsWithoutStyles) {
      const style = getDrawableStyle(currentStylesMut, g);
      currentStylesMut.push(style);
      finalGroups.push({ ...g, style });
    }
    if (finalGroups.length === 0) {
      return undefined;
    }
    return finalGroups;
  };

  const finalLineGroups = getFinalGroups(lineGroups, lineGroupsWithoutStyles);
  const finalPointGroups = getFinalGroups(
    pointGroups,
    pointGroupsWithoutStyles
  );
  const finalPolygonGroups = getFinalGroups(
    polygonGroups,
    polygonGroupsWithoutStyles
  );

  const updatedMicroMapState: MicroMapState = {
    loadedLines: finalLineGroups,
    loadedLinesProgress: Progress.Success,
    loadedPoints: finalPointGroups,
    loadedPointsProgress: Progress.Success,
    loadedPolygons: finalPolygonGroups,
    loadedPolygonsProgress: Progress.Success,
  };
  return updatedMicroMapState;
}

function drawableGroupToSpec(obj: DrawableGroupV2): DrawableGroupSpec {
  return {
    breakdownCombination: obj.breakdownCombination,
    id: obj.id,
    datasetChecksum: obj.datasetChecksum,
    label: obj.label,
    measurePath: obj.measurePath,
    style: obj.style,
    type: obj.type,
  };
}
