import { Checkbox, TextField } from "@fluentui/react";
import { sortBy } from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";

import { Button } from "../../../../../components/Button";
import { InfoBox } from "../../../../../components/InfoBox";
import { DefaultLoading } from "../../../../../components/Loading";
import {
  AppMessagesContext,
  CategoriesContext,
} from "../../../../../lib/application/contexts";
import { getOrganizationsWithCache } from "../../../../../lib/application/requests/admin/common_requests_admin";
import {
  getMicroMeasureMetadata,
  retireMicroMeasure,
  saveEnabledComputedMeasures,
  saveMicroMeasureMetadata,
  saveMicroMeasureMetadataMultiField,
  saveMicroOrgAccessRestriction,
  unretireMicroMeasure,
} from "../../../../../lib/application/requests/admin/measure_management/micro/metadata";
import { defined } from "../../../../../lib/core/defined";
import { Progress } from "../../../../../lib/core/progress";
import { AdminMetadataMicroDto } from "../../../../../lib/domain-admin/metadata";
import { validResolutions } from "../../../../../lib/domain/time";
import { OrganizationResponse } from "../../../../../lib/infra/api_responses/admin/organizations";
import {
  aggregationMethods,
  dataValueTypesMicroGeo,
  microValueTypes,
} from "../../../../../lib/infra/api_responses/dataset";
import {
  displayHttpErrorInternal,
  HttpResult,
} from "../../../../../lib/infra/HttpResult";
import { logger } from "../../../../../lib/infra/logging";
import { SimpleSubjectSelect } from "../../../../admin/views_data_admin/shared/SimpleSubjectSelect";
import {
  OrgAccessEditor,
  ORG_ACCESS_FIELD_LABEL,
  DataFieldDef,
  DataFields,
} from "./shared";
import { TagsEditor } from "./TagsEditor";
import { getMicroCategoriesWithCache } from "../../../../../lib/application/requests/datasets/micro";
import { editMicroDimension } from "../../../../../lib/application/requests/admin/measure_management/edit";
import { FluentModal, FluentModalBody } from "../../../../../components/Modal";
import { DimensionsEditorV2 } from "../../../../admin/views_data_admin/shared/DimensionsEditorV2";
import { Categories } from "../../../../../lib/domain/categories";
import { DimensionV2Dto } from "../../../../../lib/domain/measure/definitions";
import {
  ALL_COMPUTED_MEASURE_TYPES,
  ComputedMeasurementType,
  COMPUTED_TYPE_LABELS,
} from "../../../../../lib/infra/api_responses/micro_dataset";
import { voidFunc } from "../../../../../lib/core/voidFunc";
import { arraysContainSameStrings } from "../../../../../lib/core/arraysContainSameStrings";
import {
  useLoadMicroDimensions,
  useSaveDimensionsOrder,
} from "../../../../../lib/application_admin/hooks/dimensions";

import "./MetadataAdmin.scss";

const fields: DataFieldDef<AdminMetadataMicroDto>[] = [
  { key: "label", editable: false, type: "input" },
  { key: "unit_label", editable: true, type: "input" },
  { key: "public_comment", editable: true, type: "textarea" },
  { key: "mikro_id", editable: false, type: "int-input" },
  { key: "last_update", editable: true, type: "date", deletable: true },
  { key: "next_update", editable: true, type: "date", deletable: true },
  {
    key: "agg_method_geo",
    editable: true,
    type: "select-single",
    options: aggregationMethods.map((m) => ({ key: m, text: m })),
  },
  {
    key: "resolution",
    editable: true,
    type: "select-single",
    options: validResolutions.map((r) => ({ key: r, text: r })),
  },
  { key: "measure", editable: true, type: "input" },
  { key: "descr_long", editable: true, type: "input" },
  { key: "ext_descr", editable: true, type: "input" },
  { key: "ext_descr_long", editable: true, type: "input" },
  { key: "ext_source", editable: true, type: "input" },
  { key: "source", editable: true, type: "input" },
  { key: "source_url", editable: true, type: "input" },
];

interface Props {
  measureId?: number;
}
export function MetadataAdminMicro(props: Props) {
  const { measureId } = props;
  const [metadata, setMetadata] = useState<AdminMetadataMicroDto>();
  const [progress, setProgress] = useState(Progress.NotStarted);
  const [isEditingSubjectPath, setIsEditingSubjectPath] = useState(false);
  const [isEditingOrgAccess, setIsEditingOrgAccess] = useState(false);
  const [isEditingDimensions, setIsEditingDimensions] = useState(false);

  const [organizations, setOrganizations] = useState<OrganizationResponse>();
  const [dimensions, setDimensions] = useState<DimensionV2Dto[]>();
  const appMessagesHandler = useContext(AppMessagesContext);
  const [categories, setCategories] = useState<Categories>();

  useEffect(() => {
    getMicroCategoriesWithCache(true, ["deso", "regso"], microValueTypes).then(
      (res) => {
        res.match({
          ok: (categories) => {
            setCategories(categories ?? []);
          },
          err: (err) => {
            logger.error(err);
            appMessagesHandler?.add(
              "error",
              "Kunde inte hamta kategorier" + displayHttpErrorInternal(err)
            );
          },
        });
      }
    );
  }, [appMessagesHandler]);

  const loadDimensions = useLoadMicroDimensions(setDimensions, measureId, true);

  useEffect(() => {
    if (!defined(measureId)) {
      return;
    }
    loadDimensions();
  }, [loadDimensions, measureId]);

  const handleSaveDimensionsOrder = useSaveDimensionsOrder(loadDimensions);

  useEffect(() => {
    getOrganizationsWithCache().then((res) =>
      setOrganizations(sortBy(res, "name"))
    );
  }, []);

  const loadMetadata = useCallback(() => {
    if (!defined(measureId)) {
      return;
    }
    setProgress(Progress.InProgress);
    getMicroMeasureMetadata(measureId)
      .then((data) => {
        data.match({
          ok: (res) => {
            setMetadata(res);
            setProgress(Progress.Success);
          },
          err: (err) => {
            logger.error(err);
            appMessagesHandler?.add("error", "Kunde inte hämta metadata");
            setProgress(Progress.Error);
          },
        });
      })
      .catch(() => {
        setProgress(Progress.Error);
      });
  }, [appMessagesHandler, measureId]);

  useEffect(() => {
    loadMetadata();
  }, [loadMetadata, measureId]);

  const handleSaveTags = useCallback(
    (id: number, tags: string[]) => {
      return saveMicroMeasureMetadata(id, "tags", tags).then((res) => {
        res.match({
          ok: () => {
            appMessagesHandler?.add("success", "Taggar sparade");
            loadMetadata();
          },
          err: (err) => {
            logger.error(err);
            appMessagesHandler?.add("error", "Kunde inte spara taggar");
          },
        });
        return res;
      });
    },
    [appMessagesHandler, loadMetadata]
  );

  const handleSaveOrgAccess = useCallback(
    (orgIds: string[]) => {
      if (orgIds.length === 0) {
        alert("Måttet kommer göras publikt. Fortsätt?");
      }
      if (!defined(measureId)) {
        appMessagesHandler?.add("error", "Inget  Mått-ID hittat");
        return Promise.resolve(HttpResult.fromOk(null));
      }
      return saveMicroOrgAccessRestriction(
        measureId,
        orgIds.length === 0
          ? { restricted: false }
          : { restricted: true, organizations: orgIds }
      ).then((res) => {
        res.match({
          ok: () => {
            setIsEditingOrgAccess(false);
            loadMetadata();
          },
          err: (err) => {
            logger.error(err);
            appMessagesHandler?.add("error", "Kunde inte spara");
          },
        });
        return res;
      });
    },
    [appMessagesHandler, loadMetadata, measureId]
  );

  const handleSaveEnabledComputedMeasures = useCallback(
    (enabledComputedMeasures: ComputedMeasurementType[]) => {
      if (!defined(measureId)) {
        throw new Error("measureId not defined");
      }
      return saveEnabledComputedMeasures(
        measureId,
        enabledComputedMeasures
      ).then((res) => {
        res.match({
          ok: voidFunc,
          err: (e) =>
            appMessagesHandler?.add(
              "error",
              "Kunde inte spara! " + displayHttpErrorInternal(e)
            ),
        });
        return res;
      });
    },
    [appMessagesHandler, measureId]
  );

  const handleRetireMeasure = useCallback(
    (mId: number) => {
      retireMicroMeasure(mId)
        .then((res) => {
          res.match({
            ok: () => {
              appMessagesHandler?.add("success", "Mått pensionerat");
              loadMetadata();
            },
            err: (err) => {
              logger.error(err);
              appMessagesHandler?.add(
                "error",
                "Kunde inte pensionera mått: " + displayHttpErrorInternal(err)
              );
            },
          });
        })
        .catch((e) => {
          logger.error(e);
          appMessagesHandler?.add("error", e);
        });
    },
    [appMessagesHandler, loadMetadata]
  );

  const handleUnretireMeasure = useCallback(
    (mId: number) => {
      unretireMicroMeasure(mId).then((res) => {
        res.match({
          ok: () => {
            appMessagesHandler?.add("success", "Mått avpensionerat");
            loadMetadata();
          },
          err: (err) => {
            logger.error(err);
            appMessagesHandler?.add(
              "error",
              "Kunde inte avpensionera mått: " + displayHttpErrorInternal(err)
            );
          },
        });
      });
    },
    [appMessagesHandler, loadMetadata]
  );

  const handleEditDimension = useCallback(
    (
      dimensionId: number,
      dimension: DimensionV2Dto
    ): Promise<HttpResult<void>> => {
      if (dimension.type !== "mikro_breakdown") {
        throw new Error("Invalid dimension type");
      }
      return editMicroDimension(dimensionId, dimension as DimensionV2Dto);
    },
    []
  );

  if (!defined(measureId)) {
    return <InfoBox mainHeader="Inget mått valt" sections={[]}></InfoBox>;
  }

  if (progress === Progress.NotStarted || progress === Progress.InProgress) {
    return (
      <div className="admin-measure-metadata">
        <DefaultLoading></DefaultLoading>
      </div>
    );
  }

  if (!defined(metadata) || progress === Progress.Error) {
    return (
      <div className="admin-measure-metadata">
        <h2>Oklart fel!</h2>
      </div>
    );
  }

  if (!defined(categories)) {
    return <DefaultLoading></DefaultLoading>;
  }

  return (
    <CategoriesContext.Provider value={categories}>
      <div className="admin-measure-metadata content-padding-default">
        <h2>Metadata</h2>

        {isEditingSubjectPath ? (
          <SimpleSubjectSelect
            defaultPath={[metadata.area, metadata.subarea, metadata.subject]}
            onCancel={() => setIsEditingSubjectPath(false)}
            submitText="Spara"
            onSubmit={(path) => {
              setIsEditingSubjectPath(false);
              const [area, subarea, subject] = path;
              if (!defined(area) || !defined(subarea) || !defined(subject)) {
                throw new Error(
                  "Area, subarea or subject is undefined! Path: " + path
                );
              }
              return saveMicroMeasureMetadataMultiField(measureId, {
                area,
                subarea,
                subject,
              }).then((res) => {
                return res.unwrap();
              });
            }}
          ></SimpleSubjectSelect>
        ) : (
          <div className="editable-field subject-path">
            <div>
              <div className="label">
                <label>{`area > subarea > subject`}</label>
              </div>
              <TextField
                disabled
                readOnly
                value={` ${metadata.area} > ${metadata.subarea} > ${metadata.subject} `}
              ></TextField>
            </div>
            <Button
              intent="primary"
              title="Redigera"
              onClick={() => setIsEditingSubjectPath(true)}
            ></Button>
          </div>
        )}

        {!defined(organizations) ? (
          <DefaultLoading></DefaultLoading>
        ) : isEditingOrgAccess ? (
          <OrgAccessEditor
            organizations={organizations}
            selected={metadata.organization_access_restrictions ?? []}
            handleCancel={() => setIsEditingOrgAccess(false)}
            handleSave={handleSaveOrgAccess}
          ></OrgAccessEditor>
        ) : (
          <div className="editable-field org-access">
            <div>
              <div className="label">
                <label>{ORG_ACCESS_FIELD_LABEL}</label>
              </div>
              <TextField
                disabled
                readOnly
                value={
                  (metadata.organization_access_restrictions ?? []).length === 0
                    ? "[PUBLIKT MÅTT!]"
                    : metadata.organization_access_restrictions
                        ?.map(
                          (id) =>
                            organizations?.find((org) => org.id === id)?.name ??
                            "--"
                        )
                        .join("; ")
                }
              ></TextField>
            </div>
            <Button
              intent="primary"
              title="Redigera"
              onClick={() => setIsEditingOrgAccess(true)}
            ></Button>
          </div>
        )}

        <DataFields<AdminMetadataMicroDto>
          fields={fields}
          data={metadata}
          handleSave={(field, value) =>
            saveMicroMeasureMetadata(measureId, field, value)
          }
        ></DataFields>

        {dataValueTypesMicroGeo.includes(metadata.value_type) && (
          <section>
            <EnabledComputedMeasuresEditor
              handleSave={handleSaveEnabledComputedMeasures}
              enabledComputedMeasures={metadata.enabled_computed_measures}
            ></EnabledComputedMeasuresEditor>
          </section>
        )}
        <section>
          <TagsEditor
            saveTags={handleSaveTags}
            tags={metadata.tags ?? undefined}
            measureId={measureId}
          ></TagsEditor>
        </section>

        {defined(dimensions) && dimensions.length > 0 && (
          <>
            <FluentModal
              title="Redigera dimensioner"
              isOpen={isEditingDimensions}
              onClose={() => setIsEditingDimensions(false)}
            >
              <FluentModalBody>
                <DimensionsEditorV2
                  handleSaveDimensionsOrder={handleSaveDimensionsOrder}
                  handleEditDimension={handleEditDimension}
                  dimensions={dimensions}
                ></DimensionsEditorV2>
              </FluentModalBody>
            </FluentModal>
            <section>
              <Button
                title="Redigera dimensioner"
                onClick={() => setIsEditingDimensions(true)}
              ></Button>
            </section>
          </>
        )}

        <div>
          {metadata.retired ? (
            <Button
              intent="danger"
              title="Avpensionera mått"
              onClick={() => {
                if (
                  window.confirm(
                    "Vill du verkligen avpensionera måttet? Måttet kommer att visas i menyer igen."
                  )
                ) {
                  handleUnretireMeasure(measureId);
                }
              }}
            />
          ) : (
            <Button
              intent="danger"
              title="Pensionera mått"
              onClick={() => {
                if (
                  window.confirm(
                    "Vill du verkligen pensionera måttet? Måttet kommer inte längre att visas förutom i dokument som redan använder det."
                  )
                ) {
                  handleRetireMeasure(measureId);
                }
              }}
            ></Button>
          )}
        </div>
      </div>
    </CategoriesContext.Provider>
  );
}

function EnabledComputedMeasuresEditor(props: {
  enabledComputedMeasures: ComputedMeasurementType[] | null;
  handleSave: (
    enbabledMeasures: ComputedMeasurementType[]
  ) => Promise<HttpResult<any>>;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [checkedFields, setCheckedFields] = useState(() => {
    const fields: { [key: string]: boolean } = {};
    for (const t of ALL_COMPUTED_MEASURE_TYPES) {
      fields[t] = props.enabledComputedMeasures?.includes(t) ?? false;
    }
    return fields;
  });

  const currentlyCheckedFields = Object.entries(checkedFields)
    .filter(([field, checked]) => checked)
    .map(([field]) => field) as ComputedMeasurementType[];

  return (
    <div className="editable-field">
      <div className="flex-row">
        {Object.entries(checkedFields).map(([t, checked]) => {
          return (
            <Checkbox
              className="checkbox"
              disabled={!isEditing}
              label={COMPUTED_TYPE_LABELS[t as ComputedMeasurementType]}
              onChange={(e, checked) => {
                if (!defined(checked)) {
                  return;
                }
                setCheckedFields((prev) => ({ ...prev, [t]: checked }));
              }}
              checked={checked}
            ></Checkbox>
          );
        })}
      </div>
      {!isEditing ? (
        <Button
          intent="primary"
          title="Redigera"
          onClick={() => setIsEditing(true)}
        ></Button>
      ) : (
        <>
          <Button title="Avbryt" onClick={() => setIsEditing(false)}></Button>
          <Button
            intent="primary"
            disabled={arraysContainSameStrings(
              currentlyCheckedFields,
              props.enabledComputedMeasures ?? []
            )}
            title="Spara"
            onClick={() =>
              props.handleSave(currentlyCheckedFields).then((res) => {
                res.match({
                  err: voidFunc,
                  ok: () => setIsEditing(false),
                });
              })
            }
          ></Button>
        </>
      )}
    </div>
  );
}
