import { createRoot } from "react-dom/client";
import React, { useMemo } from "react";
import { ThemeProvider } from "@fluentui/react";

import { defined } from "../../../../../../lib/core/defined";
import { fluentUITheme } from "../../../../../../lib/application/theme";

import "./TitleControl.scss";
import { nonEmptyString } from "../../../../../../lib/core/nonEmptyString";

export class TitleControl {
  private _map?: mapboxgl.Map;
  private _container?: HTMLDivElement;
  private _root?: ReturnType<typeof createRoot>;
  private _title: string | undefined;
  private _subheaders: string | undefined;
  private _show: boolean;

  constructor(
    title: string | undefined,
    subheaders: string | undefined,
    show: boolean
  ) {
    this._title = title;
    this._subheaders = subheaders;
    this._show = show;
  }

  onAdd(map: mapboxgl.Map) {
    this._map = map;
    const container = document.createElement("div");
    container.className = "mapbox-title-control";
    this._container = container;
    this._root = createRoot(container);

    this.render();

    return container;
  }

  onRemove() {
    if (defined(this._container)) {
      setTimeout(() => {
        this._root?.unmount();
        this._root = undefined;
      });
    }
  }

  update(
    title: string | undefined,
    subheaders: string | undefined,
    show: boolean
  ) {
    this._title = title;
    this._subheaders = subheaders;
    this._show = show;
    this.render();
  }

  render() {
    if (this._root && this._container) {
      this._root.render(
        <TitleControlComponent
          show={this._show}
          title={this._title}
          subheaders={this._subheaders}
        />
      );
    }
  }
}

function TitleControlComponent(props: {
  title: string | undefined;
  subheaders: string | undefined;
  show: boolean;
}) {
  const subheaders = useMemo(() => {
    if (!nonEmptyString(props.subheaders)) {
      return [];
    }

    return props.subheaders.split("\n");
  }, [props.subheaders]);

  if (!defined(props.title) && !defined(props.subheaders)) {
    return null;
  }

  if (!props.show) {
    return null;
  }

  return (
    <div className="mapboxgl-ctrl">
      <ThemeProvider theme={fluentUITheme}>
        <div className="title-control">
          <h2>{props.title}</h2>
          {subheaders.length > 0 && (
            <p>
              {subheaders.map((line, index) => (
                <React.Fragment key={index}>
                  <span key={index}>{line}</span>
                  <br></br>
                </React.Fragment>
              ))}
            </p>
          )}
        </div>
      </ThemeProvider>
    </div>
  );
}
