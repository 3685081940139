import { useCallback } from "react";
import { Checkbox } from "@fluentui/react";
import {
  ButtonsFooter,
  ButtonsFooterRight,
} from "../../../../../../components/ButtonContainers";
import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../../components/Modal";
import { Button } from "../../../../../../components/Button";
import { MicroMapSettings } from "../../../../../../lib/application/state/stats/document-core/core-micro";

interface GeoLabelConfigDialogProps {
  cardId: string;
  onClose: () => void;
  settings: MicroMapSettings;
  handleUpdateSettings: (
    updater: (prev: MicroMapSettings) => MicroMapSettings
  ) => void;
}

export function GeoLabelConfigDialog({
  cardId,
  onClose,
  settings,
  handleUpdateSettings,
}: GeoLabelConfigDialogProps) {
  const handleToggle = useCallback(
    (key: keyof MicroMapSettings, checked: boolean) => {
      handleUpdateSettings((prev) => ({
        ...prev,
        [key]: checked,
      }));
    },
    [handleUpdateSettings]
  );

  return (
    <FluentModal
      width="md"
      isOpen
      onClose={onClose}
      title="Ändra etiketter för geomått"
    >
      <FluentModalBody>
        <div id="geolabel-config-dialog">
          <p>
            Välj vilka element som ska visas eller döljas för kartetiketterna:
          </p>
          <Checkbox
            className="margin-bottom-sm"
            label="Visa etikett"
            checked={settings.microGeoLabelShowLabel ?? true}
            onChange={(_, checked) =>
              handleToggle("microGeoLabelShowLabel", !!checked)
            }
          />
          <Checkbox
            className="margin-bottom-sm"
            label="Visa aktuellt filter"
            checked={settings.microGeoLabelShowInfoLines ?? true}
            onChange={(_, checked) =>
              handleToggle("microGeoLabelShowInfoLines", !!checked)
            }
          />
          <Checkbox
            className="margin-bottom-sm"
            label="Visa värde"
            checked={settings.microGeoLabelShowValue ?? true}
            onChange={(_, checked) =>
              handleToggle("microGeoLabelShowValue", !!checked)
            }
          />
        </div>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterRight>
            <Button title="Stäng" onClick={onClose}></Button>
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModal>
  );
}
