/*

| smart filterbox |

(Lyft funktionalitet för Markera samtliga kommuner, Markera samtliga län etc)

Län +/-
  (Markera samtliga)
  x Mellansverige +/-
    O Stockholm län +/-
      (Avmarkera samtliga)
      x Stockholm stad
      x Heby kommun
  x Västra Götalands län +/-
    O Göteborg stad 
    ...
  ...

Kommuner +/- (stryk den här initialt)
  (Markera samtliga)
  O Kommun A
  O Kommun B

Landsändar +/-
  (Markera samtliga)
  O Norra Sverige
  O Södra Sverige
  ...

Riksområden +/-
  (Markera samtliga)
  O Stockholmsregionen
  O Södra Mellansverige
  ...
  
*/
import * as _ from "lodash";
import React, { useMemo } from "react";
import { Checkbox, Separator } from "@fluentui/react";

import "./GeoSelect.scss";

import { MinusCircle, PlusCircle } from "../../../../../components/Icons";
import { InfostatMultiSearchBox } from "../../../../../components/InfostatMultiSearchBox";
import { SELECT_ALL_LABEL } from "../../../../../lib/application/menus";
import { classNames } from "../../../../../lib/core/classNames";
import { defined } from "../../../../../lib/core/defined";
import {
  GeoItem,
  GeographiesSerializable,
  GeoType,
  GeoTree,
  treesFromLevel,
  geoResolutionMin,
  greatestResolutionGeotype,
  listWithGeoTypes,
  displayGeographyPlural,
  higherGeoResolutions,
} from "../../../../../lib/domain/geography";
import { translateGeoLabel } from "../../../../../lib/domain/names";
import { Button } from "../../../../../components/Button";
import { sortBy } from "lodash";

/** List of geocodes */
type GeoSelection = string[];
interface CommonProps {
  selection: GeoSelection;
  expansions: GeoSelection;
  isGroupingMode: boolean;
  onExpand: (geocode: string, expanded: boolean) => void;
  onSelect: (geoItems: GeoItem[], selected: boolean) => void;
  onReset: () => void;
}
interface Props extends CommonProps {
  disabled?: boolean;
  geographies: GeographiesSerializable;
  supportedGeographies: GeoType[];
}
export function Geo(props: Props) {
  const { disabled, supportedGeographies } = props;

  const trees: GeoTree[] = useMemo(() => {
    return supportedGeographies.length === 0
      ? [props.geographies.tree]
      : treesFromLevel(
          props.geographies,
          geoResolutionMin(
            greatestResolutionGeotype(supportedGeographies),
            "nuts3"
          )
        );
  }, [props.geographies, supportedGeographies]);

  const geographiesList = useMemo(
    () => listWithGeoTypes(props.geographies, supportedGeographies),
    [props.geographies, supportedGeographies]
  );
  const supportOnlyCountryLevel =
    supportedGeographies.length === 1 && supportedGeographies[0] === "country";

  const exactlyOneSelected = props.selection.length === 1;
  return (
    <div className="geo-selector">
      <h4 className="section-title">
        <strong>
          Geografiska områden
          {disabled
            ? ""
            : ` (${props.selection.length}
          ${exactlyOneSelected ? "valt" : "valda"})`}
        </strong>
      </h4>
      {!disabled && (
        <>
          <InfostatMultiSearchBox
            disabled={disabled}
            selectedKeys={props.selection}
            onSelect={(item: GeoItem, selected) =>
              props.onSelect([item], selected)
            }
            size="narrow"
            search={(searchString) =>
              geographiesList
                .filter((item) =>
                  item.label.toLowerCase().includes(searchString.toLowerCase())
                )
                .slice(0, 30)
                .map((item) => ({ key: item.geocode, label: item.label, item }))
            }
          ></InfostatMultiSearchBox>
          <div className="section">
            <Button
              disabled={disabled}
              className="margin-top-sm"
              title="Återställ"
              onClick={() => props.onReset()}
            ></Button>
          </div>
        </>
      )}
      {supportedGeographies.length > 0 && (
        <div>
          {supportedGeographies.map((supportedGeoType) => {
            const matchingGeos = geographiesList.filter(
              (g) => g.type === supportedGeoType
            );
            const allChecked =
              (supportedGeoType === "municipal" && props.isGroupingMode) ||
              (matchingGeos.length > 0 &&
                props.selection.length > 0 &&
                matchingGeos.every((geo) =>
                  props.selection.includes(geo.geocode)
                ));

            return (
              <div className="checkbox-and-label" key={supportedGeoType}>
                <Checkbox
                  disabled={disabled}
                  checked={allChecked}
                  onChange={(_event, checked) => {
                    if (defined(checked)) {
                      const toCheck = _.chain(geographiesList)
                        .filter((g) => {
                          return g.type === supportedGeoType;
                        })
                        .value();
                      props.onSelect(toCheck, checked);
                    }
                  }}
                ></Checkbox>
                <span>
                  {supportedGeoType === "country"
                    ? matchingGeos[0].label
                    : "Välj alla " + displayGeographyPlural(supportedGeoType)}
                </span>
              </div>
            );
          })}
        </div>
      )}

      {!supportOnlyCountryLevel && !disabled && (
        <>
          <Separator></Separator>
          {sortBy(trees, (t) => t.label).map((tree) => (
            <Tree key={tree.geocode} tree={tree} {...props}></Tree>
          ))}
        </>
      )}
    </div>
  );
}

function Tree(
  props: {
    tree: GeoTree;
    supportedGeographies: GeoType[];
  } & CommonProps
) {
  const tree = props.tree;
  const children = tree.children;
  const hasChildren = children.length > 0;
  const selected = props.selection.includes(tree.geocode);
  const expanded = props.expansions.includes(tree.geocode);
  const currentLevel = tree.type;
  const childLevels = higherGeoResolutions(currentLevel);

  const canExpand =
    hasChildren &&
    childLevels.some((level) => props.supportedGeographies.includes(level));
  return (
    <div>
      <div className="tree">
        <div
          className={classNames(
            "title",
            hasChildren ? "expandable" : undefined
          )}
        >
          <Checkbox
            disabled={!props.supportedGeographies.includes(tree.type)}
            checked={selected}
            onChange={(_, checked) => {
              if (defined(checked)) {
                props.onSelect(
                  [
                    {
                      geocode: tree.geocode,
                      label: tree.label,
                      type: tree.type,
                    },
                  ],
                  checked
                );
              }
            }}
          ></Checkbox>
          <div
            onClick={
              hasChildren
                ? () => {
                    props.onExpand(tree.geocode, !expanded);
                  }
                : undefined
            }
            className="label-and-expansion"
          >
            <span className={"label"}>
              {translateGeoLabel(tree.label) ?? tree.label}{" "}
            </span>
          </div>
          <div
            className="expansion-icon"
            onClick={
              hasChildren
                ? () => {
                    props.onExpand(tree.geocode, !expanded);
                  }
                : undefined
            }
          >
            {canExpand && hasChildren ? (
              expanded ? (
                <MinusCircle></MinusCircle>
              ) : (
                <PlusCircle></PlusCircle>
              )
            ) : undefined}
          </div>
        </div>

        {expanded && canExpand && (
          <React.Fragment>
            <Checkbox
              className="select-all"
              label={SELECT_ALL_LABEL}
              checked={children.every((child) =>
                props.selection.includes(child.geocode)
              )}
              onChange={(_, checked) => {
                if (checked !== undefined) {
                  props.onSelect(children, checked);
                }
              }}
            ></Checkbox>
            {children.map((child) => (
              <Tree
                key={child.geocode + child.type}
                {...props}
                tree={child}
              ></Tree>
            ))}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
