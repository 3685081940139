import { Icon } from "@fluentui/react";

import { classNames } from "../lib/core/classNames";
import { defined } from "../lib/core/defined";
import { nonEmptyString } from "../lib/core/nonEmptyString";
import { SortOrder } from "../lib/core/order";
import { DataSourceSection } from "./charts/shared/DataSourceInfo";
import { displayNaNType } from "../lib/application/stats/datasets/DataCellValue";
import { isLast } from "../lib/core/last";
import {
  ColumnRow,
  SummaryTableRow,
  TableSpec,
} from "../lib/application/stats/datasets/table/table_base/table_base_definitions";
import { DataTableSortSetting } from "../lib/application/stats/datasets/table/table_base/sort";

import "./DataTable.scss";

interface Props {
  className?: string;
  tableId?: string;
  table: TableSpec;
  handleSetNonPrimarySort?: (columnIndex: number) => void;
  handleTogglePrimarySort?: () => void;
  sortSetting?: DataTableSortSetting;
  sortOrderPrimary?: SortOrder;
}
export function DataTable(props: Props) {
  const { sortSetting, sortOrderPrimary, table } = props;
  const columnsRow = table.header.columnRow;
  const primaryDimensionInfo = table.primaryDimensionInfo;

  const groupingSourceInfo = table.groupingSourceInfo;
  const tableRows = table.rows;

  const primaryComparator = primaryDimensionInfo?.comparatorAscending;

  return (
    <div className={classNames("infostat-data-table", props.className)}>
      {defined(table.name) && <h3>{table.name}</h3>}
      <table id={props.tableId}>
        <thead>
          {table.header.superColumnRows.map((level, i) => (
            <tr className="super-columns" key={i}>
              <th key="placeholder"></th>
              {level.columns.map((col, index) => (
                <th
                  key={nonEmptyString(col.key) ? col.key : "no-key-" + index}
                  colSpan={col.colSpan}
                >
                  {col.text}
                </th>
              ))}
            </tr>
          ))}
          <tr>
            <th
              className={classNames(
                "primary-dimension-header",
                primaryComparator ? "sortable" : undefined
              )}
              onClick={() => {
                if (!defined(primaryComparator)) {
                  return;
                }
                props.handleTogglePrimarySort?.();
              }}
            >
              <span className="header-text">
                {table.primaryDimensionInfo?.header ?? ""}
              </span>
              {defined(primaryComparator) && tableRows.length > 1 && (
                <span className="sort-indicator">
                  <SortIndicator
                    currentOrder={sortOrderPrimary}
                  ></SortIndicator>
                </span>
              )}
            </th>
            {columnsRow.columns.map((colRow, colIndex) => {
              const comparator = colRow.comparatorAscending;
              const state =
                defined(sortSetting) && sortSetting.columnIndex === colIndex
                  ? sortSetting.order
                  : undefined;
              const canSort = defined(comparator) && tableRows.length > 1;
              return (
                <th
                  className={classNames(
                    canSort ? "sortable" : undefined,
                    colRow.classNames
                  )}
                  key={colRow.key}
                  onClick={() => {
                    if (!canSort) {
                      return;
                    }
                    props.handleSetNonPrimarySort?.(colIndex);
                  }}
                >
                  <span className="header-container">
                    <span className="header-text">{colRow.text}</span>
                    {canSort && (
                      <span className="sort-indicator">
                        <SortIndicator currentOrder={state}></SortIndicator>
                      </span>
                    )}
                  </span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableRows.map((row) => (
            <tr
              key={row.label}
              className={classNames(
                isLast(tableRows, row) ? "last" : "",
                row.rowClassName
              )}
            >
              <td className={classNames("label", row.labelClassName ?? "")}>
                {row.label}
              </td>
              {row.values.map((value, i) => {
                const column = columnsRow.columns[i];
                return value.match({
                  ok: (v) => {
                    const outputValue = defined(v.format)
                      ? v.format(v.value)
                      : column.format(v.value);
                    if (
                      v.significantChange === "positive" ||
                      v.significantChange === "negative"
                    ) {
                      return (
                        <td
                          className={classNames(column.classNames, column.type)}
                          key={i}
                        >
                          <span
                            className={classNames(
                              v.significantChange === "positive"
                                ? "significant-change-positive"
                                : "",
                              v.significantChange === "negative"
                                ? "significant-change-negative"
                                : ""
                            )}
                          >
                            {outputValue}
                          </span>
                        </td>
                      );
                    }
                    return (
                      <td
                        className={classNames(
                          column.classNames,
                          column.type,
                          v.significantChange === "positive"
                            ? "significant-change-positive"
                            : "",
                          v.significantChange === "negative"
                            ? "significant-change-negative"
                            : ""
                        )}
                        key={i}
                      >
                        {outputValue}
                      </td>
                    );
                  },
                  nan: (v) => (
                    <td
                      className={classNames(
                        column.classNames,
                        column.type,
                        "no-value"
                      )}
                      key={i}
                    >
                      {displayNaNType(v)}
                    </td>
                  ),
                });
              })}
            </tr>
          ))}
          {defined(table.summaryRows) && table.summaryRows.length > 0 && (
            <SummaryRows
              summaryRows={table.summaryRows}
              columnsRow={columnsRow}
            ></SummaryRows>
          )}
          {defined(table.referenceRows) && table.referenceRows.length > 0 && (
            <SummaryRows
              summaryRows={table.referenceRows}
              columnsRow={columnsRow}
            ></SummaryRows>
          )}
        </tbody>
      </table>
      <DataSourceSection
        groupingSource={groupingSourceInfo}
        mainSource={table.sourceInfo}
        customSourceText={table.customSourceText}
      ></DataSourceSection>
      {table.surveySignificantChangesMode === "time" && (
        <div className="significant-changes-legend">
          <div className="row">
            <div className="survey-legend-change-positive"></div>
            <p>Signifikant uppgång från föregående mätning</p>
          </div>
          <div className="row">
            <div className="survey-legend-change-negative"></div>
            <p>Signifikant nedgång från föregående mätning</p>
          </div>
        </div>
      )}
      {table.surveySignificantChangesMode === "value" && (
        <div className="significant-changes-legend">
          <div className="row">
            <div className="survey-legend-change-positive"></div>
            <p>Signifikant högre än Samtliga</p>
          </div>
          <div className="row">
            <div className="survey-legend-change-negative"></div>
            <p>Signifikant lägre än Samtliga</p>
          </div>
        </div>
      )}
    </div>
  );
}

function SummaryRows(props: {
  summaryRows: SummaryTableRow[];
  columnsRow: ColumnRow;
}) {
  const { summaryRows, columnsRow } = props;
  return (
    <>
      {summaryRows.map((summaryRow, i) => (
        <tr
          key={summaryRow.label}
          className={i === 0 ? "first-summary-row" : undefined}
        >
          <td className="label">{summaryRow.label}</td>
          {summaryRow.values.map((value, i) => {
            const column = columnsRow.columns[i];
            const colType = "integer";
            return value.match({
              ok: (v) => (
                <td className={colType} key={i}>
                  {summaryRow.format(v)}
                </td>
              ),
              nan: (v) => (
                <td className={classNames(column.type, "no-value")} key={i}>
                  {displayNaNType(v)}
                </td>
              ),
            });
          })}
        </tr>
      ))}
    </>
  );
}

export function SortIndicator(props: { currentOrder?: SortOrder }) {
  const { currentOrder: state } = props;

  return (
    <Icon
      iconName={
        !defined(state)
          ? "double-caret-vertical"
          : state === SortOrder.ascending
          ? "caret-up"
          : "caret-down"
      }
    ></Icon>
  );
}
