import { Union, Literal, Static } from "runtypes";

import { defined } from "../../../../core/defined";
import { last } from "../../../../core/last";
import { v4 as uuidv4 } from "uuid";
import { InfostatBoundingBox } from "../../../../domain/cartography/types";
import {
  FilterMeasureMicro,
  PrimaryMeasureSelectionMicroPartial,
  MeasureSpecMicro,
  MeasureSelectionGeoMicroPartial,
  MeasureSelectionMicroPartial,
  MeasureSelectionGeoMicroFull,
  PrimaryMeasureSelectionMicroFull,
  DimensionV2Dto,
  SelectedDimensionsV2,
  ComputedMeasureVariablesConfig,
} from "../../../../domain/measure/definitions";
import { MicroColorScheme } from "../../../../domain/micro/colors";
import {
  ComputedMeasurementType,
  MicroMeasureDto,
  MicroMeasureRegularDto,
} from "../../../../infra/api_responses/micro_dataset";
import { DocCardMicro } from "./core";
import { DataValueTypeMicroAll } from "../../../../infra/api_responses/dataset";
import { processLongDescription } from "../../../stats/shared/charts_common";
import {
  ChartDataState,
  DataLoadProgress,
  MicroMapState,
  TableDataState,
} from "./_core-shared";
import { MicroDataset } from "../../../stats/datasets/MicroDataset";
import { ThirdPartyMicroCardSettings } from "../../../third_party_docs";
import { DataOutputSettings } from "./DataOutputSettings";
import { DrawableGroupSpec } from "../../../stats/datasets/MicroDatasetGeo";

export type MicroSelectionTypeGroup = "group";
export type MicroSelectionTypeAnonGroup = "anonymous-group";

export interface MicroCardLoadedData {
  primaryProgress?: DataLoadProgress;
  primaryDataset?: MicroDataset;
  chartDataState?: ChartDataState;
  tableDataState?: TableDataState;
  microMapState?: MicroMapState;
  microLatentStyles?: DrawableGroupSpec[];
}

export const MicroOutputTabRT = Union(
  Literal("map-select"),
  Literal("map-view"),
  Literal("table"),
  Literal("chart"),
  Literal("info")
);

export type MicroOutputTab = Static<typeof MicroOutputTabRT>;

// TODO: only deso as id
interface MicroSelectionPart {
  deso: string;
  id: number;
}
export interface MicroSelectionGroup {
  type: MicroSelectionTypeGroup;
  groupId: string;
  name: string;
  parts: MicroSelectionPart[];
}

export interface MicroSelectionAnonGroup {
  type: MicroSelectionTypeAnonGroup;
  parts: MicroSelectionPart[];
}

export type DesoId = number;
export interface DesoPropertiesBase {
  deso: string;
  deso_label: string;
  regso: string;
  regso_label: string;
  lng?: number;
  lat?: number;
}

export interface DesoProperties extends DesoPropertiesBase {
  id: DesoId;
}

export interface RegsoProperties {
  id: number;
  regso: string;
  regso_label: string;
  lng?: number;
  lat?: number;
}

export interface CustomRegion {
  id: string;
  name: string;
  propertiesArray: DesoProperties[];
}

/**
 * A single remotely stored pre-defined region, consisting of a set of deso ids,
 * name and id
 */
export interface SingleStoredRegion {
  id: string;
  name: string;
  desos: DesoPropertiesBase[];
}

export interface StoredRegions {
  numTotalAvailable: number;
  userDefined: SingleStoredRegion[];
  infostatPredefined: SingleStoredRegion[];
}

export type SelectedDesoArea = {
  type: "deso";
  props: DesoProperties;
};
export type SelectedRegsoArea = {
  type: "regso";
  props: RegsoProperties;
};

export type SelectedCustomRegion<T> = {
  type: "user-defined";
  color: string;
  groupName: string;
  groupId: string;
  props: T[];
};

export type SelectedAreaDesoMode =
  | SelectedDesoArea
  | SelectedCustomRegion<DesoProperties>;
export type SelectedAreaRegsoMode =
  | SelectedRegsoArea
  | SelectedCustomRegion<RegsoProperties>;

export type SelectedArea = SelectedAreaDesoMode | SelectedAreaRegsoMode;
export type SelectedAreasDeso = SelectedAreaDesoMode[];
export type SelectedAreasRegso = SelectedAreaRegsoMode[];

export function isUserDefinedAreaDesoMode(
  area: SelectedAreaDesoMode
): area is SelectedCustomRegion<DesoProperties> {
  return area.type === "user-defined";
}

export function isUserDefinedAreaRegsoMode(
  area: SelectedAreaRegsoMode
): area is SelectedCustomRegion<RegsoProperties> {
  return area.type === "user-defined";
}

export type MapSelectTool = "click-select" | "draw-select";
export type MicroMapMode =
  | {
      type: "edit-selections";
      selectedTool: MapSelectTool;
    }
  | {
      type: "view-results";
    };

/**
 * map-view: view results on map
 * map-select: select areas on map
 */
export type MicroMapView = "map-view" | "map-select";
export interface MicroMapSettings {
  /**
   * Show borders between regso/deso areas
   */
  showBorders: boolean;

  /** Show info box over map: holding a header and some info about selected areas */
  showInfoBox?: boolean;

  /**
   * Show map layers containing map labels: places, natural features, roads etc.
   * Default: true
   */
  showMapLabels?: boolean;

  /**
   * Hide geo objects (points, polygons, lines) that are outside
   * selected deso/regso areas
   */
  hideGeoObjectsOutsideSelection?: boolean;

  /**
   * The selection tool active. Used to add/remove areas.
   */
  mapSelectTool: MapSelectTool;

  /**
   * A number between 0 and 1, 1 denoting full opacity
   */
  resultsOpacity: number;
  /**
   * The color scheme for coloring map results
   */
  colorScheme: MicroColorScheme;
  /**
   * Maximum/minimum z value (from normal distribution) used for coloring. Any greater/lesser values will be colored with the max/min color.
   */
  zMinMaxAbsolute: number;
  /**
   * When localZRange is true, the z min/max values are calculated based on the currently selected areas' z values.
   */
  localZRange?: boolean;
  /**
   * Show area borders but do not fill with any color
   */
  bordersOnlyNoFill?: boolean;
  showDesoRegsoLabels?: boolean;
  /** Show values for the selected measurement for each area */
  showDesoRegsoValues?: boolean;
  /** Manual breakpoints for coloring areas */
  manualBreakpoints?: number[];
  /** Show legend for points, lines and polygons */
  showGeoLegend?: boolean;
  /** Show legend with long labels for points, lines and polygons */
  showGeoLegendLongLabels?: boolean;
  /** Show legend for map results */
  showLegend?: boolean;
  /** Manual colors for ranges, for coloring areas */
  manualColorsForRanges?: string[];
  /** Labels for ranges. Only applicable when using manual colors for ranges */
  labelsForManualRanges?: string[];
  /** Custom header for map view */
  customHeader?: string;
  /** Custom subheaders for map view */
  customSubheaders?: string;

  microGeoLabelShowLabel?: boolean; // default to true
  microGeoLabelShowInfoLines?: boolean; // default to true
  microGeoLabelShowValue?: boolean; // default to true
}

export type MicroDataFilter =
  | { type: "gte"; value: number }
  | { type: "lte"; value: number }
  | { type: "interval"; gte: number; lte: number }
  | { type: "topX"; value: number }
  | { type: "topXPercent"; value: number }
  | { type: "bottomX"; value: number }
  | { type: "bottomXPercent"; value: number }
  | { type: "intervalPercent"; gte: number; lte: number }
  | { type: "belowAvg" }
  | { type: "aboveAvg" };

export type FilterSet = {
  type: "simple-and-filter";
  filters: MicroDataFilter[];
};

export interface MicroSettings {
  map: MicroMapSettings;
  dataOutputSettings: DataOutputSettings;
}

export type MicroSubjectPath = (string | undefined)[];
export type MicroGeoSelectionsDesoMode = {
  type: "deso";
  selected: SelectedAreaDesoMode[];
};
export type MicroGeoSelectionsRegsoMode = {
  type: "regso";
  selected: SelectedAreaRegsoMode[];
};
export type MicroGeoSelections =
  | MicroGeoSelectionsDesoMode
  | MicroGeoSelectionsRegsoMode;

export type DataSelectionMicro =
  | PrimaryMeasureSelectionMicroPartial
  | MeasureSelectionGeoMicroPartial;

export interface InnerMicroCardData {
  loadedData?: MicroCardLoadedData;

  thirdPartyMicroCardSettings?: ThirdPartyMicroCardSettings;

  settings: MicroSettings;
  /**
   * The location to load when loading a card
   */
  mapLocationBounds: InfostatBoundingBox;
  selectedTab: MicroOutputTab;
  /**
   * Previous geoselections that may not be valid for the current selection.
   * Stored in order to enable applying a reasonable selection when
   * 1) switching from a primary measure to geo-micro measure and
   * back again.
   * 2) switching from a deso measure to a regso measure and back again
   */
  geoSelectionsOldDeso?: MicroGeoSelections;
  /** See @geoSelectionsOldDeso */
  geoSelectionsOldRegso?: MicroGeoSelections;
  geoSelections?: MicroGeoSelections;
  dataSelections?: DataSelectionMicro[];
  filterMeasures: FilterMeasureMicro[];
  lockToLatestTime?: boolean;
}

export function measureToMeasureSpecMicro(
  measure: MicroMeasureDto,
  dimensions: DimensionV2Dto[]
): MeasureSpecMicro {
  const common = {
    id: measure.mikro_id,
    dimensions,
    label: measure.label,
    descrLong: measure.descr_long,
    publicComment: processLongDescription(measure.public_comment),
    measure: measure.measure,
    source: measure.source,
    extSource: measure.ext_source,
    extDescription: measure.ext_descr,
    extDescriptionLong: measure.ext_descr_long,
    sourceUrl: measure.source_url,
    lastUpdate: measure.last_update,
    unitLabel: measure.unit_label,
    valueType: measure.value_type,
    timeResolution: measure.resolution,
  };

  if (isMicroMeasureRegularDto(measure)) {
    return {
      ...common,
      computed: defined(measure.computed_measurement_type)
        ? {
            type: measure.computed_measurement_type,
            variables: measure.computed_measurement_variables,
          }
        : undefined,
      aggMethodGeo: measure.agg_method_geo,
      geoTypes: measure.geo_types,
    };
  }
  return {
    ...common,
    aggMethodGeo: "none",
    geoTypes: [],
  };
}

export function makeMicroDataGeoSelection(
  subjectPath: MicroSubjectPath,
  measureDto: MicroMeasureDto,
  dimensions: DimensionV2Dto[] | null,
  availableDates: string[],
  selectedDimensions: SelectedDimensionsV2
): MeasureSelectionGeoMicroPartial {
  const lastDate = last(availableDates);
  return {
    id: uuidv4(),
    type: "geo-micro",
    subjectPath,
    measure: measureToMeasureSpecMicro(measureDto, dimensions ?? []),
    timeSelection: defined(lastDate) ? [lastDate, lastDate] : undefined,
    selectedDimensions,
    availableDates,
  };
}

export function microGeoSelectionsToGeocodes(
  selections: MicroGeoSelections
): string[] {
  const geocodes: string[] = [];
  for (const s of selections.selected) {
    if (s.type === "user-defined") {
      continue;
    }
    if (s.type === "regso") {
      geocodes.push(s.props.regso);
    } else if (s.type === "deso") {
      geocodes.push(s.props.deso);
    }
  }
  return geocodes;
}

export function makePrimaryMicroDataSelection(
  subjectPath: MicroSubjectPath,
  measureDto: MicroMeasureDto,
  dimensions: DimensionV2Dto[] | null,
  availableDates: string[],
  selectedDimensions: SelectedDimensionsV2,
  computedMeasureVariableConfig: ComputedMeasureVariablesConfig | undefined
): PrimaryMeasureSelectionMicroPartial {
  const lastDate = last(availableDates);
  const measureSpec = measureToMeasureSpecMicro(measureDto, dimensions ?? []);
  return {
    id: uuidv4(),
    type: "primary",
    subjectPath,
    measure: measureSpec,
    timeSelection: defined(lastDate) ? [lastDate, lastDate] : undefined,
    multiSelectEnabled: false,
    computedMeasureVariablesConfig: computedMeasureVariableConfig,
    selectedDimensions,
    availableDates,
  };
}

export function microSelectionPrimary(
  card: DocCardMicro
): PrimaryMeasureSelectionMicroFull | undefined {
  return card.data.dataSelections?.find(isMicroSelectionPrimary);
}
export function microSelectionsNonPrimary(
  card: DocCardMicro
): MeasureSelectionGeoMicroPartial[] {
  return (
    (card.data.dataSelections?.filter(
      (s) => !isMicroSelectionPrimary(s)
    ) as MeasureSelectionGeoMicroPartial[]) ?? []
  );
}

export function microCardHasFilters(card: DocCardMicro): boolean {
  return (
    card.data.filterMeasures.length > 0 ||
    (defined(card.data.dataSelections) &&
      card.data.dataSelections.some(
        (s) => s.type === "primary" && defined(s.filterSet)
      ))
  );
}

export function findMeasureMicro(
  measures: MicroMeasureDto[],
  id: number,
  computedMeasurementType: ComputedMeasurementType | undefined
): MicroMeasureDto | undefined {
  return measures.find(
    (m) =>
      m.mikro_id === id &&
      (computedMeasurementType === undefined ||
        (isMicroMeasureRegularDto(m) &&
          m.computed_measurement_type === computedMeasurementType))
  );
}

export function isMicroSelectionPrimary(
  selection: MeasureSelectionMicroPartial
): selection is PrimaryMeasureSelectionMicroFull {
  return selection.type === "primary" && defined(selection.measure);
}

export function isMicroSelectionPoint(
  selection: MeasureSelectionMicroPartial
): selection is MeasureSelectionGeoMicroPartial {
  return selection.measure?.valueType === "point";
}

export function isMicroSelectionPolygon(
  selection: MeasureSelectionMicroPartial
): selection is MeasureSelectionGeoMicroPartial {
  return selection.measure?.valueType === "polygon";
}

export function isMicroSelectionLine(
  selection: MeasureSelectionMicroPartial
): selection is MeasureSelectionGeoMicroPartial {
  return selection.measure?.valueType === "line";
}

export function isMeasureSelectionGeoMicroFull(
  selection: MeasureSelectionMicroPartial
): selection is MeasureSelectionGeoMicroFull {
  return defined(selection.measure);
}

export function isMicroMeasureRegularDto(
  measure: MicroMeasureDto
): measure is MicroMeasureRegularDto {
  const regularTypes: DataValueTypeMicroAll[] = ["decimal", "integer"];
  return regularTypes.includes(measure.value_type);
}

export function cardPrimaryMeasureIsComputed(card: DocCardMicro): boolean {
  return microSelectionPrimary(card)?.measure?.computed !== undefined;
}

export function desoToId(deso: string) {
  return parseInt(
    deso.slice(0, 4) + convertDesoCharacter(deso[4]) + deso.slice(5)
  );
}

export function regsoToId(regso: string) {
  return parseInt(
    regso.slice(0, 4) + convertRegsoCharacter(regso[4]) + regso.slice(5)
  );
}

function convertRegsoCharacter(char: string): string {
  switch (char) {
    case "R":
      return "0";
    default:
      throw new Error("Unknown regso character: " + char);
  }
}

function convertDesoCharacter(char: string): string {
  switch (char) {
    case "A":
      return "0";
    case "B":
      return "1";
    case "C":
      return "2";
    default:
      throw new Error("DeSO character must be A, B or C, was: " + char);
  }
}
