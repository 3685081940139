import {
  Checkbox,
  ColorPicker,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  Pivot,
  PivotItem,
  SwatchColorPicker,
} from "@fluentui/react";
import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import * as _ from "lodash";

import { CustomDropdown } from "../../../../../components/CustomDropdown";
import {
  FluentModalBody,
  FluentModalFooter,
  FluentModalTall,
} from "../../../../../components/Modal";
import { fullCardsQuery } from "../../../../../lib/application/state/stats/document-core/queries/card";
import {
  DEFAULT_AXIS_COLOR,
  DEFAULT_CHART_TEXT_COLOR,
  DEFAULT_GRID_LINE_COLOR,
  extendedSwatchColors,
  standardColors,
} from "../../../../../lib/application/stats/shared/core/colors/colors";
import { defined } from "../../../../../lib/core/defined";
import { ColorSchemeContainer } from "../../../../../lib/application/state/stats/document-style/definitions";
import { logger } from "../../../../../lib/infra/logging";
import { Button } from "../../../../../components/Button";
import {
  copyColorSchemeContainer,
  makeSingleColorScheme,
} from "../../../../../lib/application/state/stats/document-style/operations";
import { useSingleColorSymbol } from "../../../../../lib/application/state/stats/document-core/core";
import { config } from "../../../../../config";
import {
  ButtonsFooter,
  ButtonsFooterLeft,
  ButtonsFooterRight,
} from "../../../../../components/ButtonContainers";
import { useColorScheme } from "../../../../../lib/application/hooks/useColorScheme";
import { cardColors } from "../../../../../lib/application/state/stats/document-core/queries/shared";
import { useExtendedAppearanceSettings } from "../../../../../lib/application/state/actions/useExtendedAppearanceSettings";
import {
  CustomThemeSpec,
  CustomThemeSpecApplied,
  ExtendedAppearanceSettings,
  builtInSecondaryThemes,
  defaultThemeSpec,
  getInfostatStandardTheme,
  gridLineStyleOptions,
  isInfostatStandardPalette,
  themeSpecFromColorContainer,
} from "../../../../../lib/application/stats/shared/core/colors/colorSchemes";

import "./ColorOptionsDialog.scss";
import { OutputPreview } from "../OutputPreview";
import { GridLineDropdown } from "../../../../../components/charts/GridLinesDropdown";
import { classNames } from "../../../../../lib/core/classNames";
import { DataOutputSettings } from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";

const RGBA_TRANSPARENT = "rgba(0,0,0,0)";

interface Props {
  isOpen: boolean;
  cardId: string;
  onClose: () => void;
  settings: DataOutputSettings;
  setSettings: (settings: DataOutputSettings) => void;
}

interface AvailableScheme {
  label: string;
  scheme: ColorSchemeContainer;
}

const defaultTheme = getInfostatStandardTheme();

export function ColorOptionsDialog(props: Props) {
  const { cardId, settings, setSettings } = props;

  const [currentColorScheme, setCurrentColorScheme] = useColorScheme(cardId);
  const isSingleColoredScheme =
    currentColorScheme?.colorScheme[useSingleColorSymbol] === true;

  const handleOutputSettingChange = <K extends keyof DataOutputSettings>(
    setting: K,
    value: DataOutputSettings[K]
  ) => {
    setSettings({ ...settings, [setting]: value });
  };

  const [localPalette] = useState(() => {
    const embeddedPalette = currentColorScheme?.embeddedPalette;
    if (defined(embeddedPalette)) {
      if (isInfostatStandardPalette(embeddedPalette)) {
        return {
          ...embeddedPalette,
          colors: standardColors.concat(extendedSwatchColors),
        };
      }
      return embeddedPalette;
    }
    if (defined(currentColorScheme)) {
      return themeSpecFromColorContainer(
        currentColorScheme,
        standardColors.concat(extendedSwatchColors)
      );
    }
    return defaultThemeSpec();
  });

  const dataCards = useRecoilValue(fullCardsQuery);
  const appearanceSettings = useExtendedAppearanceSettings();

  const otherCardAvailableColorSchemes: AvailableScheme[] = useMemo(
    () =>
      _.chain(dataCards)
        .filter((card) => card.id !== cardId)
        .map((card) => {
          if (card.type !== "dataCard") {
            return;
          }
          const scheme = cardColors(card);
          if (!defined(scheme)) {
            return undefined;
          }
          return { label: card.label, scheme: scheme };
        })
        .filter(defined)
        .uniq()
        .value(),
    [dataCards, cardId]
  );

  const handleApplyColor = useCallback(
    (label: string, color: string) => {
      if (!defined(currentColorScheme)) {
        return;
      }
      const scheme = { ...currentColorScheme.colorScheme };
      scheme[label] = color;
      setCurrentColorScheme({
        ...currentColorScheme,
        colorScheme: scheme,
        isUserDefined: true,
      });
    },
    [currentColorScheme, setCurrentColorScheme]
  );

  const setColorSchemeCustomAttr = useCallback(
    (attr: keyof ColorSchemeContainer, color?: string) => {
      if (!defined(currentColorScheme)) {
        return;
      }
      setCurrentColorScheme({
        ...currentColorScheme,
        [attr]: color,
      });
    },
    [currentColorScheme, setCurrentColorScheme]
  );

  const handleSetUseDefaultSpecialColors = useCallback(
    (use: boolean) => {
      if (!defined(currentColorScheme)) {
        return;
      }
      setCurrentColorScheme({
        ...currentColorScheme,
        useDefaultSpecialColors: use,
      });
    },
    [currentColorScheme, setCurrentColorScheme]
  );

  const handleApplyColorSingleColorScheme = useCallback(
    (color: string) => {
      if (!defined(currentColorScheme)) {
        return;
      }
      const scheme = { ...currentColorScheme.colorScheme };
      for (const label of Object.keys(scheme)) {
        scheme[label] = color;
      }
      setCurrentColorScheme({
        ...currentColorScheme,
        colorScheme: scheme,
        isUserDefined: true,
      });
    },
    [currentColorScheme, setCurrentColorScheme]
  );

  const handleResetColorScheme = useCallback(() => {
    if (!defined(currentColorScheme)) {
      return;
    }
    const embeddedPalette = currentColorScheme.embeddedPalette;
    const currentTheme = appearanceSettings.availableThemes.find(
      (theme) => theme.id === embeddedPalette?.id
    );
    setCurrentColorScheme(
      {
        ...currentColorScheme,
        customAxesColor: embeddedPalette?.customAxesColor,
        customBgColor: embeddedPalette?.customBgColor,
        customGridLinesColor: embeddedPalette?.customGridLinesColor,
        customGridLinesXColor: embeddedPalette?.customGridLinesXColor,
        customGridLinesYColor: embeddedPalette?.customGridLinesYColor,
        customHeadersColor: embeddedPalette?.customHeadersColor,
        customLabelsColor: embeddedPalette?.customLabelsColor,
        colorScheme: {},
        isUserDefined: false,
      },
      currentTheme
    );
  }, [
    appearanceSettings.availableThemes,
    currentColorScheme,
    setCurrentColorScheme,
  ]);

  const handleCopyColorScheme = useCallback(
    (container: ColorSchemeContainer) => {
      if (!defined(currentColorScheme)) {
        logger.error("Cannot update color scheme: original not found");
        return;
      }
      if (!defined(container)) {
        logger.error("Cannot update color scheme: copy not found");
        return;
      }
      const copy = copyColorSchemeContainer(container);
      copy.id = currentColorScheme.id;
      copy.colorScheme = {
        ...currentColorScheme.colorScheme,
        ...copy.colorScheme,
      };
      setCurrentColorScheme(copy);
    },
    [currentColorScheme, setCurrentColorScheme]
  );

  const handleSetUseMultiColorScheme = useCallback(
    (use: boolean) => {
      if (!defined(currentColorScheme)) {
        return;
      }

      const schemeContainer: ColorSchemeContainer = use
        ? {
            ...currentColorScheme,
            colorScheme: { [useSingleColorSymbol]: false },
            isUserDefined: true,
          }
        : {
            ...currentColorScheme,
            paletteName: currentColorScheme.paletteName,
            embeddedPalette: currentColorScheme.embeddedPalette,
            colorScheme: makeSingleColorScheme(
              Object.keys(currentColorScheme.colorScheme),
              Object.values(currentColorScheme.colorScheme)[0]
            ),
            isUserDefined: true,
            id: currentColorScheme.id,
          };

      setCurrentColorScheme(schemeContainer);
    },
    [currentColorScheme, setCurrentColorScheme]
  );

  const handleSwitchTheme = useCallback(
    (theme: CustomThemeSpecApplied) => {
      if (!defined(currentColorScheme)) {
        return;
      }
      if (currentColorScheme.colorScheme[useSingleColorSymbol] === true) {
        setCurrentColorScheme(
          {
            ...currentColorScheme,
            embeddedPalette: theme,
            colorScheme: makeSingleColorScheme(
              Object.keys(currentColorScheme.colorScheme),
              theme.colors[0]
            ),
            useDefaultSpecialColors: theme.useDefaultSpecialColors,
            customAxesColor: theme.customAxesColor,
            customBgColor: theme.customBgColor,
            customGridLinesColor: theme.customGridLinesColor,
            customHeadersColor: theme.customHeadersColor,
            customLabelsColor: theme.customLabelsColor,
            isUserDefined: false,
          },
          theme
        );
        return;
      }

      setCurrentColorScheme(
        {
          ...currentColorScheme,
          useDefaultSpecialColors: theme.useDefaultSpecialColors,
          customAxesColor: theme.customAxesColor,
          customBgColor: theme.customBgColor,
          customGridLinesColor: theme.customGridLinesColor,
          customHeadersColor: theme.customHeadersColor,
          customLabelsColor: theme.customLabelsColor,
          embeddedPalette: theme,
          colorScheme: {
            [useSingleColorSymbol]: false,
          },
          isUserDefined: false,
        },
        theme
      );
    },
    [currentColorScheme, setCurrentColorScheme]
  );

  const currentColors = currentColorScheme?.colorScheme ?? {};
  const currentColorsKeys = Object.keys(currentColors);
  const singleColor = {
    label: currentColorsKeys.length === 1 ? currentColorsKeys[0] : "Alla",
    color: Object.values(currentColors)[0],
  };

  const currentPalette = useMemo(() => {
    const embeddedPalette = currentColorScheme?.embeddedPalette;
    if (defined(embeddedPalette)) {
      if (isInfostatStandardPalette(embeddedPalette)) {
        return {
          ...embeddedPalette,
          colors: standardColors.concat(extendedSwatchColors),
        };
      }
      return embeddedPalette;
    }
    if (defined(currentColorScheme)) {
      return themeSpecFromColorContainer(
        currentColorScheme,
        standardColors.concat(extendedSwatchColors)
      );
    }
    return defaultThemeSpec();
  }, [currentColorScheme]);

  const colorLabelToCustom = useCallback(
    (label: string) => {
      if (!defined(settings.customLabels)) {
        return label;
      }
      const keys = Object.keys(settings.customLabels);
      if (keys.length === 0) {
        return label;
      }
      for (const key of keys) {
        const customLabel = settings.customLabels?.[key]?.[label];
        if (defined(customLabel)) {
          return customLabel;
        }
      }
      return label;
    },
    [settings.customLabels]
  );

  return (
    <div>
      <FluentModalTall
        title="Färger och teman"
        isOpen={props.isOpen}
        onClose={props.onClose}
        width="md"
      >
        <FluentModalBody>
          <div className="color-options-dialog-stats">
            <section>
              <SwitchTheme
                handleResetColorScheme={handleResetColorScheme}
                startingTheme={localPalette}
                currentTheme={currentPalette}
                appearanceSettings={appearanceSettings}
                handleSwitchColorScheme={handleSwitchTheme}
              />
            </section>
            <div className="section">
              <h3>Staplar & linjer</h3>

              <Checkbox
                label="Använd flera färger"
                checked={!isSingleColoredScheme}
                onChange={(e, checked) => {
                  if (defined(checked)) {
                    handleSetUseMultiColorScheme(checked);
                  }
                }}
              />
              <Checkbox
                checked={
                  currentColorScheme?.useDefaultSpecialColors ??
                  defaultTheme.useDefaultSpecialColors
                }
                onChange={(e, checked) => {
                  if (!defined(checked)) {
                    return;
                  }
                  handleSetUseDefaultSpecialColors(checked);
                }}
                label="Använd färgautomatik för ordningsföljder, kön m.m."
              />

              <div className="colors-list">
                {isSingleColoredScheme ? (
                  <div className="dropdown-container">
                    <ColorDropdown
                      key="__single-color__"
                      swatchColors={currentPalette.colors}
                      mode="both"
                      colorCode={singleColor.color}
                      label="Alla"
                      applyColor={(label, color) =>
                        handleApplyColorSingleColorScheme(color)
                      }
                    ></ColorDropdown>
                    <label>{singleColor.label}</label>
                  </div>
                ) : (
                  Object.entries(currentColors)
                    .sort((left, right) => (left[0] < right[0] ? -1 : 1))
                    .map(([domain, colorCode], index) => {
                      return (
                        <div className="dropdown-container" key={domain}>
                          <ColorDropdown
                            swatchColors={currentPalette.colors}
                            mode="both"
                            key={domain}
                            colorCode={colorCode}
                            label={domain}
                            applyColor={handleApplyColor}
                          />
                          <label>{colorLabelToCustom(domain)}</label>
                        </div>
                      );
                    })
                )}
              </div>
            </div>
            <>
              {config.appMode !== "embedded" && defined(currentColorScheme) && (
                <>
                  <div className="section">
                    <h3>Diagramdelar</h3>
                    <div className="colors-list">
                      <div className="dropdown-container">
                        <ColorDropdown
                          swatchColors={currentPalette.colors}
                          mode="picker"
                          colorCode={
                            currentColorScheme?.customBgColor ?? undefined
                          }
                          label="Bakgrundsfärg"
                          applyColor={(label, color) =>
                            setColorSchemeCustomAttr("customBgColor", color)
                          }
                          applyNoColor={() =>
                            setColorSchemeCustomAttr("customBgColor")
                          }
                        />
                        <label>Bakgrundsfärg</label>
                      </div>
                      <div className="dropdown-container">
                        <ColorDropdown
                          swatchColors={currentPalette.colors}
                          mode="picker"
                          colorCode={
                            currentColorScheme?.customGridLinesColor === null
                              ? undefined
                              : currentColorScheme.customGridLinesColor ??
                                DEFAULT_GRID_LINE_COLOR
                          }
                          label="Stödlinjer"
                          applyColor={(label, color) =>
                            setColorSchemeCustomAttr(
                              "customGridLinesColor",
                              color
                            )
                          }
                          applyNoColor={() =>
                            setColorSchemeCustomAttr(
                              "customGridLinesColor",
                              RGBA_TRANSPARENT
                            )
                          }
                        />
                        <label>Stödlinjer</label>
                      </div>
                      <div className="dropdown-container">
                        <ColorDropdown
                          swatchColors={currentPalette.colors}
                          mode="picker"
                          colorCode={
                            currentColorScheme.customAxesColor === null
                              ? undefined
                              : currentColorScheme.customAxesColor ??
                                DEFAULT_AXIS_COLOR
                          }
                          label="Axlar"
                          applyColor={(label, color) =>
                            setColorSchemeCustomAttr("customAxesColor", color)
                          }
                          applyNoColor={() =>
                            setColorSchemeCustomAttr(
                              "customAxesColor",
                              RGBA_TRANSPARENT
                            )
                          }
                        />
                        <label>Axlar</label>
                      </div>
                      <div className="dropdown-container">
                        <ColorDropdown
                          swatchColors={currentPalette.colors}
                          mode="picker"
                          colorCode={
                            currentColorScheme.customHeadersColor ??
                            DEFAULT_CHART_TEXT_COLOR
                          }
                          label="Rubriker"
                          applyColor={(label, color) =>
                            setColorSchemeCustomAttr(
                              "customHeadersColor",
                              color
                            )
                          }
                        />
                        <label>Rubriker</label>
                      </div>
                      <div className="dropdown-container">
                        <ColorDropdown
                          swatchColors={currentPalette.colors}
                          mode="picker"
                          colorCode={
                            currentColorScheme.customLabelsColor ??
                            DEFAULT_CHART_TEXT_COLOR
                          }
                          label="Etiketter"
                          applyColor={(label, color) =>
                            setColorSchemeCustomAttr("customLabelsColor", color)
                          }
                        />
                        <label>Etiketter</label>
                      </div>
                    </div>
                  </div>

                  <div className="section">
                    <h3>Detaljinställningar för stödlinjer</h3>

                    <div className="dropdowns-list">
                      <div className="dropdown-container">
                        <ColorDropdown
                          swatchColors={currentPalette.colors}
                          mode="picker"
                          colorCode={
                            currentColorScheme.customGridLinesXColor === null
                              ? undefined
                              : currentColorScheme.customGridLinesXColor ??
                                DEFAULT_AXIS_COLOR
                          }
                          label="Axlar"
                          applyColor={(label, color) =>
                            setColorSchemeCustomAttr(
                              "customGridLinesXColor",
                              color
                            )
                          }
                          applyNoColor={() =>
                            setColorSchemeCustomAttr(
                              "customGridLinesXColor",
                              RGBA_TRANSPARENT
                            )
                          }
                        />
                        <label>Färg för stödlinjer i x-led</label>
                      </div>
                      <div className="dropdown-container">
                        <ColorDropdown
                          swatchColors={currentPalette.colors}
                          mode="picker"
                          colorCode={
                            currentColorScheme.customGridLinesYColor === null
                              ? undefined
                              : currentColorScheme.customGridLinesYColor ??
                                undefined
                          }
                          label="Axlar"
                          applyColor={(label, color) =>
                            setColorSchemeCustomAttr(
                              "customGridLinesYColor",
                              color
                            )
                          }
                          applyNoColor={() =>
                            setColorSchemeCustomAttr(
                              "customGridLinesYColor",
                              RGBA_TRANSPARENT
                            )
                          }
                        />
                        <label>Färg för stödlinjer i y-led</label>
                      </div>
                    </div>
                    <div className="dropdowns-list">
                      <div className="dropdown-container">
                        <GridLineDropdown
                          label="Stil för stödlinjer i x-led"
                          selectedOption={
                            settings.gridLinesXStyle ?? gridLineStyleOptions[0]
                          }
                          handleOutputSettingChange={(value) =>
                            handleOutputSettingChange("gridLinesXStyle", value)
                          }
                        />
                      </div>
                      <div className="dropdown-container">
                        <GridLineDropdown
                          label="Stil för stödlinjer i y-led"
                          selectedOption={
                            settings.gridLinesYStyle ?? gridLineStyleOptions[0]
                          }
                          handleOutputSettingChange={(value) =>
                            handleOutputSettingChange("gridLinesYStyle", value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>

            {config.appMode !== "embedded" && (
              <div className="section">
                <h3>Kopiera färgschema</h3>
                <p>
                  Välj ett kort för att kopiera färgschemat till det här kortet.
                </p>

                <DefaultButton
                  disabled={otherCardAvailableColorSchemes.length === 0}
                  text="Kopiera från ..."
                  menuProps={{
                    items: otherCardAvailableColorSchemes.map((scheme) => ({
                      key: scheme.label,
                      text: scheme.label,
                      title: scheme.label,
                      onClick: () => {
                        handleCopyColorScheme(scheme.scheme);
                      },
                    })),
                  }}
                ></DefaultButton>
              </div>
            )}

            <div className="preview">
              <h2>Förhandsvisning</h2>
              <OutputPreview cardId={props.cardId}></OutputPreview>
            </div>
          </div>
        </FluentModalBody>
        <FluentModalFooter>
          <Button
            title="Stäng"
            onClick={() => {
              props.onClose();
            }}
          ></Button>
        </FluentModalFooter>
      </FluentModalTall>
    </div>
  );
}

const colorToCell = (color: string) => ({ color, id: color });

const colorCellsExtendedSwatch = extendedSwatchColors.map(colorToCell);

type ColorPickingType = "swatch" | "swatch_extended" | "picker";
type ColorPickingSupport = ColorPickingType | "both";

export function ColorDropdown(props: {
  swatchColors: string[];
  colorCode?: string;
  label: string;
  classNames?: string;
  applyColor: (label: string, color: string) => void;
  applyNoColor?: (label: string) => void;
  applyResetColor?: (label: string) => void;
  removeColor?: (label: string) => void;
  noMargin?: boolean;
  mode: ColorPickingSupport;
}) {
  const { label, colorCode, applyNoColor, mode, removeColor } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [colorPickerColor, setColorPickerColor] = useState(colorCode);
  const colorCells = useMemo(
    () => props.swatchColors.map(colorToCell),
    [props.swatchColors]
  );

  const boxSize = 16;

  const [tab, setTab] = useState<ColorPickingType>("swatch");

  const isPicker = mode === "picker" || (mode === "both" && tab === "picker");
  return (
    <CustomDropdown
      onClick={(show) => setIsOpen(show)}
      isOpen={isOpen}
      onDismiss={() => setIsOpen(false)}
      containerClassNames="custom-dropdown-color-options"
      classNames={classNames(
        props.classNames,
        props.noMargin ? "no-margin" : ""
      )}
      label={
        <div
          onClick={() => setIsOpen(!isOpen)}
          style={
            // colorCode
            // If colorCode is undefined,
            // show a white box with a diagonal red line through to
            // indicate no color
            !defined(colorCode)
              ? {
                  backgroundColor: "white",
                  height: boxSize,
                  width: boxSize,
                  border: "1px solid #d8d8d8",
                  position: "relative",
                }
              : {
                  backgroundColor: colorCode,
                  height: boxSize,
                  width: boxSize,
                }
          }
        >
          {!defined(colorCode) && <NoColorBox boxSize={boxSize}></NoColorBox>}
        </div>
      }
      content={
        <div className="chart-appearance-color-picker-dropdown">
          {mode === "both" && (
            <Pivot
              className="pivot-menu"
              getTabId={(itemKey) => itemKey}
              selectedKey={tab}
              onLinkClick={(item) => {
                if (!defined(item)) {
                  return;
                }
                setTab(item.props.itemKey as any);
              }}
            >
              <PivotItem itemKey={"swatch"} headerText="Palett" />
              <PivotItem itemKey={"swatch_extended"} headerText="Skalor" />
              <PivotItem itemKey={"picker"} headerText="Färgväljare" />
            </Pivot>
          )}

          {(mode === "swatch" || (mode === "both" && tab === "swatch")) && (
            <SwatchColorPicker
              onChange={(ev, id) => {
                if (!defined(id)) {
                  throw new Error("No color ID defined");
                }
                props.applyColor(label, id);
                setIsOpen(false);
              }}
              columnCount={6}
              cellHeight={30}
              cellWidth={30}
              cellShape="square"
              colorCells={colorCells}
            ></SwatchColorPicker>
          )}
          {(mode === "swatch" ||
            (mode === "both" && tab === "swatch_extended")) && (
            <SwatchColorPicker
              onChange={(ev, id) => {
                if (!defined(id)) {
                  throw new Error("No color ID defined");
                }
                props.applyColor(label, id);
                setIsOpen(false);
              }}
              columnCount={6}
              cellHeight={30}
              cellWidth={30}
              cellShape="square"
              colorCells={colorCellsExtendedSwatch}
            ></SwatchColorPicker>
          )}
          {isPicker && (
            <ColorPicker
              onChange={(ev, color) => {
                const { r, g, b, a } = color;
                setColorPickerColor(`rgba(${r},${g},${b},${(a ?? 100) / 100})`);
              }}
              showPreview
              color={colorPickerColor ?? "white"}
              alphaType="alpha"
              strings={{
                alpha: "Opacitet",
                red: "Röd",
                green: "Grön",
                blue: "Blå",
                hex: "Hexkod",
                alphaError: "Måste vara mellan 0 och 100.",
                redError: "Måste vara mellan 0 och 255",
                greenError: "Måste vara mellan 0 och 255",
                blueError: "Måste vara mellan 0 och 255",
                hexError: "Måste vara mellan 3 och 6 tecken.",
              }}
            ></ColorPicker>
          )}
          {defined(applyNoColor) && (
            <section>
              <NoColorOption
                onClick={() => {
                  applyNoColor(label);
                  setIsOpen(false);
                }}
              ></NoColorOption>
            </section>
          )}
          {defined(removeColor) && (
            <section>
              <Button
                intent="danger"
                title="Ta bort färg"
                onClick={() => {
                  removeColor(label);
                  setIsOpen(false);
                }}
              ></Button>
            </section>
          )}
          {isPicker && (
            <ButtonsFooter>
              <ButtonsFooterLeft>
                <>
                  <Button
                    title="Avbryt"
                    onClick={() => setIsOpen(false)}
                  ></Button>
                  {defined(props.applyResetColor) && (
                    <Button
                      title="Återställ"
                      onClick={() => {
                        props.applyResetColor?.(label);
                        setIsOpen(false);
                      }}
                    />
                  )}
                </>
              </ButtonsFooterLeft>
              <ButtonsFooterRight>
                <Button
                  disabled={!defined(colorPickerColor)}
                  intent="primary"
                  title="Välj"
                  onClick={() => {
                    if (!defined(colorPickerColor)) {
                      return;
                    }
                    props.applyColor(label, colorPickerColor);
                    setIsOpen(false);
                  }}
                ></Button>
              </ButtonsFooterRight>
            </ButtonsFooter>
          )}
        </div>
      }
    ></CustomDropdown>
  );
}

function NoColorBox(props: { boxSize: number }) {
  const boxSize = props.boxSize;
  return (
    <svg
      height={boxSize}
      width={boxSize}
      style={{ position: "absolute", top: 0, left: 0 }}
    >
      <line
        x1="0"
        y1="0"
        x2={boxSize}
        y2={boxSize}
        style={{ stroke: "red", strokeWidth: 1 }}
      />
    </svg>
  );
}

function customThemeSpecToApplied(
  spec: CustomThemeSpec
): CustomThemeSpecApplied {
  // Create a new object with only the properties conforming to the applied type
  return {
    id: spec.id,
    colors: spec.colors,
    customOutputSettings: spec.customOutputSettings,
    useDefaultSpecialColors: spec.useDefaultSpecialColors,
    customAxesColor: spec.customAxesColor,
    customBgColor: spec.customBgColor,
    customGridLinesColor: spec.customGridLinesColor,
    customHeadersColor: spec.customHeadersColor,
    customLabelsColor: spec.customLabelsColor,
  };
}

// Component that allows the user to switch between color schemes.
// Contains a single dropdown listing all the options
function SwitchTheme(props: {
  handleResetColorScheme: () => void;
  appearanceSettings: ExtendedAppearanceSettings;
  handleSwitchColorScheme: (colors: CustomThemeSpecApplied) => void;
  startingTheme?: CustomThemeSpecApplied;
  currentTheme: CustomThemeSpecApplied;
}) {
  const localTheme = props.startingTheme;

  const options = useMemo(() => {
    const standard = getInfostatStandardTheme();
    let optsList: IDropdownOption[] = [
      {
        key: "standard",
        text: standard.name,
        data: customThemeSpecToApplied(standard),
      },
    ];

    for (const orgTheme of props.appearanceSettings.colorPalettesSpecial
      .orgThemes ?? []) {
      optsList.push({
        key: orgTheme.id,
        text: orgTheme.name,
        data: customThemeSpecToApplied(orgTheme),
      });
    }
    for (const userPalette of props.appearanceSettings.colorPalettesSpecial
      .userThemes ?? []) {
      optsList.push({
        key: userPalette.id,
        text: userPalette.name,
        data: customThemeSpecToApplied(userPalette),
      });
    }

    for (const builtInTheme of builtInSecondaryThemes) {
      optsList.push({
        key: builtInTheme.id,
        text: builtInTheme.name,
        data: customThemeSpecToApplied(builtInTheme),
      });
    }

    if (
      defined(localTheme) &&
      !optsList.some(
        (o) => (o.data as CustomThemeSpecApplied).id === localTheme.id
      )
    ) {
      optsList.unshift({
        key: "-",
        text: "Anpassad",
        data: localTheme,
      });
    } else if (
      !optsList.some(
        (o) => (o.data as CustomThemeSpecApplied).id === props.currentTheme.id
      )
    ) {
      optsList.unshift({
        key: "-",
        text: "Anpassad",
        data: props.currentTheme,
      });
    }

    return optsList;
  }, [
    localTheme,
    props.appearanceSettings.colorPalettesSpecial.orgThemes,
    props.appearanceSettings.colorPalettesSpecial.userThemes,
    props.currentTheme,
  ]);

  const [selectedPalette, setSelectedPalette] = useState(() => {
    return (
      options.find(
        (o) => (o.data as CustomThemeSpecApplied).id === props.currentTheme.id
      )?.key ?? "-"
    );
  });

  if (options.length < 2) {
    return null;
  }

  const renderOptionWithColors = (
    option: IDropdownOption | undefined,
    maxColors = 10
  ) => {
    if (!defined(option)) {
      return null;
    }
    const key = option.key;
    if (typeof key !== "string") {
      return null;
    }
    const palette = option.data as CustomThemeSpecApplied;
    if (!defined(palette)) {
      logger.error("No data found for option");
      return null;
    }

    return (
      <div className="color-scheme-list-option">
        <div>
          <span>{option.text}</span>
        </div>
        <div className="colors">
          {palette.colors.slice(0, maxColors).map((color, index) => (
            <div
              key={index}
              style={{
                backgroundColor: color,
                width: 12,
                height: 12,
                marginLeft: index > 0 ? 5 : 0,
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="switch-color-scheme-container">
      <Dropdown
        label="Tema"
        className="input-default-width"
        selectedKey={selectedPalette}
        options={options}
        dropdownWidth={"auto"}
        onRenderTitle={(items?: IDropdownOption[]) => {
          return (
            <div className="selected-color-scheme">
              <div className="colors">
                {props.currentTheme.colors.slice(0, 10).map((c, index) => (
                  <div
                    key={index + "" + props.currentTheme.colors.length}
                    style={{
                      backgroundColor: c,
                      width: 12,
                      height: 12,
                      marginLeft: index > 0 ? 5 : 0,
                    }}
                  ></div>
                ))}
              </div>
            </div>
          );
        }}
        onRenderOption={(option) => {
          return renderOptionWithColors(option);
        }}
        onChange={(e, option) => {
          if (!defined(option)) {
            return;
          }
          const key = option.key;
          if (typeof key !== "string") {
            return;
          }
          const data = option.data;
          if (!defined(data)) {
            logger.error("No data found for option");
            return;
          }
          setSelectedPalette(key);
          props.handleSwitchColorScheme(data);
        }}
      ></Dropdown>
      <Button
        disabled={selectedPalette === "-"}
        title={null}
        icon="reset"
        onClick={props.handleResetColorScheme}
      ></Button>
    </div>
  );
}

function NoColorOption(props: { onClick: () => void }) {
  return (
    <div className="no-color" onClick={props.onClick}>
      <div className="no-color-box">
        <NoColorBox boxSize={30}></NoColorBox>
      </div>
      <label onClick={props.onClick}>Ingen färg</label>
    </div>
  );
}
