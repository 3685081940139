import { createRoot } from "react-dom/client";
import { useCallback, useState } from "react";
import { DirectionalHint, ThemeProvider } from "@fluentui/react";

import { defined } from "../../../../../../lib/core/defined";
import { Button } from "../../../../../../components/Button";
import { areaTypesMixed, ClearGeos } from "../shared";
import "./SelectionPanel.scss";
import { assertNever } from "../../../../../../lib/core/assert";
import { FluentIcon } from "../../../../../../components/Icons";
import { FoldoutPanelControlled } from "../../../../../../components/FoldoutPanel";
import { classNames } from "../../../../../../lib/core/classNames";
import { fluentUITheme } from "../../../../../../lib/application/theme";
import { withLoadingState } from "./withLoadingState";
import {
  SelectedAreaDesoMode,
  MicroMapSettings,
  CustomRegion,
  MicroGeoSelections,
  SelectedAreaRegsoMode,
  MicroMapView,
} from "../../../../../../lib/application/state/stats/document-core/core-micro";
import { AlertBox } from "../../../../../../components/AlertBox";
import { getText } from "../../../../../../lib/application/strings";
import { GeoState } from "../useGeoState";
import { IconContextMenu } from "../../../../../../components/IconContextMenu";
import { SimpleInputModal } from "../../../../../../components/SimpleInputModal";
import { config } from "../../../../../../config";
import { validateNonEmptyString } from "../../../../../../lib/application/validation/string";
import { HttpResult } from "../../../../../../lib/infra/HttpResult";
import { GeoQuickSelect } from "./GeoQuickSelect";
import { GeoTypeMicro } from "../../../../../../lib/domain/geography";
import { MicroGeoTree } from "../../../../../../lib/application/stats/shared/MicroGeoTree";

export class SelectionPanelControl {
  private _container?: HTMLDivElement;
  private _root?: ReturnType<typeof createRoot>;

  constructor(
    private _clear: ClearGeos,
    private _deselectUnit: (
      id: number,
      prevSelectedAreas: MicroGeoSelections
    ) => void,
    private _deselectGroup: (groupId: string) => void
  ) {}

  render(
    view: MicroMapView,
    isLoading: boolean,
    isEditingCard: boolean,
    mapSettings: MicroMapSettings,
    expandSelectionPanel: boolean,
    toggleExpandSelectionPanel: () => void,
    expandQuickSelectPanel: boolean,
    toggleExpandQuickSelectPanel: () => void,
    geoState: GeoState,
    geoTree: MicroGeoTree | undefined,
    handleSaveRegion: (groupId: string) => Promise<HttpResult<unknown>>,
    handleEditLocalRegionName: (groupId: string, name: string) => void,
    handleDeleteLocalGroup: (groupId: string) => void,
    handleDeleteSavedGroup: (groupId: string) => Promise<HttpResult<unknown>>,
    handleCreateRegion: (areas: MicroGeoSelections) => void,
    handleSelectUserDefinedRegion: (groupId: string) => void,
    handleSelectAllInMunicipality: (
      currentGeoType: GeoTypeMicro,
      municipality: string
    ) => void,
    handleSelectAllInRegion: (
      currentGeoType: GeoTypeMicro,
      region: string
    ) => void
  ) {
    const container = this._container;
    if (!defined(container)) {
      return;
    }

    if (!this._root) {
      this._root = createRoot(container);
    }

    this._root.render(
      <SelectionPanel
        view={view}
        isEditingCard={isEditingCard}
        handleCreateRegion={handleCreateRegion}
        handleDeleteLocalRegion={handleDeleteLocalGroup}
        handleDeleteSavedRegion={handleDeleteSavedGroup}
        handleEditLocalGroupName={handleEditLocalRegionName}
        handleSaveGroup={handleSaveRegion}
        containerClass="mapboxgl-ctrl"
        geoState={geoState}
        geoTree={geoTree}
        mapSettings={mapSettings}
        isLoading={isLoading}
        expandSelectionPanel={expandSelectionPanel}
        toggleExpandSelectionPanel={toggleExpandSelectionPanel}
        expandQuickSelectPanel={expandQuickSelectPanel}
        toggleExpandQuickSelectPanel={toggleExpandQuickSelectPanel}
        handleSelectAllInMunicipality={handleSelectAllInMunicipality}
        handleSelectAllInRegion={handleSelectAllInRegion}
        clear={this._clear}
        handleSelectUserDefinedRegion={handleSelectUserDefinedRegion}
        handleDeselectUnit={this._deselectUnit}
        handleDeselectGroup={this._deselectGroup}
      />
    );
  }

  onAdd(map: mapboxgl.Map) {
    const container = document.createElement("div");
    container.className = "mapbox-geo-panel";
    this._container = container;
    return container;
  }

  onRemove() {
    setTimeout(() => {
      this._root?.unmount();
      this._root = undefined;
    });
  }
}

const SelectionPanel = withLoadingState(SelectionPanelInner);

export function SelectionPanelInner(props: {
  view: MicroMapView;
  mapSettings: MicroMapSettings;
  isEditingCard: boolean;
  expandSelectionPanel: boolean;
  toggleExpandSelectionPanel: () => void;
  expandQuickSelectPanel: boolean;
  toggleExpandQuickSelectPanel: () => void;
  geoState: GeoState;
  geoTree: MicroGeoTree | undefined;

  handleSelectUserDefinedRegion: (groupId: string) => void;
  handleDeselectUnit: (
    id: number,
    prevSelectedAreas: MicroGeoSelections
  ) => void;
  handleCreateRegion: (areas: MicroGeoSelections) => void;

  handleSelectAllInMunicipality: (
    geoType: GeoTypeMicro,
    municipality: string
  ) => void;
  handleSelectAllInRegion: (geoType: GeoTypeMicro, region: string) => void;
  handleDeselectGroup: (groupId: string) => void;
  handleSaveGroup: (groupId: string) => void;
  handleEditLocalGroupName: (regionId: string, name: string) => void;
  handleDeleteLocalRegion: (groupId: string) => void;
  handleDeleteSavedRegion: (groupId: string) => void;
  clear: ClearGeos;
}) {
  const {
    handleDeselectUnit: handleDeselectUnitOuter,
    handleEditLocalGroupName,
    geoTree,
    geoState,
    view,
  } = props;

  const {
    userDefinedRegions,
    userDefinedRegionsToggle,
    storedRegions,
    storedRegionsToggle,
    selectedAreas,
  } = geoState;
  const [showUserDefinedRegions, toggleUserDefinedRegions] =
    userDefinedRegionsToggle;
  const [showStoredRegions, toggleStoredRegions] = storedRegionsToggle;

  const handleDeselectDeso = useCallback(
    (id: number) => {
      if (!defined(selectedAreas)) {
        return;
      }
      handleDeselectUnitOuter(id, selectedAreas);
    },
    [handleDeselectUnitOuter, selectedAreas]
  );

  const handleEditName = useCallback(
    (groupId: string, name: string) => {
      handleEditLocalGroupName(groupId, name);
    },
    [handleEditLocalGroupName]
  );

  const handleSelectAllInMunicipality = useCallback(
    (municipality: string) => {
      const geoType = selectedAreas?.type;
      if (!defined(geoType)) {
        return;
      }

      props.handleSelectAllInMunicipality(geoType, municipality);
    },
    [props, selectedAreas?.type]
  );

  const handleSelectAllInRegion = useCallback(
    (region: string) => {
      const geoType = selectedAreas?.type;
      if (!defined(geoType)) {
        return;
      }
      props.handleSelectAllInRegion(geoType, region);
    },
    [props, selectedAreas?.type]
  );

  return (
    <ThemeProvider theme={fluentUITheme}>
      {view === "map-select" && props.isEditingCard && (
        <div className="micro-selection-panel">
          {geoTree && (
            <FoldoutPanelControlled
              title={<h3>Snabbval</h3>}
              size="md"
              isOpen={props.expandQuickSelectPanel}
              toggleOpen={props.toggleExpandQuickSelectPanel}
            >
              <div className="panel-container">
                <GeoQuickSelect
                  handleSelectAllInMunicipality={handleSelectAllInMunicipality}
                  handleSelectAllInRegion={handleSelectAllInRegion}
                  geographies={geoTree}
                ></GeoQuickSelect>
              </div>
            </FoldoutPanelControlled>
          )}
          <FoldoutPanelControlled
            title={<h3>Valda områden</h3>}
            size="md"
            isOpen={props.expandSelectionPanel}
            toggleOpen={props.toggleExpandSelectionPanel}
          >
            <div className="panel-container">
              {storedRegions.numTotalAvailable > 0 && (
                <section>
                  <FoldoutPanelControlled
                    isOpen={showStoredRegions}
                    toggleOpen={toggleStoredRegions}
                    size="sm"
                    title={
                      <strong>
                        Sparade ({storedRegions.numTotalAvailable})
                      </strong>
                    }
                  >
                    {storedRegions.userDefined.map((r) => (
                      <span key={r.id}>{r.name}</span>
                    ))}
                  </FoldoutPanelControlled>
                </section>
              )}
              {userDefinedRegions.length > 0 && (
                <section>
                  <FoldoutPanelControlled
                    isOpen={showUserDefinedRegions}
                    toggleOpen={toggleUserDefinedRegions}
                    size="sm"
                    title={<strong>Egna ({userDefinedRegions.length})</strong>}
                  >
                    {userDefinedRegions.map((area, index) => (
                      <AvailableUserDefinedRegion
                        disabled={
                          selectedAreas?.selected.some(
                            (s) =>
                              s.type === "user-defined" && s.groupId === area.id
                          ) ?? false
                        }
                        region={area}
                        key={area.id}
                        name={area.name}
                        handleDelete={props.handleDeleteLocalRegion}
                        handleSave={props.handleSaveGroup}
                        handleEditName={handleEditName}
                        handleSelect={props.handleSelectUserDefinedRegion}
                      ></AvailableUserDefinedRegion>
                    ))}
                  </FoldoutPanelControlled>
                </section>
              )}

              <div>
                {/* TODO: reinstate when grouping feature is built out */}
                {/* <Button
                    small
                    disabled={
                      defined(selectedAreas) &&
                      selectedAreas.selected.length < 2
                    }
                    title="Skapa grupp"
                    onClick={() => {
                      if (!defined(selectedAreas)) {
                        return;
                      }
                      props.handleCreateRegion(selectedAreas);
                    }}
                  ></Button> */}
                <Button
                  small
                  disabled={selectedAreas?.selected.length === 0}
                  title="Rensa"
                  onClick={() => {
                    if (!defined(selectedAreas)) {
                      return;
                    }
                    props.clear(selectedAreas);
                  }}
                ></Button>
              </div>

              {defined(selectedAreas) && areaTypesMixed(selectedAreas) && (
                <AlertBox intent="warning" className="margin-top-sm">
                  <span>{getText("micro-mixed-area-selection")}</span>
                </AlertBox>
              )}

              {selectedAreas?.selected.length === 0 ? (
                <div>
                  <em>Inga valda</em>
                </div>
              ) : (
                selectedAreas?.selected.map((area) => (
                  <RenderedSelectedArea
                    userDefinedRegion={
                      area.type === "user-defined"
                        ? userDefinedRegions.find((r) => r.id === area.groupId)
                        : undefined
                    }
                    deselectUnit={handleDeselectDeso}
                    deselectGroup={props.handleDeselectGroup}
                    area={area}
                    key={
                      area.type === "user-defined"
                        ? area.groupId
                        : area.props.id
                    }
                  ></RenderedSelectedArea>
                ))
              )}
            </div>
          </FoldoutPanelControlled>
        </div>
      )}
    </ThemeProvider>
  );
}

function RenderedSelectedArea(props: {
  userDefinedRegion?: CustomRegion;
  area: SelectedAreaDesoMode | SelectedAreaRegsoMode;
  deselectUnit: (id: number) => void;
  deselectGroup: (groupId: string) => void;
}) {
  const area = props.area;
  switch (area.type) {
    case "regso":
      return (
        <Deselectable
          deselect={() => props.deselectUnit(area.props.id)}
          name={area.props.regso_label}
        ></Deselectable>
      );
    case "deso":
      return (
        <Deselectable
          deselect={() => props.deselectUnit(area.props.id)}
          name={area.props.deso_label}
        ></Deselectable>
      );
    case "user-defined":
      return (
        <Deselectable
          deselect={() => props.deselectGroup(area.groupId)}
          name={area.groupName}
        ></Deselectable>
      );
    default:
      assertNever(area);
  }
}

const iconSize = "sm";

function AvailableUserDefinedRegion(props: {
  disabled: boolean;
  region: CustomRegion;
  handleSelect: (groupId: string) => void;
  handleEditName: (groupId: string, name: string) => void;
  handleSave: (groupId: string) => void;
  handleDelete: (groupId: string) => void;
  name: string;
}) {
  const [isEditingName, setIsEditingName] = useState(false);
  const regionId = props.region.id;

  const handleEditName = useCallback(() => {
    setIsEditingName(true);
  }, []);

  return (
    <>
      {isEditingName && (
        <SimpleInputModal
          title="Ändra namn"
          input={{
            initialValue: props.region.name,
            label: "Namn",
            maxLength: config.valueLabelMaxLength,
            validate: validateNonEmptyString,
          }}
          onSaveInput={(name) => {
            props.handleEditName(props.region.id, name);
            setIsEditingName(false);
          }}
          onDismiss={() => setIsEditingName(false)}
          autoSelectText={true}
        ></SimpleInputModal>
      )}
      <div
        className={classNames(
          "user-defined-region",
          props.disabled ? "disabled" : ""
        )}
      >
        <div className="left">
          <FluentIcon
            onClick={
              props.disabled ? undefined : () => props.handleSelect(regionId)
            }
            size={iconSize}
            name="plus"
          ></FluentIcon>
          <span>{props.name}</span>
        </div>
        <div className="right">
          <IconContextMenu
            size={iconSize}
            icon="cog"
            directionalHint={DirectionalHint.bottomRightEdge}
            items={[
              {
                key: "change-name",
                text: "Ändra namn",
                onClick: handleEditName,
              },
              // {
              //   key: "save",
              //   text: "Spara",
              //   onClick: () => props.handleSave(regionId),
              // },
              {
                key: "delete",
                text: "Ta bort",
                onClick: () => props.handleDelete(regionId),
              },
            ]}
          ></IconContextMenu>
        </div>
      </div>
    </>
  );
}

function Deselectable(props: { deselect: () => void; name: string }) {
  return (
    <div className="deselectable-region">
      <div className="left">
        <FluentIcon
          onClick={props.deselect}
          size={iconSize}
          name="minus"
        ></FluentIcon>
        <span>{props.name}</span>
      </div>
    </div>
  );
}
