import { Checkbox, Dropdown, Slider, TextField } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import * as d3scale from "d3-scale";
import { isEqual, range } from "lodash";

import { Button } from "../../../../../../components/Button";
import {
  ButtonsFooter,
  ButtonsFooterRight,
} from "../../../../../../components/ButtonContainers";
import {
  FluentModalBody,
  FluentModalFooter,
  FluentModalTall,
} from "../../../../../../components/Modal";
import { MicroMapSettings } from "../../../../../../lib/application/state/stats/document-core/core-micro";
import {
  extendedStandardColors,
  standardColors,
} from "../../../../../../lib/application/stats/shared/core/colors/colors";
import { classNames } from "../../../../../../lib/core/classNames";
import { defined } from "../../../../../../lib/core/defined";
import {
  DEFAULT_COLOR_SCHEME_MICRO,
  getColorRamp,
  getDecimalColorScaleAuto,
  makeColorGetterMicro,
  MicroColorScheme,
  microColorSchemes,
} from "../../../../../../lib/domain/micro/colors";
import { HandleUpdateSetting } from "../shared";

import { HelpIcon } from "../../../../../../components/HelpIcon";
import { singleMicroCardQuery } from "../../../../../../lib/application/state/stats/document-core/queries/microCard";
import { GeometryType } from "../../../../../../lib/application/state/stats/document-style/definitions";
import { useApplyGeoStyle } from "../mapColorHooks";
import { microLineStyleDashArrayOptions } from "../../../../../../lib/application/stats/shared/core/colors/colorSchemes";
import { FoldoutPanelControlled } from "../../../../../../components/FoldoutPanel";
import { useAppMessages } from "../../../../../../lib/application/hooks/useAppMessages";
import { logger } from "../../../../../../lib/infra/logging";
import {
  significantFiguresRounderLocale,
  swedishLocaleSimple,
} from "../../../../../../lib/application/stats/format";
import {
  displayNumericalRange as displayNumericalRangeBase,
  NumericalRange,
} from "../../../../../../lib/application/stats/map/types";
import { MicroDataset } from "../../../../../../lib/application/stats/datasets/MicroDataset";
import {
  findDistinctiveFormatter,
  getColorScaleManual,
  getIntegerColorScaleAuto,
} from "../../../../../../lib/application/stats/map/scales";

import "./MicroSettingsModal.scss";
import { DocCardMicro } from "../../../../../../lib/application/state/stats/document-core/core";
import { ColorDropdown } from "../../card_general/ColorOptionsDialog";
import { parseSwedishNumber } from "../../../../../../lib/core/numeric/parseNum";
import { useToggle } from "../../../../../../lib/application/hooks/useToggle";

type ColorSet = {
  dataGroupId: string;
  geometry: GeometryType;
  label: string;
  fill?: string;
  fillOpacity?: number;
  lineDashArray?: number[];
  border?: string;
};

export function MicroSettingsModal(props: {
  cardId: string;
  settings: MicroMapSettings;
  primaryMeasureIsComputed: boolean;
  handleUpdateSetting: HandleUpdateSetting;
  handleUpdateSettings: (
    f: (settings: MicroMapSettings) => MicroMapSettings
  ) => void;
  handleClose: () => void;
}) {
  const card = useRecoilValue(
    singleMicroCardQuery({ cardStateId: props.cardId })
  );
  const applyStyle = useApplyGeoStyle(props.cardId);

  const [advancedPanelOpen, toggleAdvancedPanelOpen] = useToggle(
    defined(props.settings.manualBreakpoints)
  );

  const colors: ColorSet[] = useMemo(() => {
    const microMapState = card.data.loadedData?.microMapState;

    const colorSets: ColorSet[] = [];
    microMapState?.loadedLines?.forEach((line) => {
      colorSets.push({
        dataGroupId: line.id,
        geometry: "line",
        label: line.style.label,
        border: line.style.border,
        fill: line.style.fill,
        lineDashArray: line.style.lineDashArray,
      });
    });
    microMapState?.loadedPoints?.forEach((point) => {
      colorSets.push({
        dataGroupId: point.id,
        geometry: "point",
        label: point.style.label,
        border: point.style.border,
        fill: point.style.fill,
        fillOpacity: point.style.fillOpacity,
      });
    });
    microMapState?.loadedPolygons?.forEach((polygon) => {
      colorSets.push({
        dataGroupId: polygon.id,
        geometry: "polygon",
        label: polygon.style.label,
        border: polygon.style.border,
        fill: polygon.style.fill,
        fillOpacity: polygon.style.fillOpacity,
        lineDashArray: polygon.style.lineDashArray,
      });
    });
    return colorSets;
  }, [card.data.loadedData?.microMapState]);

  const latentColors: ColorSet[] = useMemo(() => {
    const latentState = card.data.loadedData?.microLatentStyles;
    if (!defined(latentState)) {
      return [];
    }
    return latentState.map((latent) => {
      return {
        dataGroupId: latent.id,
        geometry: latent.type,
        ...latent.style,
      };
    });
  }, [card.data.loadedData?.microLatentStyles]);

  const handleApplyStyle = useCallback(
    (colorSet: ColorSet) => {
      applyStyle(colorSet.geometry, colorSet.dataGroupId, {
        label: colorSet.label,
        fill: colorSet.fill,
        fillOpacity: colorSet.fillOpacity,
        lineDashArray: colorSet.lineDashArray,
        border: colorSet.border,
      });
    },
    [applyStyle]
  );

  const handleToggleLocalColorScheme = useCallback(
    (on: boolean) => {
      props.handleUpdateSetting("localZRange", on);
    },
    [props]
  );

  const loadedMicroMapData =
    card.data.loadedData?.microMapState?.loadedMicroMapData;
  const loadedMicroDataset = card.data.loadedData?.primaryDataset;

  return (
    <FluentModalTall
      containerClassName="micro-settings-modal"
      onClose={props.handleClose}
      width="md"
      isOpen={true}
      title="Inställningar"
    >
      <FluentModalBody>
        <>
          {((defined(colors) && colors.length > 0) ||
            (defined(latentColors) && latentColors.length > 0)) && (
            <div className="section">
              <h3>Färg och stil</h3>
              <table className="current-colors-geo-micro">
                <thead>
                  <tr>
                    <th>Mått/filter</th>
                    <th>Fyllning</th>
                    <th>Fyllningsopacitet</th>
                    <th>Kontur</th>
                    <th>Linjestil</th>
                  </tr>
                </thead>
                <tbody>
                  {colors.map((c, index) => {
                    return (
                      <ColorSetOptions
                        key={c.dataGroupId}
                        handleApplyStyle={handleApplyStyle}
                        colorSet={c}
                      ></ColorSetOptions>
                    );
                  })}
                  {latentColors.map((c) => {
                    return (
                      <ColorSetOptions
                        key={c.dataGroupId}
                        handleApplyStyle={handleApplyStyle}
                        colorSet={c}
                      ></ColorSetOptions>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          {defined(loadedMicroMapData) && (
            <div className="section">
              <h3>Färgskala för områden</h3>
              <section className="local-z-range">
                <Checkbox
                  disabled={
                    props.primaryMeasureIsComputed ||
                    defined(props.settings.manualBreakpoints)
                  }
                  checked={props.settings.localZRange ?? false}
                  onChange={(ev, checked) => {
                    if (!defined(checked)) {
                      return;
                    }
                    handleToggleLocalColorScheme(checked);
                  }}
                  label={`Lokal färgsättning ${
                    defined(props.settings.manualBreakpoints)
                      ? " (inaktiverat när egna brytpunkter används)"
                      : ""
                  }`}
                ></Checkbox>
                <HelpIcon tooltipText="Dina valda områden kommer att färgas enligt en skala där områdena med högst och lägst värden färgas med sista respektiva första färgen på färgskalan och resten passas in däremellan."></HelpIcon>
              </section>
              <section>
                <Checkbox
                  label="Ingen fyllnadsfärg, endast konturer"
                  checked={props.settings.bordersOnlyNoFill ?? false}
                  onChange={(ev, checked) => {
                    if (!defined(checked)) {
                      return;
                    }
                    props.handleUpdateSetting("bordersOnlyNoFill", checked);
                  }}
                ></Checkbox>
              </section>
              {microColorSchemes.map((scheme) => {
                return (
                  <div key={scheme} className="color-scheme-row">
                    <div
                      className={classNames(
                        "color-scheme",
                        scheme === props.settings.colorScheme ? "selected" : ""
                      )}
                      onClick={() =>
                        props.handleUpdateSetting("colorScheme", scheme)
                      }
                    >
                      <ColorSchemeIndicator scheme={scheme} />
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {defined(loadedMicroDataset) && (
            <AdvancedScaleSettings
              card={card}
              advancedPanelOpen={advancedPanelOpen}
              toggleAdvancedPanelOpen={toggleAdvancedPanelOpen}
              dataset={loadedMicroDataset}
              settings={props.settings}
              updateSettings={props.handleUpdateSettings}
            />
          )}
        </>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterRight>
            <Button title="Stäng" onClick={props.handleClose}></Button>
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModalTall>
  );
}

function ColorSchemeIndicator(props: { scheme: MicroColorScheme }) {
  const canvasContainerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const current = canvasContainerRef?.current;
    if (!defined(current)) {
      return;
    }

    const rampSteps = 100;
    const colorRamp = getColorRamp(props.scheme, rampSteps);
    const canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 100;
    canvas.style.width = "100%";
    canvas.style.height = "20px";
    const ctx = canvas.getContext("2d");
    if (!defined(ctx)) {
      throw new Error("Could not get context");
    }
    for (let i = 0; i < rampSteps; i++) {
      ctx.fillStyle = colorRamp[i];
      ctx.fillRect(i, 0, 1, 100);
    }

    current.appendChild(canvas);

    return () => {
      current.removeChild(canvas);
    };
  }, [props.scheme]);

  return (
    <div ref={canvasContainerRef} className="color-scheme-container"></div>
  );
}

function ColorSetOptions(props: {
  disabled?: boolean;
  handleApplyStyle: (colorSet: ColorSet) => void;
  colorSet: ColorSet;
}) {
  const colorSet = props.colorSet;
  const handleApplyStyle = props.handleApplyStyle;
  const { label, fill, border, geometry } = colorSet;
  const [localFillOpacity, setLocalFillOpacity] = useState(
    props.colorSet.fillOpacity
  );
  const currentFillOpacity = useMemo(
    () => props.colorSet.fillOpacity,
    [props.colorSet.fillOpacity]
  );

  useEffect(() => {
    if (!defined(localFillOpacity) || localFillOpacity === currentFillOpacity) {
      return;
    }
    const handle = setTimeout(() => {
      handleApplyStyle({
        ...colorSet,
        fillOpacity: localFillOpacity,
      });
    }, 300);
    return () => clearTimeout(handle);
  }, [currentFillOpacity, localFillOpacity, colorSet, handleApplyStyle]);

  return (
    <tr>
      <td>{label}</td>
      <td>
        {(geometry === "point" ||
          geometry === "line" ||
          geometry === "polygon") && (
          <ColorDropdown
            label="Fyllning"
            applyColor={(label, color) =>
              handleApplyStyle({ ...props.colorSet, fill: color })
            }
            applyNoColor={() =>
              handleApplyStyle({ ...props.colorSet, fill: undefined })
            }
            mode={"both"}
            swatchColors={standardColors.concat(extendedStandardColors)}
            colorCode={fill}
          />
        )}
      </td>
      <td>
        {(geometry === "polygon" || geometry === "point") && defined(fill) && (
          <Slider
            className="opacity-slider"
            min={0}
            max={1}
            step={0.1}
            value={localFillOpacity ?? 1}
            valueFormat={(value) => `${Math.round(value * 100)}%`}
            onChange={(value) => {
              setLocalFillOpacity(value);
            }}
          />
        )}
      </td>
      <td>
        {geometry === "polygon" && (
          <ColorDropdown
            label="Kontur"
            applyColor={(label, color) =>
              handleApplyStyle({ ...props.colorSet, border: color })
            }
            applyNoColor={() =>
              handleApplyStyle({ ...props.colorSet, border: undefined })
            }
            mode={"both"}
            swatchColors={standardColors.concat(extendedStandardColors)}
            colorCode={border}
          />
        )}
      </td>
      <td>
        {(geometry === "line" ||
          (geometry === "polygon" && defined(border))) && (
          <LineStyleDropdown
            selectedOption={props.colorSet.lineDashArray}
            handleOutputSettingChange={(value) => {
              handleApplyStyle({ ...props.colorSet, lineDashArray: value });
            }}
          ></LineStyleDropdown>
        )}
      </td>
    </tr>
  );
}

interface LineStyleDropdownProps {
  selectedOption?: number[];
  handleOutputSettingChange: (value?: number[]) => void;
}
export const LineStyleDropdown: React.FC<LineStyleDropdownProps> = ({
  selectedOption,
  handleOutputSettingChange,
}) => {
  const options = useMemo(() => microLineStyleDashArrayOptions, []);

  const getKey = useCallback((o?: number[]) => {
    return (o ?? microLineStyleDashArrayOptions[0]).join("-") ?? "";
  }, []);

  return (
    <Dropdown
      style={{ minWidth: "100px" }}
      options={options.map((option) => ({
        key: getKey(option),
        text: "",
      }))}
      selectedKey={getKey(selectedOption)}
      onChange={(e, option) => {
        const key = option?.key;
        if (!defined(key)) {
          return;
        }
        const selected = options.find((o) => getKey(o) === key);
        if (!defined(selected)) {
          return;
        }
        handleOutputSettingChange(selected);
      }}
      onRenderTitle={(selectedOptions) => {
        const option = selectedOptions?.[0];
        if (!defined(option)) {
          return null;
        }
        const found = options.find((o) => getKey(o) === option?.key);
        if (!defined(found)) {
          return null;
        }
        return onRenderLineStyleOption(found);
      }}
      onRenderOption={(option) => {
        const found = options.find((o) => getKey(o) === option?.key);
        if (!defined(found)) {
          return null;
        }
        return onRenderLineStyleOption(found);
      }}
    />
  );
};
function onRenderLineStyleOption(item?: number[]): JSX.Element {
  return (
    <svg viewBox="0 0 40 1">
      <line
        x1="0"
        y1="0"
        x2="40"
        y2="0"
        strokeDasharray={item?.join(",") ?? "1,0"}
        stroke="black"
      />
    </svg>
  );
}

const twoSFRounder = significantFiguresRounderLocale(2, swedishLocaleSimple);
const MAX_NUM_BREAKPOINTS = 13;
const DEFAULT_NUM_BREAKPOINTS = 5;

interface AdvancedProps {
  advancedPanelOpen: boolean;
  toggleAdvancedPanelOpen: () => void;
  dataset: MicroDataset;
  settings: MicroMapSettings;
  card: DocCardMicro;
  updateSettings: (f: (prev: MicroMapSettings) => MicroMapSettings) => void;
}

function AdvancedScaleSettings(props: AdvancedProps) {
  const { dataset, settings, updateSettings } = props;
  const appMessages = useAppMessages();

  const [numBreakpoints, setNumBreakpoints] = useState(() => {
    return settings.manualBreakpoints?.length ?? DEFAULT_NUM_BREAKPOINTS;
  });

  const [useCustomBreakpoints, setUseCustomBreakpoints] = useState(
    defined(settings.manualBreakpoints)
  );

  const [breakpoints, setBreakpoints] = useState<string[] | undefined>(
    settings.manualBreakpoints?.map((b) => twoSFRounder(b))
  );

  const [rangeLabels, setRangeLabels] = useState<string[]>(
    settings.labelsForManualRanges ?? []
  );

  const handleLabelChange = (index: number, value: string) => {
    const updatedLabels = [...rangeLabels];
    updatedLabels[index] = value;
    setRangeLabels(updatedLabels);
  };

  const displayNumericalRange = useMemo(() => {
    const mainType = dataset.valueType();
    switch (mainType) {
      case "decimal":
        return (r: NumericalRange, rounder?: (num: number) => string) => {
          return displayNumericalRangeBase(r, rounder ?? twoSFRounder);
        };
      case "integer":
        return (r: NumericalRange, rounder?: (num: number) => string) => {
          return displayNumericalRangeBase(r, rounder ?? ((n) => n.toString()));
        };
    }
    return (r: NumericalRange) =>
      displayNumericalRangeBase(r, (n) => n.toString());
  }, [dataset]);

  const calculateMapColors = useCallback(
    (
      dataset: MicroDataset,
      mapSettings: MicroMapSettings,
      formatter?: (num: number) => string
    ) => {
      const manualRangeColors = mapSettings.manualColorsForRanges;
      const valueType = dataset.valueType();

      const customColorScale = mapSettings.colorScheme;
      const getColorsFunc = defined(customColorScale)
        ? makeColorGetterMicro(customColorScale)
        : makeColorGetterMicro(DEFAULT_COLOR_SCHEME_MICRO);
      const zMin = dataset.zMin;
      const zMax = dataset.zMax;
      if (!defined(zMin) || !defined(zMax)) {
        throw new Error("No zMin or zMax");
      }

      const fullExtent: [number, number] = [zMin, zMax];
      const manualBreakpoints = mapSettings.manualBreakpoints;
      try {
        if (defined(manualBreakpoints)) {
          const { ranges } = getColorScaleManual(
            manualBreakpoints,
            getColorsFunc,
            mapSettings.manualColorsForRanges
          );
          return ranges.map((r) => ({
            label: displayNumericalRange(r, formatter),
            color: r.color,
          }));
        }

        // Auto coloring
        if (valueType === "integer") {
          const { ranges } = getIntegerColorScaleAuto(
            fullExtent[0],
            fullExtent[1],
            (numColors) => {
              if (defined(manualRangeColors)) {
                return manualRangeColors;
              }
              return getColorsFunc(numColors);
            }
          );
          return ranges.map((r) => ({
            label: displayNumericalRange(r, formatter),
            color: r.color,
          }));
        } else {
          // Auto scale
          return getDecimalColorScaleAuto(
            fullExtent,
            (v) => dataset.chartData().makeTicksRangeFormatter(v),
            settings.colorScheme,
            manualRangeColors
          ).ranges.map((r) => ({
            label: displayNumericalRange(r, formatter),
            color: r.color,
          }));
        }
      } catch (e) {
        logger.error("Failed to calculate map colors", e);
        return [];
      }
    },
    [displayNumericalRange, settings.colorScheme]
  );

  const [customMapColors, setCustomMapColors] = useState<
    { label: string; color: string }[] | undefined
  >(() => {
    try {
      return calculateMapColors(dataset, settings);
    } catch (e) {
      logger.error("Failed to calculate map colors", e);
      return undefined;
    }
  });

  const manualSettings:
    | Pick<MicroMapSettings, "manualBreakpoints" | "manualColorsForRanges">
    | undefined = useMemo(() => {
    if (!defined(breakpoints)) {
      return;
    }
    try {
      const updatedBreakpoints = parseBreakpoints(breakpoints);
      const error = validateBreakpoints(updatedBreakpoints);
      if (defined(error)) {
        return;
      }
      const colors = customMapColors?.map((c) => c.color);
      if (!defined(colors)) {
        return;
      }
      return {
        manualBreakpoints: updatedBreakpoints,
        manualColorsForRanges: colors,
      };
    } catch (e) {
      // We ignore parsing errors here to account for partially written values
      return;
    }
  }, [breakpoints, customMapColors]);

  const handleSliderChange = useCallback(
    (value: number) => {
      if (value === numBreakpoints) {
        return;
      }

      setNumBreakpoints(value);
      const suggested = suggestBreakpoints(dataset, value);
      try {
        const parsedBreakpoints = parseBreakpoints(suggested.breakpoints);
        setBreakpoints(suggested.breakpoints);
        setNumBreakpoints(value);
        const colors = calculateMapColors(
          dataset,
          {
            ...settings,
            manualColorsForRanges: undefined,
            manualBreakpoints: parsedBreakpoints,
          },
          suggested.formatter
        );
        setCustomMapColors(colors);
      } catch (e) {
        logger.error("Failed to parse breakpoints", e);
        appMessages?.add("error", "Kunde inte tolka brytpunkterna");
      }
    },
    [appMessages, calculateMapColors, dataset, numBreakpoints, settings]
  );

  const handleBreakpointChange = useCallback(
    (index: number, value: string | undefined) => {
      if (!defined(breakpoints)) {
        logger.error("[handleBreakpointChange] Breakpoints not defined");
        return;
      }

      const newBreakpoints = [...breakpoints];
      if (index >= newBreakpoints.length) {
        logger.error("Index out of bounds");
        return;
      }

      newBreakpoints[index] = value ?? "";
      setBreakpoints(newBreakpoints);

      try {
        const parsedBreakpoints = parseBreakpoints(newBreakpoints);
        if (defined(validateBreakpoints(parsedBreakpoints))) {
          return;
        }

        const newColors =
          customMapColors ??
          calculateMapColors(dataset, {
            ...settings,
            manualBreakpoints: parsedBreakpoints,
          });

        newColors[index] = {
          ...newColors[index],
          label: displayNumericalRange({
            min: parsedBreakpoints[index - 1],
            max: parsedBreakpoints[index],
          }),
        };
        if (defined(newColors[index + 1])) {
          newColors[index + 1] = {
            ...newColors[index + 1],
            label: displayNumericalRange({
              min: parsedBreakpoints[index],
              max: parsedBreakpoints[index + 1],
            }),
          };
        }

        setCustomMapColors(newColors);
      } catch (e) {
        logger.info("Failed to parse breakpoints, waiting for input", e);
        // Ignore parsing errors here
      }
    },
    [
      breakpoints,
      calculateMapColors,
      customMapColors,
      dataset,
      displayNumericalRange,
      settings,
    ]
  );

  const handleReset = useCallback(() => {
    setNumBreakpoints(DEFAULT_NUM_BREAKPOINTS);
    setUseCustomBreakpoints(false);
    setBreakpoints(undefined);
    setCustomMapColors(undefined);

    updateSettings((p) => ({
      ...p,
      manualBreakpoints: undefined,
      manualColorsForRanges: undefined,
    }));
  }, [updateSettings]);

  const handleSetUseCustomBreakpoints = useCallback(
    (useCustom: boolean) => {
      if (useCustom && !defined(breakpoints)) {
        const numBreakpoints = DEFAULT_NUM_BREAKPOINTS;

        const suggested = suggestBreakpoints(dataset, numBreakpoints);

        const parsedBreakpoints = parseBreakpoints(suggested.breakpoints);
        const error = validateBreakpoints(parsedBreakpoints);
        if (defined(error)) {
          appMessages?.add("error", error);
          return;
        }

        const newColors = calculateMapColors(dataset, {
          ...settings,
          manualBreakpoints: parsedBreakpoints,
        });
        setCustomMapColors(newColors);
        setBreakpoints(suggested.breakpoints);
        setNumBreakpoints(suggested.breakpoints.length);
        setUseCustomBreakpoints(true);
        return;
      }
      handleReset();
    },
    [
      appMessages,
      breakpoints,
      calculateMapColors,
      dataset,
      handleReset,
      settings,
    ]
  );

  const handleSave = useCallback(() => {
    if (!defined(breakpoints)) {
      logger.error("Breakpoints not defined");
      return;
    }

    try {
      const updatedBreakpoints = parseBreakpoints(breakpoints);
      const error = validateBreakpoints(updatedBreakpoints);
      if (defined(error)) {
        appMessages?.add("error", error);
        return;
      }

      const colors = customMapColors?.map((c) => c.color);
      const labels = rangeLabels;

      if (defined(colors) && colors.length !== updatedBreakpoints.length + 1) {
        appMessages?.add(
          "error",
          `Färgerna matchar inte brytpunkterna. ${colors.length} färger och ${updatedBreakpoints.length} brytpunkter.`
        );
        return;
      }

      updateSettings((prev) => ({
        ...prev,
        manualBreakpoints: updatedBreakpoints,
        manualColorsForRanges: colors ?? prev.manualColorsForRanges,
        labelsForManualRanges: labels ?? prev.labelsForManualRanges,
      }));
    } catch (e) {
      appMessages?.add("error", "Kunde inte tolka brytpunkterna");
      logger.error("Failed to parse breakpoints", e);
      return;
    }
  }, [appMessages, breakpoints, customMapColors, rangeLabels, updateSettings]);

  const handleApplyColor = useCallback(
    (color: string, index: number) => {
      const colors = defined(customMapColors)
        ? customMapColors
        : calculateMapColors(dataset, settings);
      const newColors = [...colors];
      newColors[index] = { ...newColors[index], color };
      setCustomMapColors(newColors);
    },
    [calculateMapColors, customMapColors, dataset, settings]
  );

  const handleApplyNoColor = useCallback(
    (index: number) => {
      const colors = defined(customMapColors)
        ? customMapColors
        : calculateMapColors(dataset, settings);
      const newColors = [...colors];
      newColors[index] = { ...newColors[index], color: "" };
      setCustomMapColors(newColors);
    },
    [calculateMapColors, customMapColors, dataset, settings]
  );

  const hasChanges = useMemo(() => {
    if (!defined(manualSettings)) {
      return false;
    }
    return (
      !isEqual(manualSettings.manualBreakpoints, settings.manualBreakpoints) ||
      !isEqual(
        manualSettings.manualColorsForRanges,
        settings.manualColorsForRanges
      ) ||
      !isEqual(rangeLabels, settings.labelsForManualRanges)
    );
  }, [
    manualSettings,
    rangeLabels,
    settings.labelsForManualRanges,
    settings.manualBreakpoints,
    settings.manualColorsForRanges,
  ]);

  return (
    <FoldoutPanelControlled
      isOpen={props.advancedPanelOpen}
      toggleOpen={props.toggleAdvancedPanelOpen}
      title={"Avancerat"}
    >
      <Checkbox
        label="Använd brytpunkter för färgning av områden"
        checked={useCustomBreakpoints}
        onChange={(_, checked) => handleSetUseCustomBreakpoints(!!checked)}
        className="margin-bottom-lg"
      />

      <>
        {useCustomBreakpoints && (
          <>
            <div className="margin-bottom-md">
              <div className="flex flex-row">
                <Slider
                  className="flex-grow"
                  label="Antal brytpunkter"
                  min={1}
                  max={MAX_NUM_BREAKPOINTS}
                  step={1}
                  value={numBreakpoints}
                  showValue
                  onChange={handleSliderChange}
                />
              </div>
              <p>
                Varje intervall är inklusive den övre brytpunkten och exklusive
                den nedre brytpunkten.
              </p>
            </div>

            <div className="custom-colors margin-top-md">
              {customMapColors?.map((c, index) => (
                <div
                  key={c.label + "-" + index}
                  className="color-dropdown-container"
                >
                  <ColorDropdown
                    key={c.label}
                    applyColor={(label, color) =>
                      handleApplyColor(color, index)
                    }
                    classNames="color-dropdown"
                    applyNoColor={() => handleApplyNoColor(index)}
                    mode={"both"}
                    swatchColors={standardColors.concat(extendedStandardColors)}
                    colorCode={c.color}
                    label={c.label}
                  />
                  <label>{c.label}</label>
                  <TextField
                    label=""
                    placeholder="Etikett"
                    value={rangeLabels[index] ?? ""}
                    onChange={(e, newValue) =>
                      handleLabelChange(index, newValue || "")
                    }
                  />
                </div>
              ))}
            </div>

            <div className="margin-bottom-md">
              {useCustomBreakpoints && defined(breakpoints) && (
                <div className="breakpoints-grid">
                  {range(0, numBreakpoints).map((_, index) => (
                    <TextField
                      key={numBreakpoints + "_" + index}
                      label={"Brytpunkt " + (index + 1)}
                      value={breakpoints[index] ?? ""}
                      onChange={(e, newValue) =>
                        handleBreakpointChange(index, newValue)
                      }
                    />
                  ))}
                </div>
              )}
            </div>

            <div className="buttons-footer">
              <Button
                disabled={!hasChanges}
                intent="primary"
                title={"Tillämpa"}
                onClick={handleSave}
              ></Button>
            </div>
          </>
        )}
      </>
    </FoldoutPanelControlled>
  );
}

interface BreakpointsResult {
  breakpoints: string[];
  formatter: (n: number) => string;
}

function suggestBreakpoints(dataset: MicroDataset, numBreakpoints: number) {
  const res = suggestedBreakpointsInner(dataset, numBreakpoints);
  if (!defined(res)) {
    return {
      breakpoints: range(0, numBreakpoints).map(() => ""),
      formatter: (n: number) => n.toString(),
    };
  }

  if (
    res.breakpoints.length < numBreakpoints &&
    dataset.valueType() !== "integer" // No need to add more breakpoints for integers
  ) {
    return {
      breakpoints: range(0, numBreakpoints).map(() => ""),
      formatter: res.formatter,
    };
  }
  return res;
}

// Dataset value type doesn't matter, because we use the Z values for coloring
function suggestedBreakpointsInner(
  dataset: MicroDataset,
  numBreakpoints: number
): BreakpointsResult | undefined {
  const extent = dataset.chartData().rangeExtent(false);
  const s = d3scale
    .scaleQuantize<string, string>()
    .domain(extent)
    .range(range(numBreakpoints + 1).map((_, i) => i + ""));
  const formatter = findDistinctiveFormatter(s.thresholds());

  return {
    breakpoints: s.thresholds().map(formatter),
    formatter: formatter,
  };
}

function parseBreakpoints(points: string[]): number[] {
  let parsedBreakpoints: number[] = [];
  for (const b of points) {
    const res = parseSwedishNumber(b);
    res.match({
      ok: (num) => {
        parsedBreakpoints.push(num);
      },
      err: (err) => {
        throw new Error(err + ": Parsing num failed: " + b);
      },
    });
  }
  return parsedBreakpoints;
}

function validateBreakpointPair(
  breakpoints: number[],
  firstIndex: number,
  secondIndex: number
): string | undefined {
  const first = breakpoints[firstIndex];
  const second = breakpoints[secondIndex];

  if (first >= second) {
    return `Brytpunkt ${
      firstIndex + 1
    } (${first}) måste vara mindre än brytpunkt ${secondIndex + 1} (${second})`;
  }
}

function validateBreakpoints(breakpointsParsed: number[]): string | undefined {
  for (let i = 1; i < breakpointsParsed.length; i++) {
    const err = validateBreakpointPair(breakpointsParsed, i - 1, i);
    if (defined(err)) {
      return err;
    }
  }
  return undefined;
}
