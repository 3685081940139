import { useCallback, useEffect } from "react";

import { defined } from "../../../../../lib/core/defined";
import {
  GeoLegend,
  ManualLegend,
  ResultsPanelControl,
} from "./components/ResultsPanel";
import {
  MicroMapSettings,
  MicroMapView,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import { MicroDataset } from "../../../../../lib/application/stats/datasets/MicroDataset";
import { useAddRemoveControl } from "./useAddRemoveControl";

export function useResultsControl(
  view: MicroMapView,
  map: mapboxgl.Map | undefined,
  mapSettings: MicroMapSettings,
  isEditingCard: boolean,
  microDataset: MicroDataset | undefined,
  reconstructCount: number,
  geoLegend?: GeoLegend,
  manualLegend?: ManualLegend
) {
  const makeControl = useCallback(() => {
    return new ResultsPanelControl();
  }, []);

  const {
    add,
    remove,
    control: resultsControl,
  } = useAddRemoveControl(map, makeControl, "top-right");

  useEffect(() => {
    if (!defined(resultsControl)) {
      return;
    }
    resultsControl.render(
      view === "map-view" || !isEditingCard
        ? microDataset?.microMapResults()
        : undefined,
      mapSettings,
      geoLegend,
      manualLegend
    );
  }, [
    geoLegend,
    isEditingCard,
    manualLegend,
    map,
    mapSettings,
    mapSettings.colorScheme,
    microDataset,
    resultsControl,
    view,
    reconstructCount, // We want to re-render the results panel when the reconstruct count changes
  ]);

  return [resultsControl, add, remove] as const;
}
