import React, { useCallback } from "react";
import {
  createMeasureAlert,
  saveMeasureMetadata,
  saveMeasureMetadataMemberOrgsMeasure,
} from "../../application/requests/admin/common_requests_admin";
import { saveMicroMeasureMetadata } from "../../application/requests/admin/measure_management/micro/metadata";
import { AppMessagesHandler } from "../../application/AppMessagesHandler";
import { logger } from "../../infra/logging";
import { statsApi } from "../../application/requests/statsApi";
import { TimeResolution } from "../../domain/time";
import { Progress } from "../../core/progress";
import { HttpResult } from "../../infra/HttpResult";

export type MeasureManagementType =
  | "stats"
  | "survey"
  | "micro"
  | "memberOrgsMeasure";

function getMetadataSaver(type: MeasureManagementType) {
  switch (type) {
    case "stats":
    case "survey":
      return saveMeasureMetadata;
    case "micro":
      return saveMicroMeasureMetadata;
    case "memberOrgsMeasure":
      return saveMeasureMetadataMemberOrgsMeasure;
  }
}

export function useUpdateLastUpdated(messagesHandler?: AppMessagesHandler) {
  return useCallback(
    (type: MeasureManagementType, dataId: number) => {
      const metadataSaver = getMetadataSaver(type);
      if (type === "survey") {
        return Promise.resolve(HttpResult.fromOk(null));
      }

      return metadataSaver(
        dataId,
        "last_update",
        new Date().toLocaleDateString()
      )
        .then((res) => {
          res.match({
            ok: () => {
              messagesHandler?.add("success", "Datum uppdaterat!");
            },
            err: (err) => {
              logger.error(err);
              messagesHandler?.add("error", "Datumuppdatering misslyckades");
            },
          });
          return res;
        })
        .catch((err) => {
          logger.error(err);
          messagesHandler?.add("error", "Datumuppdatering misslyckades");
          return HttpResult.fromErr(err);
        });
    },
    [messagesHandler]
  );
}

export function useUpdateNextUpdate(messagesHandler?: AppMessagesHandler) {
  return useCallback(
    (type: MeasureManagementType, dataId: number) => {
      if (type === "survey") {
        return Promise.resolve(HttpResult.fromOk(null));
      }

      return (
        type === "stats" || type === "memberOrgsMeasure"
          ? statsApi.getSingleMeasure(dataId, false, false)
          : statsApi.getSingleMeasureMicro(dataId, undefined)
      )
        .then((res) => {
          const metadataSaver = getMetadataSaver(type);
          const metadata = res.unwrap();
          const resolution = TimeResolution.deserialize(metadata.resolution);
          const nextUpdate = resolution.incrementDateBySteps(new Date(), 1);
          return metadataSaver(
            dataId,
            "next_update",
            nextUpdate.toLocaleDateString()
          ).then((res) => {
            res.match({
              ok: () => {
                messagesHandler?.add("success", "next_update uppdaterat!");
              },
              err: (err) => {
                logger.error(err);
                messagesHandler?.add(
                  "error",
                  "Uppdatering av next_update misslyckades"
                );
              },
            });
            return res;
          });
        })
        .catch((err) => {
          logger.error(err);
          messagesHandler?.add(
            "error",
            "Uppdatering av next_update misslyckades"
          );
          return HttpResult.fromErr(err);
        });
    },
    [messagesHandler]
  );
}

export function useSendNotices(
  setSendProgress: React.Dispatch<React.SetStateAction<Progress>> | undefined,
  messagesHandler?: AppMessagesHandler
) {
  return useCallback(
    (dataId: number) => {
      setSendProgress?.(Progress.InProgress);
      return createMeasureAlert(dataId)
        .then((res) => {
          res.match({
            ok: () => {
              messagesHandler?.add("success", "Utskick schemalagt!");
              setSendProgress?.(Progress.Success);
            },
            err: (err) => {
              logger.error(err);
              messagesHandler?.add(
                "error",
                "Schemaläggning av utskick misslyckades"
              );
              setSendProgress?.(Progress.Error);
            },
          });
          return res;
        })
        .catch((err) => {
          logger.error(err);
          setSendProgress?.(Progress.Error);
          messagesHandler?.add(
            "error",
            "Schemaläggning av utskick misslyckades"
          );
          return HttpResult.fromErr(err);
        });
    },
    [messagesHandler, setSendProgress]
  );
}
