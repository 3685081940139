import { useCallback, useEffect } from "react";

import {
  MicroGeoSelections,
  MicroMapSettings,
  MicroMapView,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import { defined } from "../../../../../lib/core/defined";
import { ToolPanelControl, ZoomActions } from "./components/ToolPanel";
import { areaTypesMixed, HandleUpdateSetting } from "./shared";
import { useAddRemoveControl } from "./useAddRemoveControl";

export function useToolPanelControl(
  view: MicroMapView,
  map: mapboxgl.Map | undefined,
  isLoading: boolean,
  selectedAreas: MicroGeoSelections | undefined,
  settings: MicroMapSettings,
  fullscreenSupported: boolean,
  isFullscreen: boolean,
  handleFullScreen: () => void,
  handleUpdateSetting: HandleUpdateSetting,
  zoomActions: ZoomActions,
  isEditingCard: boolean,
  reconstructCount: number
) {
  const makeToolPanelControl = useCallback(() => {
    return new ToolPanelControl();
  }, []);

  const {
    add,
    remove,
    control: toolPanelControl,
  } = useAddRemoveControl(map, makeToolPanelControl, "top-right");

  useEffect(() => {
    if (defined(toolPanelControl)) {
      toolPanelControl.render(
        view,
        isLoading,
        defined(selectedAreas) && areaTypesMixed(selectedAreas),
        settings,
        fullscreenSupported,
        isFullscreen,
        handleFullScreen,
        handleUpdateSetting,
        zoomActions,
        isEditingCard
      );
    }
  }, [
    fullscreenSupported,
    handleFullScreen,
    handleUpdateSetting,
    isEditingCard,
    isFullscreen,
    isLoading,
    selectedAreas,
    settings,
    toolPanelControl,
    view,
    zoomActions,
    reconstructCount, // We want to re-render after controls reconstruction, therefore depend on count here
  ]);

  return [toolPanelControl, add, remove] as const;
}
