import mapbox, { MapboxEvent } from "mapbox-gl";
import { useEffect } from "react";

import { mapboxBoundsToInfostatBounds } from "../../../../../lib/application/cartography/mapbox";
import {
  DesoProperties,
  MicroGeoSelections,
  MicroMapSettings,
  MicroMapView,
  RegsoProperties,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import { defined } from "../../../../../lib/core/defined";
import { InfostatBoundingBox } from "../../../../../lib/domain/cartography/types";
import { getGeoLayerId, mapSetFeatureSelected } from "./shared";
import { config } from "../../../../../config";
import { getText } from "../../../../../lib/application/strings";
import { PointerResult } from "./useGeoPanelActions";
import { GeoTypeMicro } from "../../../../../lib/domain/geography";

export function useUserEvents(
  view: MicroMapView,
  geoLayerIds: string[],
  map: mapboxgl.Map | undefined,
  settings: MicroMapSettings,
  selectedAreas: MicroGeoSelections | undefined,
  handleHoverResult: (result?: PointerResult) => void,
  handleClickResult: (result?: PointerResult) => void,
  handleBoxSelect: (
    propertiesArray: (DesoProperties | RegsoProperties)[]
  ) => void,
  handlePointerSelect: (properties: DesoProperties) => void,
  handleDeselectSingleUnit: (
    id: number,
    prevSelectedAreas: MicroGeoSelections
  ) => void,
  saveMapLocation: (box: InfostatBoundingBox) => void
) {
  const selectionMode = selectedAreas?.type;
  const geotype = selectedAreas?.type;
  const numSelectedAreas = selectedAreas?.selected.length ?? 0;

  // Draw-select
  useEffect(() => {
    if (
      !defined(map) ||
      !defined(geotype) ||
      !(view === "map-select" && settings.mapSelectTool === "draw-select")
    ) {
      return;
    }
    const canvas = map.getCanvasContainer();

    let start: mapboxgl.Point;
    let current: mapboxgl.Point;

    // Variable for the draw box element.
    let box: HTMLElement | undefined;

    // Return the xy coordinates of the mouse position
    function mousePos(e: MouseEvent) {
      const rect = canvas.getBoundingClientRect();
      return new mapbox.Point(
        e.clientX - rect.left - canvas.clientLeft,
        e.clientY - rect.top - canvas.clientTop
      );
    }

    function mouseDown(e: MouseEvent) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
      document.addEventListener("keydown", onKeyDown);

      start = mousePos(e);
    }

    function onMouseMove(e: MouseEvent) {
      current = mousePos(e);

      if (!defined(box)) {
        box = document.createElement("div");
        box.classList.add("boxdraw");
        canvas.appendChild(box);
      }

      const minX = Math.min(start.x, current.x),
        maxX = Math.max(start.x, current.x),
        minY = Math.min(start.y, current.y),
        maxY = Math.max(start.y, current.y);

      // Adjust width and xy position of the box element ongoing
      const pos = `translate(${minX}px, ${minY}px)`;
      box.style.transform = pos;
      box.style.width = maxX - minX + "px";
      box.style.height = maxY - minY + "px";
    }

    function onMouseUp(e: MouseEvent) {
      // Capture xy coordinates
      finish([start, mousePos(e)]);
    }

    function onKeyDown(e: KeyboardEvent) {
      // If the ESC key is pressed
      if (e.keyCode === 27) finish();
    }

    function finish(bbox?: [mapboxgl.Point, mapboxgl.Point]) {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("mouseup", onMouseUp);

      if (defined(box)) {
        box.parentNode?.removeChild(box);
        box = undefined;
      }

      if (!defined(map) || !defined(geotype) || !defined(bbox)) {
        return;
      }

      const features = map.queryRenderedFeatures(bbox, {
        layers: [getGeoLayerId(geotype)],
      });

      if (
        features.length + numSelectedAreas >=
        config.micro.maxNumFeaturesSelected
      ) {
        return window.alert(getText("micro-too-many-features-selected"));
      }
      for (const f of features) {
        const id = f.id;
        if (!defined(id)) {
          continue;
        }
        mapSetFeatureSelected(map, geotype, id);
      }
      handleBoxSelect(
        features.map((f) => f.properties as DesoProperties | RegsoProperties)
      );
    }

    canvas.addEventListener("mousedown", mouseDown, true);

    return () => {
      canvas.removeEventListener("mousedown", mouseDown, true);
    };
  }, [
    handleBoxSelect,
    map,
    geotype,
    numSelectedAreas,
    view,
    settings.mapSelectTool,
  ]);

  // Hover/click labels for results
  useEffect(() => {
    if (!defined(map) || view !== "map-view") {
      return;
    }

    map.on("mousemove", onMouseMove);
    map.on("click", onMouseClick);

    let timeoutHandle: NodeJS.Timeout | undefined;

    function onMouseClick(e: mapboxgl.MapMouseEvent) {
      if (!defined(map) || (!defined(geotype) && geoLayerIds.length === 0)) {
        return;
      }
      const pos = e.point;
      const feature = getFirstPriorityFeatureHit(
        map,
        pos,
        geoLayerIds,
        geotype
      );
      const props = feature?.properties;
      if (!defined(props)) {
        handleClickResult(undefined);
      }

      if (props?.type === "micro-geo") {
        const infoLines = defined(props.infoLines)
          ? JSON.parse(props.infoLines)
          : [];
        const label = props.label;
        const value = props.value;
        if (infoLines.length === 0 && !defined(label) && !defined(value)) {
          return;
        }

        return handleClickResult({
          position: pos,
          lngLat: e.lngLat,
          properties: {
            type: "geo",
            data: { label, infoLines, value, unit: props.unit },
          },
        });
      }

      handleClickResult(
        defined(feature)
          ? {
              properties:
                selectionMode === "deso"
                  ? { type: "deso", data: props as DesoProperties }
                  : {
                      type: "regso",
                      data: props as RegsoProperties,
                    },
              position: pos,
              lngLat: e.lngLat,
            }
          : undefined
      );
    }

    function onMouseMove(e: mapboxgl.MapMouseEvent) {
      // Geotype is defined for deso/regso measures. For point/line/polygon measures, we have at least one geoLayerId.
      if (!defined(map) || (!defined(geotype) && geoLayerIds.length === 0)) {
        return;
      }
      const currentPos = e.point;

      if (defined(timeoutHandle)) {
        clearTimeout(timeoutHandle);
      }

      timeoutHandle = setTimeout(() => {
        const props = getFirstPriorityFeatureHit(
          map,
          currentPos,
          geoLayerIds,
          geotype
        )?.properties;
        if (!defined(props)) {
          handleHoverResult(undefined);
        }

        if (props?.type === "micro-geo") {
          const infoLines = defined(props.infoLines)
            ? JSON.parse(props.infoLines)
            : [];
          const label = props.label;
          const value = props.value;
          if (infoLines.length === 0 && !defined(label) && !defined(value)) {
            return;
          }

          return handleHoverResult({
            position: currentPos,
            lngLat: e.lngLat,
            properties: {
              type: "geo",
              data: { label, infoLines, value, unit: props.unit },
            },
          });
        }

        handleHoverResult(
          defined(props)
            ? {
                properties:
                  selectionMode === "deso"
                    ? { type: "deso", data: props as DesoProperties }
                    : {
                        type: "regso",
                        data: props as RegsoProperties,
                      },
                position: currentPos,
                lngLat: e.lngLat,
              }
            : undefined
        );
      }, 100);
    }

    return () => {
      if (defined(timeoutHandle)) {
        clearTimeout(timeoutHandle);
      }
      map.off("mousemove", onMouseMove);
      map.off("click", onMouseClick);
    };
  }, [
    geoLayerIds,
    geotype,
    handleClickResult,
    handleHoverResult,
    map,
    selectionMode,
    view,
  ]);

  // Select/deselect areas on click
  useEffect(() => {
    if (!defined(map) || !defined(geotype)) {
      return;
    }
    if (!(view === "map-select" && settings.mapSelectTool === "click-select")) {
      return;
    }

    const listener = (
      e: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
      }
    ): void => {
      if (!defined(selectedAreas)) {
        return;
      }
      for (const feature of e.features ?? []) {
        const selected = !feature.state?.selected;
        const id = feature.id;
        if (!defined(id)) {
          return;
        }

        const props = feature.properties as DesoProperties;
        if (selected) {
          handlePointerSelect(props);
        } else {
          handleDeselectSingleUnit(props.id, selectedAreas);
        }
      }
    };

    map.on("click", getGeoLayerId(geotype), listener);

    return () => {
      map.off("click", getGeoLayerId(geotype), listener);
    };
  }, [
    handlePointerSelect,
    map,
    handleDeselectSingleUnit,
    selectedAreas,
    settings,
    geotype,
    view,
  ]);

  useEffect(() => {
    if (!defined(map)) {
      return;
    }

    const onMoveEnd = (e: MapboxEvent): void => {
      const bounds = map.getBounds();
      if (!defined(bounds)) {
        throw new Error("Bounds should be defined");
      }
      saveMapLocation(mapboxBoundsToInfostatBounds(bounds));
    };
    map.on("moveend", onMoveEnd);
    return () => {
      map.off("moveend", onMoveEnd);
    };
  }, [map, saveMapLocation]);
}

function getFirstPriorityFeatureHit(
  map: mapboxgl.Map,
  currentPos: mapboxgl.Point,
  geoLayerIds: string[],
  geotype?: GeoTypeMicro
) {
  const layers = geoLayerIds.slice();
  if (defined(geotype)) {
    layers.push(getGeoLayerId(geotype));
  }
  const featuresUnfiltered = map
    .queryRenderedFeatures(currentPos, { layers })
    .slice();
  const fatLineFeatures = featuresUnfiltered.filter(
    (f) => f.layer?.type === "line" && f.layer.id.endsWith("fat-lines")
  );
  const polygonFillFeatures = featuresUnfiltered.filter(
    (f) =>
      f.layer?.type === "fill" &&
      !(f.layer.source === "deso" || f.layer.source === "regso")
  );
  const features = featuresUnfiltered
    .filter((f) => {
      if (fatLineFeatures.some((fatLine) => fatLine.id === f.id)) {
        // Check if the fill feature corresponding to the contour was also hit,
        // if it wasn't it means the mouse is on the wrong side of the contour.
        const matched = polygonFillFeatures.some(
          (otherFeature) =>
            otherFeature.source === f.source &&
            otherFeature.id === f.id?.toString().replace("fat-lines", "fill")
        );
        return matched;
      }

      return true;
    })
    .filter((f) => {
      // We don't match polygon fill layers that are not deso/regso -- we only want to match them close to their
      // edges and for that we use invisible line layers instead.
      if (
        f.layer?.type === "fill" &&
        !(f.layer.source === "deso" || f.layer.source === "regso")
      ) {
        return false;
      }
      return true;
    });

  features.sort((left, right) => {
    // Sort point features first, then line features, then polygon features.
    const leftType = left.layer?.type;
    const rightType = right.layer?.type;
    if (leftType === rightType) {
      return 0;
    }
    if (leftType === "circle") {
      return -1;
    }
    if (rightType === "circle") {
      return 1;
    }
    if (leftType === "line") {
      return -1;
    }
    if (rightType === "line") {
      return 1;
    }
    return 0;
  });

  return features[0];
}
