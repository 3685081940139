import { RecoilState, useRecoilCallback } from "recoil";

import { DocCardState, DocCardTextCK } from "../stats/document-core/core";
import {
  docCardAtomFamily,
  docCardsListState,
  DocListItem,
} from "../stats/document-core/atoms";
import { defaultStyleContainerGeoMicro } from "../stats/document-style/operations";
import {
  cardColorSchemeLinksAtom,
  cardStyleContainerGeoMicroLinksAtom,
  colorSchemeContainersAtomFamily,
  styleContainersGeoMicroAtomFamily,
} from "../stats/document-style/atoms";
import {
  ColorSchemeContainer,
  StyleContainerGeoMicro,
} from "../stats/document-style/definitions";
import { logger } from "../../../infra/logging";

/**
 * Hook exposing access to recoil state for the given card
 *
 * When called, it adds the card to global state, also taking care of color scheme state.
 */
export function useAddStatsCardCallback() {
  return useRecoilCallback(
    ({ set }) =>
      (
        card: DocCardState,
        insertIndex: number,
        colorSchemeContainer: ColorSchemeContainer
      ) => {
        addNewStatsOrMicroCardToState(
          set,
          card,
          insertIndex,
          colorSchemeContainer
        );
      },
    []
  );
}

export function useAddPythonCardCallback() {
  return useRecoilCallback(
    ({ set }) =>
      (card: DocCardState, insertIndex: number) => {
        addNewPythonCardToState(set, card, insertIndex);
      }
  );
}

export type InsertCardArrays = { cards: DocCardTextCK[]; index: number }[];
export function useAddPythonResultCardsCallback() {
  return useRecoilCallback(
    ({ set }) =>
      (cardArrays: InsertCardArrays) => {
        for (const array of cardArrays) {
          for (const card of array.cards) {
            set(docCardAtomFamily(card.id), card);
          }
        }
        set(docCardsListState, (prev) => {
          const copy = prev.slice();
          for (const array of cardArrays) {
            copy.splice(
              array.index,
              0,
              ...array.cards.map((c) => ({ id: c.id }))
            );
          }
          return copy;
        });
      },
    []
  );
}

export function useSetCardCallback() {
  return useRecoilCallback(
    ({ set }) =>
      (card: DocCardState) => {
        set(docCardAtomFamily(card.id), card);
      },
    []
  );
}

export function useAddTextCardCallback() {
  return useRecoilCallback(
    ({ set }) =>
      (card: DocCardState, insertIndex: number) => {
        addNewTextCardToState(set, card, insertIndex);
      },
    []
  );
}

export function useAddMicroCardCallback() {
  return useRecoilCallback(
    ({ set }) =>
      (
        card: DocCardState,
        insertIndex: number,
        colorSchemeContainer: ColorSchemeContainer
      ) => {
        addNewStatsOrMicroCardToState(
          set,
          card,
          insertIndex,
          colorSchemeContainer
        );
      },
    []
  );
}

export function useGetAllCardsCallback(cardsList: DocListItem[]) {
  return useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return cardsList.map(({ id }) => {
          const contents = snapshot.getLoadable(docCardAtomFamily(id)).contents;
          if (contents instanceof Error || contents === undefined) {
            throw new Error("Invalid contents");
          }
          return contents as DocCardState;
        });
      },
    [cardsList]
  );
}

export function useGetAllCardsCurrentCallback() {
  return useRecoilCallback(({ snapshot }) => () => {
    const cardsList = snapshot.getLoadable(docCardsListState).contents as
      | DocListItem[]
      | undefined;
    if (cardsList === undefined) {
      throw new Error("Invalid cards list");
    }
    return cardsList.map(({ id }) => {
      const contents = snapshot.getLoadable(docCardAtomFamily(id)).contents;
      if (contents instanceof Error || contents === undefined) {
        throw new Error("Invalid contents");
      }
      return contents as DocCardState;
    });
  });
}

export interface CardSpaceSettings {
  hideSpaceBefore?: boolean;
  hideSpaceAfter?: boolean;
}

export function useGetCardSpaceSettings() {
  return useRecoilCallback(
    ({ snapshot }) =>
      (cardId: string): CardSpaceSettings | undefined => {
        const card = snapshot.getLoadable(docCardAtomFamily(cardId)).contents;
        if (card instanceof Error) {
          logger.error("Invalid card contents, was error");
          return;
        }
        if (card === undefined) {
          logger.error("Invalid card contents, was undefined");
          return;
        }

        const typedCard = card as DocCardState;
        if (typedCard.type === "error") {
          return;
        }

        return {
          hideSpaceBefore: typedCard.hideSpaceBefore,
          hideSpaceAfter: typedCard.hideSpaceAfter,
        };
      }
  );
}

export function useResetAllCardsCallback() {
  return useRecoilCallback(({ set, reset }) => (cardIds: string[]) => {
    set(docCardsListState, []);
    for (const cardId of cardIds) {
      reset(docCardAtomFamily(cardId));
    }
  });
}

export function useSetCardSpacesCallback() {
  return useRecoilCallback(
    ({ set }) =>
      (cardIds: string[], hideSpaces: boolean) => {
        for (const cardId of cardIds) {
          set(docCardAtomFamily(cardId), (prev) => {
            if (prev instanceof Error) {
              logger.error("Invalid card contents, was error");
              return prev;
            }
            if (prev === undefined) {
              logger.error("Invalid card contents, was undefined");
              return prev;
            }

            const typedCard = prev as DocCardState;
            if (typedCard.type === "error") {
              return prev;
            }
            const copy = { ...typedCard };
            if (cardIds.indexOf(cardId) === 0) {
              copy.hideSpaceAfter = hideSpaces;
            } else if (cardIds.indexOf(cardId) === cardIds.length - 1) {
              copy.hideSpaceBefore = hideSpaces;
            } else {
              copy.hideSpaceBefore = hideSpaces;
              copy.hideSpaceAfter = hideSpaces;
            }

            return copy;
          });
        }
      },
    []
  );
}

export function useMoveCardCallback() {
  return useRecoilCallback(
    ({ set }) =>
      (cardId: string, insertIndex: number) => {
        set(docCardsListState, (prev) => {
          const copy = prev.slice();
          const cardIndex = copy.findIndex((c) => c.id === cardId);
          copy.splice(cardIndex, 1);
          copy.splice(insertIndex, 0, { id: cardId });
          return copy;
        });
      }
  );
}

export function useRemoveCardCallback() {
  return useRecoilCallback(
    ({ set, reset }) =>
      (cardId: string) => {
        set(docCardsListState, (prev) => {
          return prev.filter((card) => card.id !== cardId);
        });
        reset(docCardAtomFamily(cardId));
      },
    []
  );
}

export function useSetAllCardsCallback() {
  return useRecoilCallback(
    ({ set }) =>
      (cards: DocCardState[]) => {
        set(
          docCardsListState,
          cards.map((card) => ({ id: card.id }))
        );
        for (const card of cards) {
          set(docCardAtomFamily(card.id), card);
        }
      },
    []
  );
}

export function useAddSingleCardIfNotExists() {
  return useRecoilCallback(
    ({ set }) =>
      (card: DocCardState) => {
        set(docCardsListState, (prev) => {
          if (prev.some((c) => c.id === card.id)) {
            return prev;
          }
          return prev.concat({ id: card.id });
        });
        set(docCardAtomFamily(card.id), card);
      },
    []
  );
}

/**
 * Internal function
 * Add new stats/micro card to state at given list insert index
 */
function addNewStatsOrMicroCardToState(
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: T | ((currVal: T) => T)
  ) => void,
  card: DocCardState,
  insertIndex: number,
  colorSchemeContainer: ColorSchemeContainer,
  optMicroStyleContainer?: StyleContainerGeoMicro
) {
  set(docCardAtomFamily(card.id), card);
  set(docCardsListState, (prev) => {
    const copy = prev.slice();
    copy.splice(insertIndex, 0, { id: card.id });
    return copy;
  });
  addColorSchemeContainerToState(set, colorSchemeContainer, card.id);

  if (card.type === "microCard") {
    addStyleContainerGeoMicroToState(
      set,
      optMicroStyleContainer ?? defaultStyleContainerGeoMicro(),
      card.id
    );
  }
}

function addNewPythonCardToState(
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: T | ((currVal: T) => T)
  ) => void,
  card: DocCardState,
  insertIndex: number
) {
  set(docCardAtomFamily(card.id), card);
  set(docCardsListState, (prev) => {
    const copy = prev.slice();
    copy.splice(insertIndex, 0, { id: card.id });
    return copy;
  });
}

/**
 * Internal function
 * Add new stats card to state at given list insert index
 */
function addNewTextCardToState(
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: T | ((currVal: T) => T)
  ) => void,
  card: DocCardState,
  insertIndex: number
) {
  set(docCardAtomFamily(card.id), card);
  set(docCardsListState, (prev) => {
    const copy = prev.slice();
    copy.splice(insertIndex, 0, { id: card.id });
    return copy;
  });
}

/**
 * Internal function
 * Add new color scheme container to state, and add the
 * connection between the card and the color scheme container to the list
 */
function addColorSchemeContainerToState(
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: T | ((currVal: T) => T)
  ) => void,
  colorSchemeContainer: ColorSchemeContainer,
  cardId: string
) {
  set(
    colorSchemeContainersAtomFamily(colorSchemeContainer.id),
    colorSchemeContainer
  );
  set(cardColorSchemeLinksAtom, (prev) => {
    return prev.concat({ cardId, schemeId: colorSchemeContainer.id });
  });
}

/**
 * Internal function
 * Add new color scheme container for micro cards, and add the
 * connection between the card and the color scheme container to the list
 */
function addStyleContainerGeoMicroToState(
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: T | ((currVal: T) => T)
  ) => void,
  styleContainer: StyleContainerGeoMicro,
  cardId: string
) {
  set(styleContainersGeoMicroAtomFamily(styleContainer.id), styleContainer);
  set(cardStyleContainerGeoMicroLinksAtom, (prev) => {
    return prev.concat({ cardId, styleContainerId: styleContainer.id });
  });
}
