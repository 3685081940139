import * as _ from "lodash";

import { RowRegular, SurveyRow } from "../row";
import { BandScalesByDimension } from "./bandScales";
import { BarSpec, BarSpecCommon, BarSpecRegular } from "./bar_chart_common";
import { assertNever } from "@fluentui/utilities";
import { defined } from "../../../../core/defined";
import { getSpecialColorScheme } from "../core/colors/colorSchemes";
import { BREAKDOWN_ALL_LABEL } from "../../../../domain/measure/definitions";
import { ComputedValueOutputSettings } from "../../datasets/table/table_base/table_base_definitions";

function getDimensionOrBreakdownAll(
  row: RowRegular | SurveyRow,
  dimension: string,
  dimensionFormatter?: (dimension: string, value: any) => string
) {
  const value = row.dimension(dimension);
  if (defined(value)) {
    return defined(dimensionFormatter)
      ? dimensionFormatter(dimension, value)
      : value;
  }
  return BREAKDOWN_ALL_LABEL;
}

export function calculateGroupedBarsSpec<T extends RowRegular | SurveyRow>(
  rows: T[],
  dimensionsOrdered: string[],
  innerMostDimension: string | undefined,
  innerMostDimensionLabel: string | undefined,
  legendDimension: string | undefined,
  legendDimensionLabel: string | undefined,
  bandScales: BandScalesByDimension,
  rangeScale: (row: T, round: undefined | ((num: number) => number)) => number,
  axisScreenOffset: number,
  dimensionFormatter: (dimension: string, value: any) => string,
  computedValueOutputSettings?: ComputedValueOutputSettings,
  customLabels?: { [key: string]: { [key: string]: string } }
): BarSpec[] {
  return rows.map((row) => {
    const finalBandscale = bandScales[bandScales.length - 1];
    const domainAxisOffset =
      dimensionsOrdered.length === 0
        ? finalBandscale.scale("") ?? 0
        : _.sum(
            dimensionsOrdered.map((dimension, index) => {
              const currentBandScale = bandScales[index];
              if (currentBandScale.dimension !== dimension) {
                throw new Error("Wrong bandscale!");
              }

              // If the dimension we're trying to access does not have a value,
              // it means it's a survey catch-all dimension value
              const valueRaw = getDimensionOrBreakdownAll(row, dimension);
              const value =
                typeof valueRaw === "string"
                  ? customLabels?.[dimension]?.[valueRaw] ?? valueRaw
                  : valueRaw;

              const scaled = currentBandScale.scale(value as string);
              return scaled;
            })
          ) ?? 0;

    const bandwidth = finalBandscale.scale.bandwidth();
    const rowType = row.type();
    const specialColorScheme = defined(legendDimensionLabel)
      ? getSpecialColorScheme(legendDimensionLabel)
      : defined(innerMostDimensionLabel)
      ? getSpecialColorScheme(innerMostDimensionLabel)
      : undefined;
    const specialColorBy = !defined(legendDimension)
      ? row.dimension(dimensionsOrdered[0])
      : undefined;

    const colorBy =
      defined(specialColorBy) &&
      defined(specialColorScheme) &&
      defined(specialColorScheme[specialColorBy as string])
        ? specialColorBy
        : (getDimensionOrBreakdownAll(
            row,
            (legendDimension ?? innerMostDimension) as string,
            dimensionFormatter
          ) as string);

    const common: BarSpecCommon = {
      key: dimensionsOrdered.map((d) => row.dimension(d)).join("-"),
      dimensions: (d) => getDimensionOrBreakdownAll(row, d),
      domain: row.domain(),
      colorKey: colorBy as string,
      domainAxisOffset,
      width: bandwidth,
    };
    if (rowType === "") {
      const round = row.isUserDefined
        ? computedValueOutputSettings?.roundValues
        : undefined;
      const scaledRange = rangeScale(row, round);
      const height = scaledRange;
      let range = row.range();
      const format = row.isUserDefined
        ? computedValueOutputSettings?.format
        : undefined;
      if (round) {
        range = round(range);
      }

      return {
        type: "regular",
        ...common,
        format,
        range: range,
        flip: range < 0,
        heightFromAxis: height - axisScreenOffset,
      } as BarSpecRegular;
    } else if (rowType === "low_base") {
      return { type: rowType, ...common };
    } else if (rowType === "invalid_choice") {
      return { type: rowType, ...common };
    } else {
      return assertNever(rowType);
    }
  });
}
