import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useMemo, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { FontIcon } from "@fluentui/react";

import { Button } from "../../../components/Button";
import { DefaultLoading } from "../../../components/Loading";
import { MainContent } from "../../../components/MainContent";
import { defined } from "../../../lib/core/defined";
import { lastPathSegment, pathInfo } from "../../../lib/paths";
import { AlertsContainer } from "./settings_views/Alerts";
import { AppearanceSettings } from "./settings_views/AppearanceSettings";
import { readHistory } from "../../../lib/application/browser/locationHistory";
import { logger } from "../../../lib/infra/logging";
import { OrgAppearance } from "./org_settings/OrgAppearance";
import { classNames } from "../../../lib/core/classNames";
import { UserInfoContext } from "../../../lib/application/contexts";
import { Permission, UserInfo } from "../../../lib/application/auth/UserInfo";
import { OrgLicenseManagement } from "./org_settings/OrgLicenseManagement";
import { DataLinks } from "./settings_views/DataLinks";
import { General } from "./settings_views/General";
import { FileManager } from "./org_settings/FileManager";

import "./UserSettings.scss";
import { Deliveries } from "./org_settings/Deliveries";

interface CustomNavLink {
  name: string;
  path: string;
}

interface CustomNavLinkGroup {
  name?: string;
  links: CustomNavLink[];
}

interface Props {}
export function UserSettings(props: Props) {
  const navigate = useNavigate();

  const hasNonSettingsPath = useMemo(() => {
    const prevPaths = readHistory();
    return prevPaths.some(
      (path) => !path.startsWith(pathInfo.userSettings.pathTemplate)
    );
  }, []);

  const handleNavigateBack = useCallback(() => {
    const prevPaths = readHistory();
    const index = prevPaths
      .slice()
      .reverse()
      .findIndex(
        (path) => !path.startsWith(pathInfo.userSettings.pathTemplate)
      );
    if (index === -1) {
      logger.error("Could not find previous non-settings path");
      return;
    }
    navigate(prevPaths[prevPaths.length - index - 1]);
  }, [navigate]);

  const { user } = useAuth0();
  const userInfo = useContext(UserInfoContext);

  return (
    <div id="user-settings">
      <CustomNavSmallScreen />
      <>
        {hasNonSettingsPath && (
          <div className="back-button">
            <Button
              onClick={handleNavigateBack}
              icon="arrow-left"
              title="Tillbaka"
            ></Button>
          </div>
        )}
      </>

      <div className="flex-row">
        <CustomNavBigScreen />
        <MainContent className="content">
          <>
            <div className="settings-view">
              {!defined(user) || !defined(userInfo) ? (
                <DefaultLoading></DefaultLoading>
              ) : (
                <>
                  <Routes>
                    <Route path="appearance" element={<AppearanceSettings />} />
                    <Route
                      path="alerts"
                      element={<AlertsContainer user={user} />}
                    />
                    <Route
                      path={lastPathSegment(
                        pathInfo.userSettingsDataLinks.pathTemplate
                      )}
                      element={<DataLinks />}
                    />
                    <Route
                      path={lastPathSegment(
                        pathInfo.userSettingsGeneral.pathTemplate
                      )}
                      element={<General />}
                    ></Route>
                    <Route
                      path={lastPathSegment(
                        pathInfo.orgSettingsAppearance.pathTemplate
                      )}
                      element={<OrgAppearance />}
                    />
                    <Route
                      path={lastPathSegment(
                        pathInfo.orgSettingsUserManagement.pathTemplate
                      )}
                      element={
                        <OrgLicenseManagement
                          orgId={userInfo.organizationId()}
                        />
                      }
                    />
                    <Route
                      path={lastPathSegment(
                        pathInfo.orgSecureDelivery.pathTemplate
                      )}
                      element={<Deliveries />}
                    />

                    <Route
                      path={lastPathSegment(pathInfo.orgFiles.pathTemplate)}
                      element={
                        <FileManager orgId={userInfo.organizationId()} />
                      }
                    />
                    <Route path="*" element={<Navigate to="alerts" />}></Route>
                  </Routes>
                </>
              )}
            </div>
          </>
        </MainContent>
        {/* Dummy element that takes up same space as on the left */}
        <div className="dummy"></div>
      </div>
    </div>
  );
}

function CustomNavSmallScreen(props: {}) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const selectedLink = useMemo(() => {
    const path = location.pathname;
    const linkGroups = getLinkGroups();
    for (const group of linkGroups) {
      for (const link of group.links) {
        if (path.includes(link.path)) {
          const prefix = defined(group.name) ? group.name + " > " : "";
          return prefix + link.name;
        }
      }
    }
    return undefined;
  }, [location.pathname]);

  return (
    <div className={classNames("hide-on-big-screen")}>
      <div className="custom-navbar-small-screen" onClick={handleToggleMenu}>
        <div className="hamburger">
          <FontIcon iconName="menu" />
        </div>
        <div className="label">
          <span>{selectedLink}</span>
        </div>
      </div>
      <div
        className={classNames(
          "custom-nav-small-screen-overlay",
          isMenuOpen ? "is-open" : ""
        )}
        onClick={() => {
          setMenuOpen(false);
        }}
      >
        <div className="custom-nav-small-screen-container">
          <CustomNavInner
            onNavigate={() => setMenuOpen(false)}
            className={classNames("custom-nav-small-screen")}
          />
          <Button className="close-button" icon="cross" title={null} />
        </div>
      </div>
    </div>
  );
}

function CustomNavBigScreen(props: {}) {
  return <CustomNavInner className="big-screen-only" />;
}

function CustomNavInner(props: {
  className?: string;
  onNavigate?: () => void;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = useContext(UserInfoContext);
  const linkGroups = getLinkGroups(userInfo);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={classNames("custom-nav-inner", props.className)}
    >
      {linkGroups.map((group, index) => (
        <div key={index}>
          {group.name && <div className="group-header">{group.name}</div>}
          <ul>
            {group.links.map((link) => (
              <li
                key={link.path}
                className={
                  location.pathname.includes(link.path) ? "active" : ""
                }
                onClick={() => {
                  navigate(link.path);
                  props.onNavigate?.();
                }}
              >
                {link.name}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

function getLinkGroups(userInfo?: UserInfo) {
  const linkGroups: CustomNavLinkGroup[] = [
    {
      links: [
        { name: "Allmänt", path: pathInfo.userSettingsGeneral.pathTemplate },
        { name: "Bevakningar", path: pathInfo.userSettingsAlerts.pathTemplate },
        {
          name: "Utseende",
          path: pathInfo.userSettingsAppearance.pathTemplate,
        },
        {
          name: "Datalänkar",
          path: pathInfo.userSettingsDataLinks.pathTemplate,
        },
      ],
    },
  ];

  const orgLinks: CustomNavLinkGroup["links"] = [];
  orgLinks.push({
    name: "Filer",
    path: pathInfo.orgFiles.pathTemplate,
  });
  orgLinks.push({
    name: "Leveransplattform",
    path: pathInfo.orgSecureDelivery.pathTemplate,
  });
  if (userInfo?.hasPermission(Permission.OrgAdminManagePreferences)) {
    orgLinks.push({
      name: "Utseende",
      path: pathInfo.orgSettingsAppearance.pathTemplate,
    });
  }
  if (userInfo?.hasPermission(Permission.OrgAdminManageLicenses)) {
    orgLinks.push({
      name: "Användare och licenser",
      path: pathInfo.orgSettingsUserManagement.pathTemplate,
    });
  }

  if (orgLinks.length > 0) {
    linkGroups.push({
      name: "Organisation",
      links: orgLinks,
    });
  }
  return linkGroups;
}
