import { useCallback, useEffect } from "react";

import { useAddRemoveControl } from "./useAddRemoveControl";
import { TitleControl } from "./components/TitleControl";

export function useTitleControl(
  map: mapboxgl.Map | undefined,
  title: string | undefined,
  subheaders: string | undefined,
  show: boolean
) {
  const makeControl = useCallback(() => {
    return new TitleControl(title, subheaders, show);
  }, [title, subheaders, show]);

  const {
    add,
    remove,
    control: titleControl,
  } = useAddRemoveControl(
    map,
    makeControl,
    "top-left" // Positioning at the top-center of the map
  );

  useEffect(() => {
    if (titleControl) {
      titleControl.update(title, subheaders, show);
    }
  }, [title, subheaders, titleControl, show]);

  return [titleControl, add, remove] as const;
}
