import { Checkbox, Spinner } from "@fluentui/react";
import { sortBy } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { Button } from "../../../../../components/Button";
import { Card } from "../../../../../components/Card";
import {
  AppMessagesContext,
  ShowDraftDataContext,
} from "../../../../../lib/application/contexts";
import { defined } from "../../../../../lib/core/defined";
import { Progress } from "../../../../../lib/core/progress";
import { DimensionV2Dto } from "../../../../../lib/domain/measure/definitions";
import {
  displayHttpErrorInternal,
  HttpResult,
} from "../../../../../lib/infra/HttpResult";
import { DraftDataTodo } from "../../../../../lib/application_admin/todos/draft_data_todos";
import { DataPreviewWithLoading } from "../../../admin_components/data_preview";
import { RecoilRoot } from "recoil";
import { HorizontalDivider } from "../../../../../components/Divider";
import { DataPreviewMicro } from "../../../admin_components/data_preview_micro";
import { DimensionsEditorV2 } from "../../shared/DimensionsEditorV2";
import { useToggle } from "../../../../../lib/application/hooks/useToggle";
import { MicroGeoSelections } from "../../../../../lib/application/state/stats/document-core/core-micro";
import { AlertBox } from "../../../../../components/AlertBox";
import { useSaveDimensionsOrder } from "../../../../../lib/application_admin/hooks/dimensions";
import {
  MeasureManagementType,
  useSendNotices,
  useUpdateLastUpdated,
  useUpdateNextUpdate,
} from "../../../../../lib/application_admin/hooks/measure_management";
import { getMeasureMetadata } from "../../../../../lib/application/requests/admin/common_requests_admin";
import { logger } from "../../../../../lib/infra/logging";
import { getOrganizationV2 } from "../../../../../lib/application/requests/admin/organization_management";
import { OrganizationDetailsV2Dto } from "../../../../../lib/infra/api_responses/admin/organizations";
import { BREAKDOWN_ALL_LABEL } from "../../../../../lib/domain/measure/definitions";
import { DefaultLoading } from "../../../../../components/Loading";
import { Table } from "../../../../../components/Table";
import { DraftConflictDto } from "../../../../../lib/application/requests/admin/measure_management/shared";
import { DataLinkDto } from "../../../../../lib/infra/api_responses/data_links";
import { ShowDataLinkDialog } from "../../../../stats/docs/cards/card_general/ExportViaLinkDialog";
import { TextButton } from "../../../../../components/buttons/TextButton";
import {
  dataLinkSupportZeroFeatures,
  getDataLinkFeatureSupport,
} from "../../../../../lib/domain/data_links";

interface Props<C, R> {
  id: number;
  todos?: DraftDataTodo[];
  type: MeasureManagementType;
  getConflicts: (id: number) => Promise<HttpResult<DraftConflictDto[]>>;
  getDimensions: (id: number) => Promise<HttpResult<DimensionV2Dto[] | null>>;
  editDimension: (
    dimensionId: number,
    dim: DimensionV2Dto
  ) => Promise<HttpResult<void>>;
  renderCommitDto: (data: C) => JSX.Element;
  renderRevertDto: (data: R) => JSX.Element;
  commitPendingChanges: (
    id: number,
    forceRemovalOfConflictingValues?: boolean
  ) => Promise<HttpResult<C>>;
  revertPendingChanges: (id: number) => Promise<HttpResult<R>>;
  reloadTodos: () => void;
  updateLastUpdatedDisabled?: boolean;
  updateNextUpdateDisabled?: boolean;
}
export function MeasureConfirmation<CommitShape, RevertShape>(
  props: Props<CommitShape, RevertShape>
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [todo, setTodo] = useState(() => {
    return props.todos?.find((d) => d.dataId === props.id);
  });

  const {
    reloadTodos,
    commitPendingChanges,
    revertPendingChanges,
    editDimension,
    getDimensions,
    id,
    getConflicts,
  } = props;
  const hasDraftDimensions = todo?.hasDraftDimensions;

  const [confirmProgress, setConfirmProgress] = useState(Progress.NotStarted);
  const appMessages = useContext(AppMessagesContext);
  const [commitResult, setCommitResult] = useState<CommitShape>();
  const [revertResult, setRevertResult] = useState<RevertShape>();
  const [linkToDisplay, setLinkToDisplay] = useState<Pick<
    DataLinkDto,
    "link" | "type"
  > | null>(null);
  const [conflicts, setConflicts] = useState<DraftConflictDto[] | null>(null);

  const [doSendNotices, toggleDoSendNotices] = useToggle(true);
  const [updateLastUpdated, toggleUpdateLastUpdated] = useToggle(
    props.updateLastUpdatedDisabled || props.type === "survey" ? false : true
  );
  const [updateNextUpdate, toggleUpdateNextUpdate] = useToggle(
    props.updateNextUpdateDisabled || props.type === "survey" ? false : true
  );
  const [geoSelectionsToReplicate, setGeoSelectionsToReplicate] =
    useState<MicroGeoSelections>();

  const messagesHandler = useContext(AppMessagesContext);

  const [dimensionsResult, setDimensionsResult] =
    useState<HttpResult<DimensionV2Dto[] | null>>();

  const handleReplicateGeoSelections = useCallback((s: MicroGeoSelections) => {
    setGeoSelectionsToReplicate(s);
  }, []);

  const handleDone = useCallback(() => {
    reloadTodos();
  }, [reloadTodos]);

  const handleCommit = useCallback(() => {
    if (conflicts === null) {
      throw new Error("Expected conflicts to be defined");
    }
    let forceRemovalOfConflictingValues = false;
    if (conflicts.length > 0) {
      if (
        !window.confirm(
          "Vill du ta bort listade dimensionsvärden och ändra utestående datalänkar?"
        )
      ) {
        return;
      }
      forceRemovalOfConflictingValues = true;
    }
    setConfirmProgress(Progress.InProgress);
    commitPendingChanges(props.id, forceRemovalOfConflictingValues).then(
      (res) => {
        res.match({
          ok: (data) => {
            setConfirmProgress(Progress.Success);
            setCommitResult(data);
            appMessages?.add("success", "Ändringarna har sparats");
            handleDone();
          },
          err: (err) => {
            setConfirmProgress(Progress.Error);
            appMessages?.add(
              "error",
              "Misslyckades! " + displayHttpErrorInternal(err)
            );
          },
        });
      }
    );
  }, [appMessages, commitPendingChanges, conflicts, handleDone, props.id]);

  const handleRevert = useCallback(() => {
    if (
      !window.confirm(
        "Är du säker på att du vill ta bort den senast uppladdade ej publicerade datan?"
      )
    ) {
      return;
    }
    setConfirmProgress(Progress.InProgress);
    revertPendingChanges(props.id).then((res) => {
      res.match({
        ok: (data) => {
          setConfirmProgress(Progress.Success);
          setRevertResult(data);
          appMessages?.add("success", "Ändringarna har tagits bort");
          handleDone();
        },
        err: (err) => {
          setConfirmProgress(Progress.Error);
          appMessages?.add(
            "error",
            "Misslyckades! " + displayHttpErrorInternal(err)
          );
        },
      });
    });
  }, [appMessages, handleDone, props.id, revertPendingChanges]);

  useEffect(() => {
    getDimensions(id).then((res) => setDimensionsResult(res));
  }, [getDimensions, id]);

  const updateDimensions = useCallback(() => {
    return getDimensions(props.id).then((res) => setDimensionsResult(res));
  }, [getDimensions, props.id]);

  const handleSaveDimensionsOrder = useSaveDimensionsOrder(updateDimensions);

  useEffect(() => {
    if (!hasDraftDimensions) {
      return;
    }
    updateDimensions();
  }, [hasDraftDimensions, props.id, updateDimensions]);

  const dimensions = dimensionsResult?.match({
    ok: (d) => d,
    err: (e) => undefined,
  });
  const [sendProgress, setSendProgress] = useState(Progress.NotStarted);

  const handleUpdateLastUpdated = useUpdateLastUpdated(messagesHandler);
  const handleUpdateNextUpdate = useUpdateNextUpdate(messagesHandler);
  const handleSendNotices = useSendNotices(setSendProgress, messagesHandler);

  const handleSendNoticesAndUpdateTimestamp = useCallback(() => {
    if (
      doSendNotices &&
      window.confirm(
        "Schemalägg mejlutskick om det här måttet till alla som bevakar?"
      )
    ) {
      handleSendNotices(props.id);
    }

    if (updateLastUpdated) {
      handleUpdateLastUpdated(props.type, props.id);
    }
    if (updateNextUpdate) {
      handleUpdateNextUpdate(props.type, props.id);
    }
  }, [
    doSendNotices,
    handleSendNotices,
    handleUpdateLastUpdated,
    handleUpdateNextUpdate,
    props.id,
    props.type,
    updateLastUpdated,
    updateNextUpdate,
  ]);

  const handleEditDimension = useCallback(
    (
      dimensionId: number,
      dimension: DimensionV2Dto
    ): Promise<HttpResult<void>> => {
      return editDimension(dimensionId, dimension).then((res) =>
        updateDimensions().then(() => res)
      );
    },
    [editDimension, updateDimensions]
  );

  const [memberOrgsFetchStatus, setMemberOrgsFetchStatus] = useState<Progress>(
    Progress.NotStarted
  );
  const [ownerOrgInfo, setOwnerOrgInfo] = useState<OrganizationDetailsV2Dto>();

  useEffect(() => {
    getConflicts(id).then((res) => {
      res.match({
        err: (err) => {
          appMessages?.add(
            "error",
            "Kunde inte hämta konliktinfo: " + displayHttpErrorInternal(err)
          );
        },
        ok: (r) => setConflicts(r),
      });
    });
  }, [appMessages, getConflicts, id]);

  useEffect(() => {
    if (props.type !== "memberOrgsMeasure") {
      return;
    }
    if (!defined(dimensions)) {
      return;
    }

    setMemberOrgsFetchStatus(Progress.InProgress);
    getMeasureMetadata(props.id).then((measureRes) => {
      const orgId = measureRes.member_org_measurement_owner_org;
      if (!defined(orgId)) {
        logger.warn("Expected a measurement owner, was undefined");
        return;
      }
      return getOrganizationV2(orgId).then((orgRes) => {
        orgRes.match({
          ok: (org) => {
            setOwnerOrgInfo(org);
            setMemberOrgsFetchStatus(Progress.Success);
          },
          err: (err) => {
            setMemberOrgsFetchStatus(Progress.Error);
            appMessages?.add(
              "error",
              "Failed to get org: " + displayHttpErrorInternal(err)
            );
          },
        });
      });
    });
  }, [appMessages, dimensions, props.id, props.type]);

  const memberOrgsCheck = useMemo(() => {
    if (
      props.type !== "memberOrgsMeasure" ||
      !defined(dimensions) ||
      !defined(ownerOrgInfo)
    ) {
      return null;
    }
    const orgsBreakdown = dimensions.find(
      (d) => d.data_column === "breakdown1"
    );
    if (!defined(orgsBreakdown)) {
      throw new Error("Expected breakdown1 dimension");
    }
    const draftValues =
      orgsBreakdown.values?.filter((v) => v.draft === true) ?? [];
    const noDataOrgs =
      ownerOrgInfo.third_party_orgs?.filter(
        (o) => !orgsBreakdown.values?.some((v) => v.label === o.name)
      ) ?? [];

    const unexpectedOrgs = draftValues.filter(
      (v) =>
        !ownerOrgInfo.third_party_orgs?.some((o) => o.name === v.label) &&
        v.label !== BREAKDOWN_ALL_LABEL
    );
    return { memberOrgsWithNoData: noDataOrgs, unexpectedOrgs };
  }, [dimensions, ownerOrgInfo, props.type]);

  if (conflicts === null) {
    return <DefaultLoading />;
  }

  return (
    <Card className="confirm-data-upload">
      <>
        {linkToDisplay !== null && (
          <ShowDataLinkDialog
            link={{
              link: linkToDisplay.link,
              type: linkToDisplay.type,
            }}
            handleClose={() => setLinkToDisplay(null)}
            featureSupport={
              defined(linkToDisplay.type)
                ? getDataLinkFeatureSupport(linkToDisplay.type)
                : dataLinkSupportZeroFeatures()
            }
          />
        )}
        {confirmProgress !== Progress.Success && (
          <>
            <h2>Bekräfta måttuppdatering</h2>
            <p>
              Måttet har uppdaterats. De senaste ändringarna kommer inte vara
              synliga för användare förrän du har bekräftat.
            </p>
            <>
              {defined(todo) && (
                <section>
                  <h3>
                    Kontrollera för mått {todo.label} (ID {todo.dataId}):
                  </h3>
                  <ul>
                    {memberOrgsCheck !== null &&
                      memberOrgsCheck.memberOrgsWithNoData.length > 0 && (
                        <li>Medlemsorganisationer som saknar data</li>
                      )}
                    {memberOrgsCheck !== null &&
                      memberOrgsCheck.unexpectedOrgs.length > 0 && (
                        <li>Oväntade värden i organisationskolumnen</li>
                      )}
                    {todo.hasDraftData && <li>Ny data</li>}
                    {todo.hasDraftDimensions && (
                      <li>Nya dimensionsvärden (se nedan)</li>
                    )}
                    {conflicts.length > 0 && (
                      <li>
                        Konflikter: borttagning av dimensionsvärden leder till
                        ändring av existerande datalänkar
                      </li>
                    )}
                  </ul>
                </section>
              )}
              {memberOrgsCheck !== null && (
                <AlertBox className="member-orgs-checks" intent="warning">
                  <>
                    {memberOrgsCheck.memberOrgsWithNoData.length > 0 && (
                      <div>
                        <strong>
                          Följande medlemsorganisationer saknar data:
                        </strong>
                        <ul>
                          {sortBy(memberOrgsCheck.memberOrgsWithNoData, (o) =>
                            o.name.toLowerCase()
                          ).map((o) => (
                            <li key={o.name}>{o.name}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {memberOrgsCheck.unexpectedOrgs.length > 0 && (
                      <div>
                        <strong>
                          Följande värden förväntades inte i breakdown1 (dessa
                          ska motsvara organisationer):
                        </strong>
                        <ul>
                          {sortBy(memberOrgsCheck.unexpectedOrgs, (o) =>
                            o.label.toLowerCase()
                          ).map((v) => (
                            <li key={v.label}>{v.label}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                </AlertBox>
              )}
              {confirmProgress === Progress.NotStarted && (
                <>
                  {defined(conflicts) && conflicts.length > 0 && (
                    <section>
                      <h3>
                        Datalänkar som kommer förändras när dimensionsvärden tas
                        bort
                      </h3>
                      <Table
                        columns={[
                          { name: "dimension", type: "text" },
                          { name: "värde som tas bort", type: "text" },
                          { name: "länk", type: "text" },
                        ]}
                        data={conflicts.map((c) => {
                          const d = dimensions?.find(
                            (d) => d.dimension_id === c.dimension_id
                          );
                          return {
                            id: c.dimension_value_id + "" + c.referencing_link,
                            cells: [
                              d?.label ?? "",
                              c.dimension_value_label,
                              defined(c.referencing_link) ? (
                                <TextButton
                                  className="monospace"
                                  title={c.referencing_link ?? ""}
                                  onClick={() =>
                                    setLinkToDisplay({
                                      link: c.referencing_link ?? "",
                                      type:
                                        (c.referencing_link_type as any) ?? "",
                                    })
                                  }
                                />
                              ) : (
                                ""
                              ),
                            ],
                          };
                        })}
                      />
                    </section>
                  )}
                  {defined(dimensions) && (
                    <section>
                      <h3>Dimensionsvärden</h3>
                      <DimensionsEditorV2
                        handleEditDimension={handleEditDimension}
                        handleSaveDimensionsOrder={handleSaveDimensionsOrder}
                        dimensions={dimensions}
                      />
                    </section>
                  )}
                </>
              )}
            </>
          </>
        )}
      </>
      <>
        {confirmProgress === Progress.Success && (
          <section>
            {defined(commitResult) && (
              <>
                <h3>Klart!</h3>
                <h3>
                  Vill du också skicka ut mejlnotiser och sätta last update/next
                  update?
                </h3>

                <div className="flex-row margin-y-sm">
                  <Checkbox
                    disabled={
                      sendProgress !== Progress.NotStarted ||
                      defined(todo?.memberOrgMeasurementParentId)
                    }
                    label={
                      props.type === "memberOrgsMeasure"
                        ? "Skicka mejlnotiser (för huvudmåttet, ej mått för medlemsorganisationer)"
                        : "Skicka mejlnotiser"
                    }
                    checked={doSendNotices}
                    onChange={toggleDoSendNotices}
                  ></Checkbox>
                  <Checkbox
                    disabled={
                      sendProgress !== Progress.NotStarted ||
                      props.updateLastUpdatedDisabled
                    }
                    className="margin-x-sm"
                    label="Sätt last update till idag"
                    checked={updateLastUpdated}
                    onChange={toggleUpdateLastUpdated}
                  ></Checkbox>
                  <Checkbox
                    disabled={
                      sendProgress !== Progress.NotStarted ||
                      props.updateNextUpdateDisabled
                    }
                    label="Sätt nästa uppdatering till (idag + tidsupplösning * 1)"
                    checked={updateNextUpdate}
                    onChange={toggleUpdateNextUpdate}
                  ></Checkbox>
                </div>
                <div>
                  <Button
                    intent="primary"
                    title="Verkställ"
                    disabled={sendProgress !== Progress.NotStarted}
                    onClick={handleSendNoticesAndUpdateTimestamp}
                  ></Button>
                </div>

                <HorizontalDivider></HorizontalDivider>

                {props.renderCommitDto(commitResult)}
              </>
            )}

            {defined(revertResult) && props.renderRevertDto(revertResult)}
          </section>
        )}
        {confirmProgress === Progress.InProgress && (
          <section className="flex-row">
            <p className="margin-x-md">Väntar på svar... </p>
            <Spinner></Spinner>
          </section>
        )}
        {(!hasDraftDimensions ||
          (hasDraftDimensions && defined(dimensions))) && (
          <section>
            <Button
              title="Ta bort ändringar"
              disabled={confirmProgress !== Progress.NotStarted}
              intent="danger"
              onClick={handleRevert}
            ></Button>
            <Button
              disabled={
                confirmProgress !== Progress.NotStarted ||
                (props.type === "memberOrgsMeasure" &&
                  memberOrgsFetchStatus !== Progress.Success)
              }
              title="Bekräfta och publicera ändringar"
              intent="primary"
              onClick={handleCommit}
            ></Button>
          </section>
        )}
      </>

      <HorizontalDivider></HorizontalDivider>

      <>
        {(props.type === "stats" ||
          props.type === "memberOrgsMeasure" ||
          props.type === "survey") &&
          defined(todo) &&
          confirmProgress === Progress.NotStarted && (
            <div className="flex-row-space-between">
              <RecoilRoot>
                <div style={{ width: "48%" }}>
                  <h2>Före ändring</h2>
                  <ShowDraftDataContext.Provider value={false}>
                    <DataPreviewWithLoading
                      measureId={todo.dataId}
                      showDraftData={false}
                    ></DataPreviewWithLoading>
                  </ShowDraftDataContext.Provider>
                </div>
              </RecoilRoot>
              <RecoilRoot>
                <div style={{ width: "48%" }}>
                  <h2>Efter ändring</h2>
                  <ShowDraftDataContext.Provider value={true}>
                    <DataPreviewWithLoading
                      measureId={todo.dataId}
                      showDraftData={true}
                    ></DataPreviewWithLoading>
                  </ShowDraftDataContext.Provider>
                </div>
              </RecoilRoot>
            </div>
          )}
        {props.type === "micro" &&
          defined(todo) &&
          confirmProgress === Progress.NotStarted && (
            <div className="">
              <AlertBox intent="warning">
                <span>
                  Välj områden på den övre kartan så väljs samma områden
                  automatiskt i den nedre kartan. Avmarkering är buggigt.
                </span>
              </AlertBox>
              <RecoilRoot>
                <div style={{}}>
                  <h2>Före ändring</h2>
                  <ShowDraftDataContext.Provider value={false}>
                    <DataPreviewMicro
                      measureId={todo.dataId}
                      showDraftData={false}
                      onGeoSelectionsUpdated={(s) =>
                        handleReplicateGeoSelections(s)
                      }
                    ></DataPreviewMicro>
                  </ShowDraftDataContext.Provider>
                </div>
              </RecoilRoot>
              <RecoilRoot>
                <div style={{}}>
                  <h2>Efter ändring</h2>
                  <ShowDraftDataContext.Provider value={true}>
                    <DataPreviewMicro
                      measureId={todo.dataId}
                      showDraftData={true}
                      geoSelectionsToReplicate={geoSelectionsToReplicate}
                    ></DataPreviewMicro>
                  </ShowDraftDataContext.Provider>
                </div>
              </RecoilRoot>
            </div>
          )}
      </>
    </Card>
  );
}
